import { Dispatch } from "redux";
import { ShiftStoreAction } from ".";
import { EventType } from "../../classes/api/types/event";
import { ShiftModel } from "../../classes/api/types/shift";
import { EmployeeFilter } from "../../containers/Manager/Employees/components/EmployeesFilter";
import { apiInstance } from "../../utils";
import { EventActionType } from "../events";
import { ReviewActionType } from "../reviews";


export const clearShifts = () => {
  return {
    type: ShiftStoreAction.CLEAR_SHIFTS
  }
}

export const clockIn = (shiftData: ShiftModel) => {
  return (dispatch: Dispatch) => {
    return apiInstance.shifts.clockIn(shiftData)
      .then((response: any) => {
          dispatch({
            type: ShiftStoreAction.CREATE_SHIFT,
            payload: response.data
          });
          return response;
      });
  }
}

export const createShift = (shifts: ShiftModel | ShiftModel[]) => {
  return (dispatch: Dispatch) => {
    return apiInstance.shifts.create(shifts).then(res => {
      let updated;
      if (Array.isArray(shifts)) {
        updated = res.data.map((shift: ShiftModel) => ({
          ...shift,
          resourcetype: EventType.SHIFT
        }));
      }
      else {
        updated = {
          ...res.data[0],
          resourcetype: EventType.SHIFT
        }
      }
      dispatch({
        type: ShiftStoreAction.CREATE_SHIFT,
        payload: updated
      });
      dispatch({
        type: EventActionType.ADD_EVENT,
        payload: updated
      });
    });
  }
}

export const updateShift = (shift: ShiftModel) => {
  return (dispatch: Dispatch) => {
    return apiInstance.shifts.update(shift).then(res => {
      const newShift = {
        ...res.data,
        resourcetype: EventType.SHIFT
      }
      dispatch({
        type: ShiftStoreAction.UPDATE_SHIFT,
        payload: newShift
      });
      dispatch({
        type: EventActionType.UPDATE_EVENT,
        payload: newShift
      });
    });
  }
}

export const bulkUpdateShifts = (shifts: ShiftModel[]) => {
  return (dispatch: Dispatch) => {
    return apiInstance.shifts.update(shifts).then(res => {
      const newShifts = res.data.map((s: ShiftModel) => ({
        ...s,
        resourcetype: EventType.SHIFT
      }))
      dispatch({
        type: ShiftStoreAction.BULK_UPDATE_SHIFTS,
        payload: newShifts
      });
      dispatch({
        type: EventActionType.BULK_UPDATE_EVENTS,
        payload: newShifts
      });
    });
  }
}

export const deleteShift = (shiftData: ShiftModel | ShiftModel[]) => {
  return (dispatch: Dispatch) => {
    return apiInstance.shifts.delete(shiftData).then(res => {
      dispatch({
        type: ShiftStoreAction.DELETE_SHIFT,
        payload: shiftData
      });
      dispatch({
        type: EventActionType.DELETE_EVENT,
        payload: shiftData
      });
    });
  }
}

export const setSelectedShift = (shift: ShiftModel | undefined) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: ShiftStoreAction.SET_SELECTED_SHIFT,
      payload: shift
    })
  }
}

export const copyScheduleToPeriod = (periodStart: string, employees: string[]) => {
  return apiInstance.shifts.copySchedule(periodStart, employees);
}

export const getScheduleExport = (
  periodStart: string, 
  fileType: 'csv' | 'excel' | 'pdf', 
  filters: EmployeeFilter,
  period: number
) => {
  return apiInstance.shifts.getScheduleExport(periodStart, fileType, filters, period);
}

export const deleteAllShiftsInSchedule = (periodStart: string) => {
  return (dispatch: Dispatch) => {
    return apiInstance.shifts.clearSchedule(periodStart).then(res => {
      dispatch({
        type: ShiftStoreAction.CLEAR_SHIFTS
      });
      dispatch({
        type: EventActionType.CLEAR_EVENTS
      })
    })
  }
}

export const notifyTeam = (employees: string[]) => {
  return (dispatch: Dispatch) => {
    return apiInstance.shifts.notifyTeam(employees);
  }
}