import styled from "styled-components";
import { Label } from "../../Label";

const tableBorderColor = "#e9e9e9";

export const TableContainer = styled.table`
  border-collapse: collapse;
  display: block;
  letter-spacing: 1px;
  font-size: 0.8rem;
  min-height: 60vh;
  white-space: nowrap;
  margin-top: 40px;
  margin-bottom: 50px;
  border-radius: 3px;
  background: white;
  border: 1px solid ${props => props.theme.cardBorder};
  padding-top: 0;
  box-shadow: ${props => props.theme.boxShadow.flat};
  overflow: auto;
`;

export const DateCol = styled.col`
  width: 10rem;
`;

export const EmployeeHeader = styled.th`
  background: white;
  left: 0;
  padding: 1rem;
  position: sticky;
  z-index: 10;
  font-size: 12px;
`;

export const EmployeeHeaderContainer = styled.div`
  min-width: 220px;
  max-width: 220px;
  white-space: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export const HoursLabel = styled.div`
  background-color: ${props => props.theme.lightPrimary};
  color: ${props => props.theme.darkPrimary};
  font-weight: bold;
  border-radius: 50px;
  padding: 5px 8px;
  font-size: 10px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
`

export const TableColumn = styled.col`
  border-width: 0 1px;
  border-style: solid;
  border-color: ${tableBorderColor};
`;

export const HeaderRow = styled.tr`
  background: white;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: ${tableBorderColor};
`;

export const TableRow = styled.tr`
  border-width: 1px 0;
  border-style: solid;
  border-color: ${tableBorderColor};
`;

export const TableHeader = styled.th<{ isHoliday: boolean }>`
  position: sticky;
  top: 0;
  z-index: 11;
  background: white;
  border: ${props => props.isHoliday ? `2px solid ${props.theme.alertRed}` : 'none'};
`;

export const Date = styled.div<{ isToday?: boolean }>`
  border: 2px solid transparent;
  border-color: ${(props) => props.isToday && props.theme.primary};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  padding: 0.5rem;
`;

export const DateLabel = styled.span`
  font-size: 25px;
  margin-bottom: 5px;
`;

export const HolidayLabel = styled.span`
  font-size: 12px;
  color: ${props => props.theme.alertRed};
  text-transform: capitalize;
  margin-top: 5px;
  font-weight: bold;
`;


export const VacationCell = styled.td`
  background-color: ${props => props.theme.lightestGrey};
  border-radius: 3px;
  padding: 10px;
  opacity: 0.5;
  text-align: center;
`

export const VacationText = styled.span`
  font-weight: bold;
  opacity: 0.7;
  color: ${props => props.theme.lightGrey};
  text-transform: capitalize;
  text-align: center;
  width: 100%;
`

export const SortContainer = styled.div`
  display: flex;
  padding: 15px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  > div {
    width: 100%;
  }

  span {
    margin-bottom: 5px;
  }
`;