import { useContext } from "react";
import { SocketContext } from "../components/ChatManager";
import { setReadReceipt } from "../reducers/chat/actions";
import { WebsocketReadReceipt } from "../types";
import { useActionLoader } from "./useActionLoader";

const useReadReceipt = () => {
  const { socket } = useContext(SocketContext);
  const { callAction: setConversationRead } = useActionLoader(setReadReceipt);

  const sendReceipt = (conversationId: string) => {
    if (socket) {
      const readReceipt: WebsocketReadReceipt = {
        readReceipt: {
          conversationId,
        }
      }
      socket.send(JSON.stringify(readReceipt));
      setConversationRead(conversationId);
    }
  }

  return sendReceipt;
}

export default useReadReceipt;