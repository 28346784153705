import styled, { css } from "styled-components"

type Props = {
  background?: string;
  backgroundHover?: string;
  color?: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
}

export const TransparentButton = styled.button<Props>`
  padding: 10px;
  font-size: 14px;
  border-radius: 50%;
  outline: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-weight: bold;
  color: ${props => props.theme.lightGrey};
  background-color: #ebebeb;
  font-size: 18px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
      background-color: #d8d8d8;
  }
`