import { Dispatch } from "redux";
import { ChatActionType } from ".";
import { MessageModel } from "../../classes/api/types/message";
import { apiInstance } from "../../utils";

export const getConversations = () => {
  return (dispatch: Dispatch) => {
    return apiInstance.conversations.getAll(null).then(res => {
      dispatch({
        type: ChatActionType.SET_CONVERSATIONS,
        payload: res.data
      })
    })
  }
}

export const setConversationMessages = ({ messages, conversationId, count }: { messages: MessageModel[], conversationId: string, count: number}) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ChatActionType.SET_CONVERSATION_MESSAGES,
      payload: {
        messages,
        conversationId,
        count
      }
    });
  }
}

export const addMessage = ({ message, isSelf=false }: { message: MessageModel, isSelf?: boolean}) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        message,
        isSelf
      }
    });
  }
}

export const setReadReceipt = (conversationId: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ChatActionType.SET_READ_RECEIPT,
      payload: conversationId
    });
  }
}