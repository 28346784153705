import React, { PropsWithChildren } from 'react';

export const hashNavigate = (to: string) => {
  window.location.hash = to;
}

const HashLink = ({ children, to }: PropsWithChildren<{ to: string }>) => (
  <a href={`#${to}`}>{children}</a>
);

export default HashLink;