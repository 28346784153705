import { LocationModel } from "../../classes/api/types/location"
import * as helpers from '../helpers';

export interface LocationState {
  location: LocationModel | undefined;
}

const initialLocationStoreState: LocationState = {
  location: undefined
}

export enum LocationActionType {
  SET_LOCATION = "SET_LOCATION",
  CLEAR_LOCATION = "CLEAR_LOCATION",
  UPDATE_LOCATION = "UPDATE_LOCATION",
  UPDATE_LOCATION_CERT = "UPDATE_LOCATION_CERT",
  ADD_LOCATION_CERT = "ADD_LOCATION_CERT",
  DELETE_LOCATION_CERT = "DELETE_LOCATION_CERT",
  ADD_GROUP = "ADD_GROUP",
  UPDATE_GROUP = "UPDATE_GROUP",
  DELETE_GROUP = "DELETE_GROUP",
  ADD_SHIFT_TYPE = "ADD_SHIFT_TYPE",
  UPDATE_SHIFT_TYPE = "UPDATE_SHIFT_TYPE",
  DELETE_SHIFT_TYPE = "DELETE_SHIFT_TYPE"
}

interface LocationAction {
  type: LocationActionType;
  payload?: any;
}

export const LocationReducer = (state: LocationState = initialLocationStoreState, action: LocationAction) => {
  switch (action.type) {
    case LocationActionType.SET_LOCATION:
      return {
        location: action.payload
      }
    case LocationActionType.UPDATE_LOCATION:
      return {
        location: {
          ...state.location,
          ...action.payload
        }
      }
    case LocationActionType.CLEAR_LOCATION:
      return {
        location: undefined
      }
    case LocationActionType.UPDATE_LOCATION_CERT:
      let certs = state.location ? state.location.certificationTemplates : [];
      if (state.location) {
        certs = helpers.updateById(state.location.certificationTemplates, action.payload);
      }
      return {
        location: {
          ...state.location,
          certificationTemplates: [ ...certs ]
        }
      }
    case LocationActionType.ADD_LOCATION_CERT:
      if (state.location?.certificationTemplates) {
        return {
          location: {
            ...state.location,
            certificationTemplates: [ ...state.location?.certificationTemplates, action.payload ]
          }
        }
      }
      return state;
    case LocationActionType.DELETE_LOCATION_CERT:
      if (state.location?.certificationTemplates) {
        const certs = helpers.removeById(state.location.certificationTemplates, action.payload);
        return {
          location: {
            ...state.location,
            certificationTemplates: [ ...certs ]
          }
        }
      }
      return state;
    case LocationActionType.ADD_GROUP:
      delete action.payload.members;
      if (state.location?.teams) {
        return {
          location: {
            ...state.location,
            teams: [ ...state.location?.teams, action.payload ]
          }
        }
      }
      return state;
    case LocationActionType.UPDATE_GROUP:
      if (state.location?.teams) {
        const teams = helpers.updateById(state.location.teams, action.payload);
        return {
          location: {
            ...state.location,
            teams: [ ...teams ]
          }
        }
      }
      return state;
    case LocationActionType.DELETE_GROUP:
      if (state.location?.teams) {
        const teams = helpers.removeById(state.location.teams, action.payload.id);
        return {
          location: {
            ...state.location,
            teams: [ ...teams ]
          }
        }
      }
      return state;
    case LocationActionType.ADD_SHIFT_TYPE:
      if (state.location?.shiftTypes) {
        return {
          location: {
            ...state.location,
            shiftTypes: [ ...state.location?.shiftTypes, action.payload ]
          }
        }
      }
      return state;
    case LocationActionType.UPDATE_SHIFT_TYPE:
      if (state.location?.shiftTypes) {
        const shiftTypes = helpers.updateById(state.location.shiftTypes, action.payload);
        return {
          location: {
            ...state.location,
            shiftTypes: [ ...shiftTypes ]
          }
        }
      }
      return state;
    case LocationActionType.DELETE_SHIFT_TYPE:
      if (state.location?.shiftTypes) {
        const shiftTypes = helpers.removeById(state.location.shiftTypes, action.payload);
        return {
          location: {
            ...state.location,
            shiftTypes: [ ...shiftTypes ]
          }
        }
      }
      return state;
    default:
      return state;
  }
}