import SimpleDate from "../../utils/SimpleDate";
import { ApiInterface } from "./types";
import { TimesheetApi, TimesheetModel } from "./types/timesheet";

export class Timesheet implements TimesheetApi {
  apiInstance: ApiInterface;
  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }


  update(input: TimesheetModel) {
    const timesheet = { ...input };
    delete timesheet.employee;
    delete timesheet.events;
    return this.apiInstance.sendRequest(
      `/shift/timesheets/${timesheet.id}/`,
      'PATCH',
      timesheet
    )
  }

  getAll({
    startDate,
    endDate,
  }: { startDate: Date, endDate: Date }) {
    const params: any = { 
      start_date: new SimpleDate(startDate).getDateLabel(), 
      end_date: new SimpleDate(endDate).getDateLabel() 
    };
    return this.apiInstance.sendRequest(
      `/shift/timesheets/`,
      'GET',
      {},
      params
    )
  }

  getOne(id: string) {
    return this.apiInstance.sendRequest(
      `/shift/timesheets/${id}/`,
      'GET'
    );
  }

  delete(id: string) {
    return this.apiInstance.sendRequest(
      `/shift/timesheets/${id}/`,
      'DELETE'
    );
  }

  create(input: any) {
    return Promise.reject('Not implemented')
  }

  getUpdatedTotals(id: string) {
    return this.apiInstance.sendRequest(
      `/shift/timesheet-totals/${id}/`,
      'POST'
    );
  }
}
