import { faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useRef, useState } from 'react';
import * as S from './index.styles';
import EmojiPicker from '../../../../../components/EmojiPicker';
import { ChatMessageType, MessageModel } from '../../../../../classes/api/types/message';
import { Modify } from '../../../../../utils/types';
import { SocketContext } from '../../../../../components/ChatManager';
import { addMessage } from '../../../../../reducers/chat/actions';
import { useActionLoader } from '../../../../../hooks/useActionLoader';

type Props = {
  conversationId: string;
  userId: string;
}

type CreateMessageModel = Omit<Modify<MessageModel, {
  senderId: string;
  conversationId: string;
  messageType: string;
}>, 'id' | 'createdAt' | 'updatedAt' | 'sender' | 'conversation'>;

const ChatInput = ({ conversationId, userId }: Props) => {
  const [message, setMessage] = useState<string>('');
  const { socket, isConnected: isWebsocketConnected } = useContext(SocketContext);
  const { callAction: sendMessage } = useActionLoader(addMessage);
  const inputRef = useRef<HTMLDivElement>(null);

  const onSubmit = (e?: any) => {
    e?.preventDefault();
    const chatMessage: CreateMessageModel = {
        text: message,
        conversationId: conversationId,
        senderId: userId,
        messageType: ChatMessageType.TEXT
    };
    sendMessage({
      ...chatMessage,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      sender: {
        id: userId,
      },
      conversation: conversationId
    });
    socket?.send(JSON.stringify({ message: chatMessage }));
    setMessage('');
    if (inputRef.current) {
      inputRef.current.textContent = '';
    }
  }
  
  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit()
    }
  }

  const addEmojii = (e: any) => {
    if (inputRef.current) {
      setMessage(message + e);
      inputRef.current.textContent += e;
    }
  };

  return (
    <S.ChatContainer>
      {!isWebsocketConnected &&
        <S.ChatConnectionStatus>
          <FontAwesomeIcon icon={faSpinner} spin />
          Connecting...
        </S.ChatConnectionStatus>
      }
      <S.ChatInputContainer onSubmit={onSubmit}>
        <S.ChatInputWrapper>
          <S.ChatInput
            ref={inputRef}
            onKeyDown={onKeyDown}
            onInput={e => setMessage(e.currentTarget.textContent || '')} />
          <S.ChatActions>
            <EmojiPicker onEmojiPick={addEmojii} />
            <S.SendButton type="submit" onClick={onSubmit}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </S.SendButton>
          </S.ChatActions>
        </S.ChatInputWrapper>
      </S.ChatInputContainer>
    </S.ChatContainer>
  );
};

export default ChatInput;