import { ApiInterface } from "./types";
import { EmployeeApi, EmployeeModel } from "./types/employee";

export class Employee implements EmployeeApi {
  apiInstance: ApiInterface;

  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  create(employee: EmployeeModel) {
    return this.apiInstance.sendRequest(
      "/user/employees/",
      "POST",
      employee
    );
  }

  update(employee: EmployeeModel) {
    return this.apiInstance.sendRequest(
      `/user/employees/${employee.id}/`,
      "PATCH",
      employee
    );
  }

  getAll() {
    return this.apiInstance.sendRequest("/user/employees/", "GET");
  }

  getArchivedEmployees() {
    return this.apiInstance.sendRequest("/user/employees?is_active=0", "GET");
  }

  delete(employee: any) {
    return this.apiInstance.sendRequest(
      `/user/employees/${employee.id}/`,
      "DELETE"
    );
  }

  getOne(input: string) {
    return Promise.reject('Not implemented')
  }

  getTeamExport() {
    return this.apiInstance.sendRequest(
      `/user/exports/team/`,
      'GET'
    );
  }
}
