import { useState } from "react";
import Modal from "../../../../../components/Modal/Modal"
import { InputContainer, Label, Select } from "../../../../../components/NewForms/Inputs";
import * as S from './index.styles';
import Button from "../../../../../components/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";

type Props = {
  onConfirm: (weeks: number) => void;
  onClose: () => void;
}

const PrintCustomizationModal = ({ onClose, onConfirm }: Props) => {
  const { location } = useSelector((state: RootState) => state.location);
  const [weeks, setWeeks] = useState(() => {
    return { value: 2, label: '2 weeks' }
  });

  return (
    <Modal onClickOut={onClose}>
      <S.Container>
        <h4>Customize print period</h4>
        <InputContainer>
          <Label>Weeks to print</Label>
          <Select
            defaultValue={weeks}
            onChange={setWeeks}
            options={[
              { label: '2 weeks', value: 2 },
              { label: '4 weeks', value: 4 },
              { label: '6 weeks', value: 6 },
            ]}
          />
        </InputContainer>
        <Button size="medium" solid onClick={() => onConfirm(weeks.value)}>Print</Button>
      </S.Container>
    </Modal>
  )
}

export default PrintCustomizationModal