import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  border: 1px solid ${props => props.theme.lightestGrey};
  position: relative;
  border-radius: 4px;
`;

export const FiltersDropdown = styled.div`
  position: absolute;
  z-index: 101;
  width: 700px;
  background-color: white;
  right: 0;
  box-shadow: ${props => props.theme.boxShadow.cardLarge};
  border: 1px solid ${props => props.theme.lightestGrey};

  span {
    font-size: 14px;
  }
`;

export const DropDownActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const InnerContainer = styled.div`
  display: flex;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  padding-left: 10px;

  h5 {
    margin: 8px 0;
    color: ${props => props.theme.lightGrey};
    svg {
      margin-right: 5px;
    }
  }
`

export const FilterSection = styled.div`
  border-right: 1px solid #e0e0e0;
  width: 50%;
  padding: 10px;
  overflow-y: auto;
  max-height: 500px;

  p {
    margin: 0 0 10px 0;
    font-size: 14px;
  }

  &:last-child {
    border-right: none;
  }
`

export const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Chip = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;

  input {
    display: none;
  }

  label {
    transition: all 200ms ease;
    font-size: 10px;
    cursor: pointer;
    border-radius: 50px;
    background-color: ${props => props.theme.lightestGrey};
    padding: 0.2rem 1rem;
  }

  input:checked + label {
    background-color: ${props => props.theme.lightGreen};
  }
`;