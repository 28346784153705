import { ApiInterface } from "./types";
import { LeaveDayApi, LeaveDayModel } from "./types/leaveDay";

export class LeaveDay implements LeaveDayApi {
  apiInstance: ApiInterface;
  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  create(leaveDay: LeaveDayModel) {
    const url = '/shift/leave-days/';
    return this.apiInstance.sendRequest(url, "POST", leaveDay);
  }

  delete(leaveDayId: string) {
    return this.apiInstance.sendRequest(`/shift/leave-days/${leaveDayId}/`, "delete");
  }

  update(leaveDayData: LeaveDayModel) {
    return this.apiInstance.sendRequest(
      `/shift/leave-days/${leaveDayData.id}/`,
      "PATCH",
      leaveDayData
    );
  }

  getOne(id: string) {
    return this.apiInstance.sendRequest(`/shift/leave-days/${id}`, "GET");
  }

  getAll() {
    return this.apiInstance.sendRequest(`/shift/leave-days/`, "GET");
  }
}
