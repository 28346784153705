import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Notification = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  min-width: 200px;
  border: 1px solid ${props => props.theme.primary};
  cursor: pointer;
  animation: slideDown 0.2s ease-in-out;

  h2 {
    margin: 0;
    margin-right: 10px;
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`

const TextDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Sender = styled.span`
  font-weight: bold;
`;

const Text = styled.span`
  font-size: 0.8rem;
`;

const ChatNotification = ({ chatId, sender, text }: { chatId: string, sender: string, text: string }) => {
  const history = useHistory();
  const truncatedText = text.length > 100 ? text.substring(0, 100) + '...' : text;
  return (
    <Notification onClick={() => history.push(`/manager/chat/${chatId}`)}>
      <h2>💬</h2>
      <TextDetails>
        <Sender>{sender}</Sender>
        <Text>{truncatedText}</Text>
      </TextDetails>
    </Notification>
  );
}

export default ChatNotification;