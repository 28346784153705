import styled from "styled-components"
import { clockedTheme } from "../../theme";

interface IconButtonProps {
    color?: 'danger' | 'success';
    showBackground?: boolean;
}


export const IconButton = styled.button<IconButtonProps>`
    width: 30px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
    margin: 0;
    background: ${props => props.showBackground ? props.theme.lightestGrey2 : 'none'};
    color: ${props => props.color === 'success' ? clockedTheme.successGreen 
        : props.color === 'danger' ? clockedTheme.alertRed : clockedTheme.lightGrey};

    &:hover {
        background-color: ${props => props.color === 'success' ? clockedTheme.lightGreen 
        : props.color === 'danger' ? clockedTheme.lightRed : '#f0f0f0'};
    }
`;

const styleTypes = {
    primary: {
        background: clockedTheme.primary,
        hoverBackground: clockedTheme.darkPrimary,
        color: 'white',
        borderColor: clockedTheme.lightPrimary,
    },
    success: {
        background: clockedTheme.lightGreen,
        hoverBackground: clockedTheme.successGreen,
        color: clockedTheme.darkGreen,
        borderColor: clockedTheme.darkGreen,
    },
    danger: {
        background: clockedTheme.lightRed,
        hoverBackground: clockedTheme.alertRed,
        color: clockedTheme.darkRed,
        borderColor: clockedTheme.darkRed,
    },
    warning: {
        background: clockedTheme.lightWarning,
        hoverBackground: clockedTheme.warning,
        color: clockedTheme.darkWarning,
        borderColor: clockedTheme.darkWarning,
    }
}

export const sizeTypes = {
    small: {
        fontSize: '12px',
        padding: '5px 10px',
    },
    medium: {
        fontSize: '14px',
        padding: '10px 20px',
    },
    large: {
        fontSize: '18px',
        padding: '12px 24px',
    }
}

type SecondaryButtonProps = {
    bordered?: boolean;
    styleType?: keyof typeof styleTypes;
    size?: keyof typeof sizeTypes;
}

export const SecondaryButton = styled.button<SecondaryButtonProps>`
    border: none;
    border-radius: 3px;
    padding: ${props => props.size ? sizeTypes[props.size].padding : '5px 10px'};
    font-size: ${props => props.size ? sizeTypes[props.size].fontSize : '14px'};
    margin: 0;
    font-weight: bold;
    background-color: ${props => props.styleType ? styleTypes[props.styleType].background : 'transparent'};
    color: ${props => props.styleType ? styleTypes[props.styleType].color : props.theme.lightGrey};
    cursor: pointer;
    border: ${props => props.bordered ? `1px solid ${props.styleType ? styleTypes[props.styleType].borderColor : props.theme.lighterGrey}` : 'none'};

    &:hover {
        background-color: ${props => props.styleType ? styleTypes[props.styleType].hoverBackground : '#f0f0f0'};
    }

    svg {
        margin-right: 5px;
    }
`;