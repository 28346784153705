import styled from "styled-components";

import { flexColumnBase } from "../../../../theme";

export const Label = styled.label`
  color: ${(props) => props.theme.standardText};
  font-family: CeraRoundBasic-Medium;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const InputBox = styled.input`
  border: 2px solid #a1a1a1;
  border-radius: 5px;
  color: ${(props) => props.theme.standardText};
  font-family: CeraRoundBasic-Medium;
  font-size: 0.8rem;
  max-width: 20rem;
  outline: none;
  padding: 6px;

  &:focus {
    box-shadow: 0 0 10px #a1a1a1;
  }
`;

export const FormContainer = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  justify-content: center;
`;

export const InputErrorMessage = styled.span`
  color: red;
  font-size: 0.8rem;
  font-family: CeraRoundBasic-Regular;
`;

export const FieldSet = styled(styled.fieldset(flexColumnBase))`
  border: none;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1.5rem 2rem 1rem;
  padding: 0;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 50px 0;
  width: 100%;
`;