import { faCheck, faCopy, faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import Button from "../../../../../components/Button"
import Modal from "../../../../../components/Modal/Modal"
import { InputContainer, Label, Select } from "../../../../../components/NewForms/Inputs"
import { useActionLoader } from "../../../../../hooks/useActionLoader"
import { RootState } from "../../../../../reducers"
import { notifyTeam } from "../../../../../reducers/shifts/actions"
import { clockedTheme } from "../../../../../theme"
import SimpleDate from "../../../../../utils/SimpleDate"
import { getDateForBackend } from "../../../../../utils/timeUtils"
import { Header2, Text } from "../../../../common.styles"


const Detail = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NotifyTeamModal = ({ close, startPeriod }: { close: {(): void}, startPeriod: Date }) => {

  const { employees } = useSelector((state: RootState) => state.employees);
  const { location } = useSelector((state: RootState) => state.location);
  const [teamOptions, setTeamOptions] = useState<{label: string, value: string}[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const { callAction: sendNotifications, done: doneSending } = useActionLoader(notifyTeam,
    'Notification sent!',
    "Couldn't notify team"
  )

  useEffect(() => {
    if (location) {
      const teams = location.teams.map(team => {
        return {
          label: team.name,
          value: team.id as string
        }
      })
      setTeamOptions([
        { value: 'all', label: 'All teams'},
        ...teams
      ]);
    }
  }, [location]);

  const submitNotification = () => {
    let teams = [];
    if (selectedTeams.some(v => v === 'all')) {
      teams = location?.teams.map(team => team.id as string) || [];
    }
    else {
      teams = selectedTeams;
    }
    sendNotifications({
      teamIds: teams,
      periodStart: new SimpleDate(startPeriod).getDateLabel()
    });
  }

  return (
    <Modal
      onClickOut={close}
      compact
    >
      <Header2 style={{marginBottom: '0'}}><FontAwesomeIcon icon={faCopy}/>Notify team of schedule updates</Header2>
      {!doneSending
        ? <Detail>
            <InputContainer>
              <Label>Select the teams to notify</Label>
              <Select
                options={teamOptions}
                isMulti
                onChange={(selected: any) => setSelectedTeams(selected.map((team: any) => team.value))}
              />
            </InputContainer>
          </Detail>
        : <>
          <Text color={clockedTheme.successGreen}><FontAwesomeIcon icon={faCheck} /> Your team's been notified!</Text>
        </>
        }
      <Detail>
        {!doneSending 
          ? <>
            <Button solid={false} borderless backgroundColor={clockedTheme.alertRed} onClick={close}>Cancel</Button>
            <Button solid onClick={submitNotification}>
              <FontAwesomeIcon icon={faPaperPlane} /> Notify team
            </Button>
          </>
          : <Button solid onClick={close}>Done</Button>
        }
      </Detail>
    </Modal>
  )
}