import { UserModel, NotificationModel } from "../../classes/api/types/user";

export interface UserState {
  user: UserModel | undefined;
  notifications: NotificationModel[]
}

const initialUserStoreState: UserState = {
  user: undefined,
  notifications: []
}

export enum UserActionType {
  SET_USER = "SET_USER",
  CLEAR_USER = "CLEAR_USER",
  UPDATE_USER = "UPDATE_USER",
  SET_NOTIFICATIONS = "SET_NOTIFICATIONS",
  CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS",
  MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ"
}

interface UserAction {
  type: UserActionType;
  payload?: any;
}

export const UserReducer = (state: UserState = initialUserStoreState, action: UserAction) => {
  switch (action.type) {
    case UserActionType.SET_USER:
      return {
          user: action.payload
      }
    case UserActionType.UPDATE_USER:
      return {
          user: {
            ...state.user,
            ...action.payload
          }
      }
    case UserActionType.CLEAR_USER:
      return {
        user: undefined
      }
    case UserActionType.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      }
    case UserActionType.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: []
      }
    case UserActionType.MARK_NOTIFICATIONS_READ:
      return {
        ...state,
        user: {
          ...state.user,
          notificationCount: 0
        },
        notifications: state.notifications.map((n: NotificationModel) => ({
          ...n,
          seen: true
        }))
      }
    default:
      return state;
  }
}