import { ShiftModel } from "../../classes/api/types/shift"
import * as helpers from '../helpers';

export const updateShift = (shifts: ShiftModel[], shift: ShiftModel): ShiftModel[] => {
  const updatedShifts = helpers.updateById<ShiftModel>(shifts, shift);
  return updatedShifts;
}

export const deleteShift = (shifts: ShiftModel[], shiftData: ShiftModel | ShiftModel[]): ShiftModel[] => {
  let updatedShifts = []
  if (Array.isArray(shiftData)) {
    const ids = shiftData.map((s: ShiftModel) => s.id);
    updatedShifts = shifts.filter((s: ShiftModel) => !ids.includes(s.id));
  }
  else {
    updatedShifts = helpers.removeById<ShiftModel>(shifts, shiftData.id || '');
  }
  return [...updatedShifts];
}