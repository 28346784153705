import { EmployeeFilter } from "../../containers/Manager/Employees/components/EmployeesFilter";
import { ApiInterface } from "./types";
import { ShiftApi, ShiftModel } from "./types/shift";

export class Shift implements ShiftApi {
  apiInstance: ApiInterface;
  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  create(shiftData: ShiftModel | ShiftModel[]) {
    let payload = shiftData
    if (!Array.isArray(shiftData)) {
      payload = [shiftData]; // Wrap in array if only 1 record
    }
    return this.apiInstance.sendRequest('/shift/schedule/bulk-create/', "POST", payload);
  }

  clockIn(shiftData: ShiftModel) {
    return this.apiInstance.sendRequest(
      `/shift/shifts/`,
      'POST',
      shiftData
    );
  }

  update(shiftData: ShiftModel | ShiftModel[]) {
    let url = '/shift/schedule/bulk-update/';
    if (!Array.isArray(shiftData)) {
      url = `/shift/shifts/${shiftData.id}/`; // for bulk creating
    }
    return this.apiInstance.sendRequest(
      url,
      "PATCH",
      shiftData
    );
  }

  delete(shiftData: ShiftModel | ShiftModel[]) {
    if (!Array.isArray(shiftData)) {
      return this.apiInstance.sendRequest(`/shift/shifts/${shiftData.id}/`, "DELETE");
    }
    return this.apiInstance.sendRequest(`/shift/schedule/bulk-delete/`, "POST", shiftData);
  }
  
  getOne(id: string) {
    return this.apiInstance.sendRequest(`/shift/shifts/${id}/`, "GET");
  }

  getAll(params: any) {
    return this.apiInstance.sendRequest(
      "/shift/shifts/",
      "GET",
      {},
      params
    );
  }

  copySchedule(periodStart: string, employees: string[] = []) {
    return this.apiInstance.sendRequest(`/job/shift-copy/`, 'POST', {
      periodStart,
      employees
    });
  }

  getScheduleExport(periodStart: string, exportType: string = 'csv', filters: EmployeeFilter, period: number) {
    return this.apiInstance.sendRequest(
      `/shift/exports/schedule/${exportType}/${periodStart}/`,
      'POST',
      {
        teams: filters.teamFilters,
        roles: filters.shiftTypeFilters,
        managers: filters.managerFilters,
        weeks: period
      }
    );
  }

  clearSchedule(periodStart: string) {
    return this.apiInstance.sendRequest(
      `/shift/schedule/clear/`,
      'POST',
      { periodStart }
    );
  }

  notifyTeam(employees: string[]) {
    return this.apiInstance.sendRequest(
      `/shift/schedule/notify-team/`,
      'POST',
      employees
    );
  }

  suggestedShiftTimes(employeeId: string) {
    return this.apiInstance.sendRequest(
      `/shift/suggested-shift-times/${employeeId}/`,
      'GET'
    );
  }
}
