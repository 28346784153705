import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { EmployeeModel } from "../../../../../../classes/api/types/employee";
import { ShiftModel } from "../../../../../../classes/api/types/shift";
import { ScheduleActions, ScheduleTableContext } from "../../../../../../containers/Manager/SchedulePage/state";
import { useActionLoader } from "../../../../../../hooks/useActionLoader";
import { RootState } from "../../../../../../reducers";
import { createShift } from "../../../../../../reducers/shifts/actions";
import { apiInstance } from "../../../../../../utils";
import { ShiftForm } from "../../ShiftForm"

export const CreateOneShiftForm = ({ hide, employee }: { hide: {(): void}, employee: EmployeeModel | undefined}) => {
  const { callAction: callCreateAction, done } = useActionLoader(createShift, 
    'Shift created', `Couldn't create shift`);
  const { dispatch } = useContext(ScheduleTableContext);
  const [data, setData] = useState<Partial<ShiftModel>>();
  const { location } = useSelector((state: RootState) => state.location);
  const { employees } = useSelector((state: RootState) => state.employees);
  const [defaultColor, setDefaultColor] = useState<string | undefined>();
  const [suggestedShiftTimes, setSuggestedShiftTimes] = useState<{ start: string, end: string, color: string }[] | undefined>();

  const onSubmit = (data: Partial<ShiftModel>) => {
    setData(data);
    callCreateAction(data);
  };

  useEffect(() => {
    getSuggestedShiftTimes();
  }, [employee])

  useEffect(() => {
    if (location && employee) {
      if (employee.teams.length) {
        const team = location.teams.find(t => t.id === employee.teams[0]);
        if (team) {
          setDefaultColor(team.color);
        }
      }
    }
  }, [location, employees])

  useEffect(() => {
    if (done) {
      dispatch({
        type: ScheduleActions.TRACK_CHANGE,
        payload: [data?.employee]
      })
      hide();
    }
  }, [done, dispatch, data])

  const getSuggestedShiftTimes = () => {
    if (employee && employee.id) {
      apiInstance.shifts.suggestedShiftTimes(employee.id).then(r => {
        setSuggestedShiftTimes(r.data);
      });
    }
  }

  return <ShiftForm
      hide={hide}
      onSave={onSubmit}
      defaultColor={defaultColor}
      suggestedShiftTimes={suggestedShiftTimes}
      showSuggestedTimes={true}
      employee={employee}
    />
}