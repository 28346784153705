import styled from "styled-components";

export const Arrow = styled.div`
  cursor: pointer;
  text-decoration: none;
  padding: 0.5rem;
  height: 100%;
  width: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: lightgray;
  }
`;

export const Container = styled.div`
  align-items: center;
  border: 1px solid ${props => props.theme.lightestGrey};
  border-radius: 4px;
  color: #4d4d4d80;
  display: flex;
  font-family: CeraRoundBasic-Medium;
  font-size: 1.25rem;
  justify-content: space-between;
  height: 50px;
  min-width: 220px;
  background: white;
`;
