import {
  eachDayOfInterval,
  format,
  endOfDay,
  parse,
  differenceInDays,
  startOfDay,
  compareAsc,
  isSameMonth,
  differenceInHours,
  differenceInSeconds,
  formatDistanceToNow,
  formatRelative
} from "date-fns";

export default class SimpleDate {
  private readonly date: Date = new Date();

  constructor(date: Date) {
    this.date = date;
  }

  public static from(dateString: string): SimpleDate {
    if (dateString.length === 10) {
      return SimpleDate.fromDateString(dateString);
    } else {
      return SimpleDate.fromDateTimeString(dateString);
    }
  }

  public static fromDateString(date: string): SimpleDate {
    const year = parseInt(date.substring(0, 4));
    const month = parseInt(date.substring(5, 7)) - 1;
    const day = parseInt(date.substring(8, 11));
    return new SimpleDate(new Date(year, month, day));
  }

  public static fromDateTimeString(date: string): SimpleDate {
    const year = parseInt(date.substring(0, 4));
    const month = parseInt(date.substring(5, 7)) - 1;
    const day = parseInt(date.substring(8, 10));
    const hour = parseInt(date.substring(11, 13));
    const minute = parseInt(date.substring(14, 16));
    return new SimpleDate(new Date(year, month, day, hour, minute));
  }

  public getDate(): Date {
    return this.date;
  }

  public static addLeadingZero = (threshold: number, val: any) => {
    if (val < threshold) {
      return `0${val}`;
    }
    return val;
  }

  getMonthAndDayLabel(): string {
    const label = format(this.date, "LLL d");
    return label;
  }

  /**
   * E.g. 10:00 AM
   */
  getTimeLabel = (includeAm = true) => {
    const minutes: any = SimpleDate.addLeadingZero(10, this.date.getMinutes());
    let militaryHours = this.date.getHours() % 12;
    let hours = militaryHours ? militaryHours : 12;
    const amPm = this.date.getHours() < 12 ? 'AM' : 'PM';
    return `${hours}:${minutes}${includeAm ? ` ${amPm}` : ''}`;
  };

  /**
   * E.g. 05:00 AM
   */
  get24TimeLabel = (includeAm = true) => {
    const minutes: any = SimpleDate.addLeadingZero(10, this.date.getMinutes());
    const hours = SimpleDate.addLeadingZero(10, this.date.getHours())
    const amPm = this.date.getHours() < 12 ? 'AM' : 'PM';
    return `${hours}:${minutes}${includeAm ? ` ${amPm}` : ''}`;
  };

  /**
   * E.g. 2023-01-01
   */
  getDateLabel = () => {
    const month: any = SimpleDate.addLeadingZero(10, this.date.getMonth() + 1);
    const day: any = SimpleDate.addLeadingZero(10, this.date.getDate());
    return `${this.date.getFullYear()}-${month}-${day}`;
  };

  /**
  * E.g. 2023-01-01 10:00 AM
  */
  getDateTimeLabel = () => {
    return `${this.getDateLabel()} ${this.getTimeLabel(false)}`.trim();
  }

  /**
   * E.g. Monday, January 1 or Mon, Jan 1
   */
  getMonthAndDayOfWeekLabel = (opts?: { short?: boolean }) => {
    if (opts?.short) {
      return format(this.date, "eee, LLL d");
    }
    return format(this.date, "eeee, LLL d");
  };

  /**
   * E.g. April 1, 2023
   */
  getFullMonthAndDayLabel = () => {
    const label = format(this.date, "LLLL d, yyyy");
    return label;
  };

  shortenedDistanceToNowLabel = () => {
    const diff = new Date().getTime() - this.date.getTime();
    const diffInMinutes = Math.floor(diff / 1000 / 60);
    if (Math.floor(diffInMinutes) === 0) {
      return 'Just now'
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes}m ago`
    }
    else if (diffInMinutes < 60 * 24) {
      return `${Math.floor(diffInMinutes / 60)}h ago`
    }
    else {
      return `${Math.floor(diffInMinutes / (60 * 24))}d ago`
    }
  };

  getDayDifferenceToNow = () => {
    const dayDifference = differenceInDays(new Date(), this.date);
    const modifier = compareAsc(new Date(), this.date);
  
    const differenceValue = dayDifference * modifier;
    return differenceValue;
  };

  getHoursDifference = (date: string) => {
    const start = SimpleDate.from(date);
    const difference = differenceInHours(this.date, start.getDate());
    return difference;
  }

  getSecondsDifference = (date: string) => {
    const start = SimpleDate.from(date);
    const difference = differenceInSeconds(this.date, start.getDate());
    return difference;
  }

  /**
   * E.g. April 1, 2023 10:00 AM
   */
  getMonthDayAndTimeLabel = () => {
    return this.getMonthAndDayLabel() + ' ' + this.getTimeLabel();
  }

  isAfterToday = () => {
    return differenceInDays(this.date, new Date()) < 0;
  }

  isSameDay = (date: SimpleDate) => {
    return this.getDate().getDate() === date.getDate().getDate();
  }

  isOvernight = (startDate: string, endDate: string) => {
    const start = SimpleDate.from(startDate);
    const end = SimpleDate.from(endDate);
    return start.isSameDay(end);
  }

  matchesDayAndMonth = (date: Date) => {
    return this.getDate().getDate() === date.getDate() && this.getDate().getMonth() === date.getMonth();
  }
}