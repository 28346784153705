import styled from 'styled-components';

export const Container = styled.div<{ $on: boolean }>`
  width: 45px;
  height: 25px;
  background-color: ${props => props.$on ? props.theme.primary : props.theme.lighterGrey};
  border-radius: 50px;
  position: relative;
  transition: background-color 0.1s ease;
  cursor: pointer;
`

export const Toggle = styled.div<{ $on: boolean }>`
  width: 18px;
  height: 18px;
  border-radius: 100%;
  box-shadow: ${props => props.theme.boxShadow.flat};
  background-color: white;
  position: absolute;
  left: ${props => props.$on ? '54%' : '6%'};
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.1s ease;
`