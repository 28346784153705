import { ApiInterface } from "./types";
import { LeaveRequestApi, LeaveRequestModel } from "./types/leaveRequest";

export class LeaveRequest implements LeaveRequestApi {
  apiInstance: ApiInterface;
  route = '/leave-request/requests'
  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  create(request: LeaveRequestModel) {
    return Promise.reject('Not implemented')
  }

  delete(request: LeaveRequestModel) {
    return Promise.reject('Not implemented')
  }

  update(request: LeaveRequestModel) {
    return this.apiInstance.sendRequest(
      `${this.route}/${request.id}/`,
      "PATCH",
      request
    );
  }

  getOne(id: string) {
    return this.apiInstance.sendRequest(`${this.route}/${id}`, "GET");
  }

  getAll() {
    return this.apiInstance.sendRequest(`${this.route}/`, "GET");
  }

  markApproved(request: LeaveRequestModel) {
    console.log(request)
    return this.apiInstance.sendRequest(`${this.route}/${request.id}/approve/`, "POST");
  }

  markDeclined(request: LeaveRequestModel) {
    return this.apiInstance.sendRequest(`${this.route}/${request.id}/decline/`, "POST");
  }

  markInReview(request: LeaveRequestModel) {
    return this.apiInstance.sendRequest(`${this.route}/${request.id}/start-review/`, "POST");
  }
}
