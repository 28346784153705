import { useContext } from 'react';
import { useRef } from 'react';
import { ScheduleTableContext } from '../../../../../containers/Manager/SchedulePage/state';
import useOnClickOutside from '../../../../../hooks/useClickOutside';
import * as S from './index.styles';
import { useState } from 'react';
import { useEffect } from 'react';
import { applyTimeToDate, formatTimeString, getDateForBackend, getHourDifference } from '../../../../../utils/timeUtils';
import ColorPicker, { colorPickerPalette } from '../../../../Forms/Components/ColorPicker';
import Button from '../../../../Button';
import { clockedTheme } from '../../../../../theme';
import { Header3, Text } from '../../../../../containers/common.styles';
import { ShiftModel } from '../../../../../classes/api/types/shift';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faExclamationCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import SimpleDate from '../../../../../utils/SimpleDate';
import useShiftColor from '../../../../../hooks/useShiftColor';
import { EmployeeModel } from '../../../../../classes/api/types/employee';
import { Select } from '../../../../NewForms/Inputs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../reducers';

interface Props {
  hide: {(): void},
  onSave: {(s: Partial<ShiftModel>, updateColors: boolean): void}
  onDelete?: {(s: Partial<ShiftModel>): void}
  fixedPosition?: boolean
  hideColor?: boolean
  size?: "small" | "medium",
  defaultColor?: string,
  suggestedShiftTimes?: { start: string, end: string, color: string }[]
  suggestedTimesLoading?: boolean
  showSuggestedTimes?: boolean
  employee?: EmployeeModel
}

export const ShiftForm = ({ hide, onSave, onDelete, fixedPosition, hideColor, size="small", 
    defaultColor, suggestedShiftTimes, showSuggestedTimes, employee }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, hide);
  const defaultShift = {
    id: 'temp',
    plannedStartTime: '2021-01-01T09:00:00Z',
    plannedEndTime: '2021-01-01T17:00:00Z',
    color: defaultColor || colorPickerPalette[0],
    tag: ''
  }
  const { scheduleState } = useContext(ScheduleTableContext);
  const { location } = useSelector((state: RootState) => state.location);
  const [selectedShift, setSelectedShift] = useState(defaultShift);
  const [startTime, setStartTime] = useState<string>('09:00')
  const [endTime, setEndTime] = useState<string>('17:00')
  const [color, setColor] = useShiftColor(employee)
  const [position, setPosition] = useState<{ x: string, y: string }>({ x: '', y: '' });
  const [isOvernight, setIsOvernight] = useState(false);
  const [extendedShiftWarning, setExtendedShiftWarning] = useState(false);
  const [colorClicked, setColorClicked] = useState(false);
  const [tag, setTag] = useState<string>('');

  useEffect(() => {
    if (scheduleState.selectedShift.id) {
      setSelectedShift(scheduleState.selectedShift);
    }
  }, [selectedShift]);

  useEffect(() => {
    setColor(defaultColor || colorPickerPalette[0]);
  }, [defaultColor]);

  useEffect(() => {
    if (!fixedPosition) {
      const allowedWidth = document.documentElement.clientWidth - 250;
      const allowedHeight = document.documentElement.clientHeight - 340;
      const dimensions = ref.current?.getBoundingClientRect();
      if (dimensions) {
        if (dimensions.right > allowedWidth && dimensions.top > allowedHeight) {
          setPosition({ x: '330%', y: '-500%' })
        }
        else if (dimensions.right > allowedWidth) {
          setPosition({ x: '260%', y: '50%' })
        }
        else if (dimensions.top > allowedHeight) {
          setPosition({ x: '0', y: '-500%' })
        }
        else {
          setPosition({ x: '0', y: '0' })
        }
      }
    }
  }, []);

  useEffect(() => {
    if (selectedShift) {
      setColor(selectedShift.color || defaultColor || colorPickerPalette[0]);
      setTag(selectedShift.tag || '');
    }
    if (startTime && endTime) {
      const start = applyTimeToDate(SimpleDate.from(selectedShift.plannedStartTime).getDate(), startTime);
      const end = applyTimeToDate(SimpleDate.from(selectedShift.plannedEndTime).getDate(), endTime)
      if (start > end) {
        setIsOvernight(true);
        end.setDate(end.getDate() + 1);
      }
      else {
        setIsOvernight(false);
      }

      if (Math.abs(getHourDifference(start, end)) > 12) {
        setExtendedShiftWarning(true);
      }
      else {
        setExtendedShiftWarning(false);
      }
    }
  }, [startTime, endTime, selectedShift]);
  
  useEffect(() => {
    if (selectedShift.id) {
      setStartTime(SimpleDate.from(selectedShift.plannedStartTime).get24TimeLabel(false));
      setEndTime(SimpleDate.from(selectedShift.plannedEndTime).get24TimeLabel(false));
    }
  }, [selectedShift]);

  const saveShift = (start: string, end: string, color: string) => {
    const startDate = applyTimeToDate(SimpleDate.from(selectedShift.plannedStartTime).getDate(), start);
    const endDate = applyTimeToDate(SimpleDate.from(selectedShift.plannedEndTime).getDate(), end);

    const overnight = endDate < startDate;

    if (isOvernight || overnight) {
      endDate.setDate(endDate.getDate() + 1);
    }
    onSave({
      ...selectedShift,
      plannedStartTime: getDateForBackend(startDate),
      plannedEndTime: getDateForBackend(endDate),
      tag,
      color
    }, hideColor ? colorClicked : true);
  }

  const onSubmit = () => {
    saveShift(startTime, endTime, color)
  }

  const selectSuggestedTime = (time: { start: string, end: string, color: string }) => {
    setStartTime(time.start);
    setEndTime(time.end);
    setColor(time.color);
    saveShift(time.start, time.end, time.color);
  }

  return (
    (position.x || fixedPosition) ?
    <S.Container ref={ref} position={position}>
      <h5>{!selectedShift.id.startsWith('temp') ? 'UPDATE SHIFT' : 'NEW SHIFT'}</h5>
      <S.InnerContainer>
        <S.MainForm>
          {!fixedPosition && <Header3 style={{ marginBottom: '10px' }}>{SimpleDate.from(selectedShift.plannedStartTime).getMonthAndDayOfWeekLabel()}</Header3>}

          <S.InputItem>
            <S.Label>Start</S.Label>
            <S.TimeInput type="time" step="900" value={startTime} onChange={e => setStartTime(e.target.value)} />
          </S.InputItem>

          <S.InputItem>
            <S.Label>End</S.Label>
            <S.TimeInput type="time" step="900" value={endTime} onChange={e => setEndTime(e.target.value)} />
          </S.InputItem>

          <S.InputItem>
            <S.Label>Tag</S.Label>
            <S.TagSelect
              options={location?.shiftTags?.map(tag => ({ label: tag, value: tag })) || []}
              value={{ label: tag, value: tag }}
              onChange={(e: any) => setTag(e.value)}
              placeholder="Optional"
            />
          </S.InputItem>

          <S.ColorContainer hide={hideColor && !colorClicked} onClick={() => setColorClicked(true)}>
            <S.InputItem>
              <S.Label>Color</S.Label>
              <ColorPicker color={color} onChange={setColor} />
            </S.InputItem>
          </S.ColorContainer>
          {(hideColor && colorClicked) && <S.ColorWarning>Colors will update</S.ColorWarning>}

          {isOvernight 
            ? <Text style={{ color: clockedTheme.lightGrey }}><FontAwesomeIcon icon={faMoon} /> Overnight shift</Text>
            : <Text style={{ opacity: 0 }}>empty</Text>
          }
          {extendedShiftWarning &&
            <Text style={{ color: clockedTheme.alertRed }}><FontAwesomeIcon icon={faExclamationCircle} /> This shift is longer than 12 hours</Text>
          }
        </S.MainForm>
          {showSuggestedTimes && <S.SuggestedTimes>
            Suggested
            {!!suggestedShiftTimes?.length && suggestedShiftTimes.map((shift, index) => (
              <S.SuggestedTimeButton 
                backgroundColor={shift.color}
                key={shift.start + shift.end + index}
                onClick={() => selectSuggestedTime(shift)}
              >
                {formatTimeString(shift.start)} - {formatTimeString(shift.end)}
                {shift.start === startTime && shift.end === endTime && <FontAwesomeIcon icon={faCheck} />}
              </S.SuggestedTimeButton>
            ))}
            {!suggestedShiftTimes?.length && <Text>No suggested times</Text>}
          </S.SuggestedTimes>
        }
      </S.InnerContainer>
        <S.Actions>
          <Button solid size={size} onClick={onSubmit}>Save</Button>
          {(!selectedShift.id.startsWith('temp') && onDelete) &&
            <Button solid={false} size={size}
              borderless backgroundColor={clockedTheme.alertRed}
              onClick={() => onDelete(selectedShift)}
            >Delete</Button>
          }
        </S.Actions>
    </S.Container>
    : <div ref={ref}></div>
  )
}