import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Route, Switch } from "react-router-dom";
import { faUsers, faClock, faChartLine, faCalendarCheck, 
  faPlaneDeparture, faBullhorn, faChartBar, faInbox, faComment } from '@fortawesome/free-solid-svg-icons'
import NotFound from '../containers/common/NotFound';
import SchedulePage from '../containers/Manager/SchedulePage';
import Conversation from '../containers/Manager/Chat/components/Conversation';
import CreateAnnouncement from '../containers/Manager/CreateAnnouncement';

const Reviews = React.lazy(() => import("../containers/Manager/Reviews"));
const LocationSettings = React.lazy(() => import("../containers/Manager/LocationSettings"));
const Dashboard = React.lazy(() => import("../containers/Manager/Dashboard"));
const VacationTracker = React.lazy(() => import("../containers/Manager/VacationTracker"));
const LeaveRequest = React.lazy(() => import("../containers/Manager/LeaveRequest"));
const Announcements = React.lazy(() => import("../containers/Manager/Announcements"));
const AddEmployee = React.lazy(() => import("../containers/Manager/AddEmployee"));
const Employees = React.lazy(() => import("../containers/Manager/Employees"));
const AddTeam = React.lazy(() => import("../containers/Manager/AddTeam"));
const EditEmployee = React.lazy(() => import("../containers/Manager/EditEmployee"));
const Notifications = React.lazy(() => import("../containers/Manager/Notifications"));
const Reports = React.lazy(() => import("../containers/Manager/Reports"));
const Review = React.lazy(() => import("../containers/Manager/Review"));
const AccountSettings = React.lazy(() => import("../containers/Employee/Profile"));
const Chat = React.lazy(() => import("../containers/Manager/Chat"));

export enum ManagerPagesEnum {
  SUMMARY,
  SCHEDULE,
  REVIEW,
  ANNOUNCEMENTS,
  CHAT,
  TEAM,
  VACATION_TRACKER,
  REPORTS
}

export const ManagerPages = {
  [ManagerPagesEnum.SUMMARY]: {
    route: "summary",
    label: "Dashboard",
    Icon: () => <FontAwesomeIcon icon={faChartLine} />,
    hidden: false
  },
  [ManagerPagesEnum.SCHEDULE]: {
    route: "schedule",
    label: "Schedule",
    Icon: () => <FontAwesomeIcon icon={faClock} />,
    hidden: false
  },
  [ManagerPagesEnum.REVIEW]: {
    route: "reviews",
    label: "Review",
    Icon: () => <FontAwesomeIcon icon={faCalendarCheck} />,
    hidden: false
  },
  [ManagerPagesEnum.ANNOUNCEMENTS]: {
    route: "announcements",
    label: "Announcements",
    Icon: () => <FontAwesomeIcon icon={faBullhorn} />,
    hidden: false
  },
  [ManagerPagesEnum.CHAT]: {
    route: "chat",
    label: "Chat",
    Icon: () => <FontAwesomeIcon icon={faComment} />,
    hidden: false
  },
  [ManagerPagesEnum.TEAM]: {
    route: "team",
    label: "Staff",
    Icon: () => <FontAwesomeIcon icon={faUsers} />,
    hidden: false
  },
  [ManagerPagesEnum.REPORTS]: {
    route: "reports",
    label: "Reports",
    Icon: () => <FontAwesomeIcon icon={faChartBar} />,
    hidden: false
  },
  [ManagerPagesEnum.VACATION_TRACKER]: {
    route: "vacation-tracker",
    label: "Vacation",
    Icon: () => <FontAwesomeIcon icon={faPlaneDeparture} />,
    hidden: false
  }
};

export const RoutesList = ({ url='/manager' }: { url?: string }) => (
  <Switch>
    <Route
      exact
      path={`${url}`}
      component={SchedulePage}
    />
    <Route
      exact
      path={`${url}/notifications`}
      component={Notifications}
    />
    <Route
      path={`${url}/${ManagerPages[ManagerPagesEnum.ANNOUNCEMENTS]?.route}/new`}
      component={CreateAnnouncement}
    />
    <Route
      path={`${url}/${ManagerPages[ManagerPagesEnum.ANNOUNCEMENTS]?.route}/:announcementId?`}
      component={Announcements}
    />
    <Route
      path={`${url}/${ManagerPages[ManagerPagesEnum.CHAT]?.route}`}
      component={Chat}
    />
    <Route
      path={`${url}/${ManagerPages[ManagerPagesEnum.CHAT]?.route}/conversations/:conversationId`}
      component={Conversation}
    />
    <Route
      path={`${url}/${ManagerPages[ManagerPagesEnum.SUMMARY]?.route}`}
      component={Dashboard}
    />
    <Route
      path={`${url}/${ManagerPages[ManagerPagesEnum.SCHEDULE]?.route}`}
      component={SchedulePage}
    />
    <Route
      exact
      path={`${url}/${ManagerPages[ManagerPagesEnum.REVIEW]?.route}`}
      component={Reviews}
    />
    <Route
      path={`${url}/${ManagerPages[ManagerPagesEnum.REVIEW]?.route}/:reviewId/:timesheetId?`}
      component={Review}
    />
    <Route
      exact
      path={`${url}/${ManagerPages[ManagerPagesEnum.TEAM]?.route}`}
      component={Employees}
    />
    <Route
      path={`${url}/${ManagerPages[ManagerPagesEnum.TEAM]?.route}/teams/new`}
      component={AddTeam}
    />
    <Route
      exact
      path={`${url}/${ManagerPages[ManagerPagesEnum.TEAM]?.route}/teams/:teamId`}
      component={AddTeam}
    />
    <Route
      exact
      path={`${url}/${ManagerPages[ManagerPagesEnum.VACATION_TRACKER]?.route}`}
      component={VacationTracker}
    />
    <Route
      path={`${url}/${ManagerPages[ManagerPagesEnum.VACATION_TRACKER]?.route}/:requestId`}
      component={LeaveRequest}
    />
    <Route
      exact
      path={`${url}/employees/new`}
      component={AddEmployee}
    />
    <Route
      exact
      path={`${url}/employees/:employeeId`}
      component={EditEmployee}
    />
    <Route
      path={`${url}/settings/:subpath?`}
      component={LocationSettings}
    />
    <Route
      path={`${url}/account`}
      component={AccountSettings}
    />
    <Route
      path={`${url}/reports`}
      component={Reports}
    />
    <Route>
      <NotFound />
    </Route>
  </Switch>
)