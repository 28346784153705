import { ApiInterface } from "./types";
import { LeaveRequestCommentApi, LeaveRequestCommentModel } from "./types/leaveRequestComment";

export class LeaveRequestComment implements LeaveRequestCommentApi {
  apiInstance: ApiInterface;
  route = '/leave-request/comments'
  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  create(comment: LeaveRequestCommentModel) {
    return this.apiInstance.sendRequest(
      `${this.route}/`,
      "POST",
      comment
    );
  }

  update(comment: LeaveRequestCommentModel) {
    return Promise.reject('Not implemented')
  }

  delete(comment: LeaveRequestCommentModel) {
    return Promise.reject('Not implemented')
  }

  getOne(id: string) {
    return this.apiInstance.sendRequest(`${this.route}/${id}`, "GET");
  }

  getAll() {
    return this.apiInstance.sendRequest(`${this.route}/`, "GET");
  }
}
