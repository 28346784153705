import { ReviewModel } from '../../classes/api/types/review';
import { TimesheetModel } from '../../classes/api/types/timesheet';
import * as helpers from '../helpers';

export const updateReview = (reviews: ReviewModel[], review: ReviewModel): ReviewModel[] => {
  const updatedReviews = helpers.updateById<ReviewModel>(reviews, review);
  return updatedReviews;
}

export const deleteReview = (reviews: ReviewModel[], reviewId: string): ReviewModel[] => {
  const updatedReviews = helpers.removeById<ReviewModel>(reviews, reviewId);
  return [...updatedReviews];
}

export const updateTimesheetInReview = (reviews: ReviewModel[], timesheet: TimesheetModel): ReviewModel[] => {
  const updatedReviews = [...reviews];
  const reviewIndex = updatedReviews.findIndex(r => r.id === timesheet.review);
  const review = updatedReviews[reviewIndex];
  const newReview = { ...review };
  const tsIndex = newReview.timesheets?.findIndex(ts => ts.id === timesheet.id);
  newReview.timesheets[tsIndex] = { ...timesheet };
  updatedReviews[reviewIndex] = { ...newReview };
  return updatedReviews;
}