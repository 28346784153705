import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();

  const setParam = useCallback((key: string, value: string) => {
    queryParams.set(key, value);
    history.replace({ search: queryParams.toString() });
  }, [queryParams]);

  const clearParams = useCallback(() => {
    history.replace({ search: '' });
    Object.keys(queryParams).forEach((key) => queryParams.delete(key));
  }, [queryParams]);

  return { queryParams, setParam, clearParams };
};