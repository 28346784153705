import React from "react";

import * as S from "./index.styles";

export interface LoaderProps {
  withOverlay?: boolean;
  fullScreen?: boolean;
  size?: "small" | "medium" | "large";
}

const Loader: React.FC<LoaderProps> = (props) => {
  const { withOverlay, fullScreen, size } = props;

  return (
    <S.Container withOverlay={withOverlay} fullScreen={fullScreen}>
      <S.LoaderContainer size={size} />
    </S.Container>
  );
};

export default Loader;
