import styled, { css } from "styled-components";

export const Container = styled.button<{
  solid: boolean;
  backgroundColor?: string;
  size?: string;
  borderless?: boolean;
  gradient?: boolean;
}>`
  background: ${(props) =>
    props.solid ? props.backgroundColor || props.theme.primary : `transparent`};
  border: 1px solid ${(props) => props.borderless ? 'transparent' : props.backgroundColor || props.theme.primary};
  color: ${(props) =>
    props.solid ? `white` : props.backgroundColor || props.theme.primary};
  cursor: pointer;
  font-family: CeraRoundBasic-Medium;
  font-size: ${props => props.size === "small" ? '0.75rem' : props.size === 'large' ? '1.25rem' : '1rem'};
  ${props => props.size === "small" ? 'min-width: 4rem;' : props.size ==='large' ? 'min-width: 8rem;' : 'min-width: 6rem;'}
  padding: ${props => props.size === 'small' ? '0.45rem 0.5rem' : props.size === 'large' ? '0.65rem 1rem' : '0.65rem 0.75rem'};
  transition: transform 0.1s linear;
  margin-right: 20px;
  border-radius: 20px;
  box-shadow:${props => props.borderless ? 'none' : '0 2px 4px 0 rgba(0, 0, 0, 0.1)'};

  &:only-child {
    margin-right: 0;  
  }
  max-width: 12rem;

  &:hover:enabled {
    opacity: ${props => props.borderless ? '0.5' : '1'};
    background: ${(props) =>
      props.solid ? `white` : props.borderless ? 'transparent' : props.backgroundColor || props.theme.primary};
    color: ${(props) =>
      props.solid ? props.backgroundColor || props.theme.primary : props.borderless ? props.backgroundColor : `white`};
  }

  ${props => props.gradient 
    ? css`
      background: linear-gradient(139deg, rgba(20,112,225,1) 0%, rgba(58,139,238,1) 25%, rgba(103,166,242,1) 100%);
    ` : ''}

  &:disabled,
  &[disabled] {
    background: ${(props) => props.theme.lighterGrey};
    cursor: default;
    border: ${(props) => props.theme.lighterGrey};
    color: ${(props) => props.theme.black};
  }
  svg {
    margin-right: 5px;
  }
`;
