import { useEffect, useState } from 'react';
import * as S from './index.styles';

type Props = {
  onToggle: (val: boolean) => any;
  defaultValue?: boolean;
  controlledValue?: boolean;
}

export const Toggle = ({ onToggle, defaultValue, controlledValue }: Props) => {

  const [on, setOn] = useState(!!defaultValue);

  const toggle = () => {
    const val = !on;
    if (controlledValue === undefined) {
      setOn(val);
    }
    onToggle(val);
  }

  useEffect(() => {
    if (controlledValue !== undefined) {
      setOn(controlledValue);
    }
  }, [controlledValue])

  return <S.Container $on={on} onClick={toggle} >
    <S.Toggle $on={on} />
  </S.Container>
};