import React, { useRef, useEffect, createContext, PropsWithChildren, useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { MessageModel } from '../../classes/api/types/message';
import { useActionLoader } from '../../hooks/useActionLoader';
import { RootState } from '../../reducers';
import { addMessage } from '../../reducers/chat/actions';
import { apiInstance } from '../../utils';
import WebSocketManager from '../../utils/Websocket';
import ChatNotification from './ChatNotification';

type WebsocketContext = {
  socket: WebSocketManager | null;
  isConnected: boolean;
  latestMessage: MessageModel | null;
}

export const SocketContext = createContext<WebsocketContext>({ socket: null, isConnected: false, latestMessage: null });

const useWebsocket = () => {
  const ws = useRef<WebSocketManager | null>(null);
  const { user } = useSelector((state: RootState) => state.user);
  const { callAction: receiveMessage } = useActionLoader(addMessage);
  const [isConnected, setIsConnected] = useState(false);
  const [latestMessage, setLatestMessage] = useState<MessageModel | null>(null);

  const createWebsocket = () => {
    try {
      const token = apiInstance.token
      if (!token || !apiInstance.baseUrl) {
        throw new Error("No token found");
      }
      const url = apiInstance.baseUrl.replace('http', 'ws');
      const route = `${url}/ws/chat/?auth=${token}`;
      const ws = new WebSocketManager(route);
      ws.connect();
      return ws;
    }
    catch (e) {
      console.error(e);
      return null;
    }
  }

  const onMessage = (message: string, userId?: string) => {
    const data: any = JSON.parse(message);
    setLatestMessage(data);
    receiveMessage({ message: data, isSelf: data.sender.id === userId });
    if (data.sender.id === userId) return;
    toast.custom(<ChatNotification chatId={data.conversation} text={data.text} sender={data.sender.fullName} />)
  };

  useEffect(() => {
    if (user?.id) {
      if (ws.current) {
        ws.current.clearSubscriptions();
      }
      ws.current = createWebsocket();
      if (ws.current) {
        ws.current.on('message', (message: string) => {
          console.log('on message event ', message);
          onMessage(message, user?.id);
        });
        ws.current.on('open', () => {
          setIsConnected(true);
        });
        ws.current.on('close', () => {
          setIsConnected(false);
        });
      }
    }
    const wsCurrent = ws.current;

    return () => {
      wsCurrent?.close();
      wsCurrent?.clearSubscriptions();
    };
  }, [apiInstance.token, user]);

  return { socket: ws.current, isConnected, latestMessage };
};

export const ChatManager = ({ children }: PropsWithChildren<{}>) => {
  const { socket, isConnected, latestMessage } = useWebsocket();
  return <SocketContext.Provider value={{ socket, isConnected, latestMessage }}>
    {children}
  </SocketContext.Provider>;
};