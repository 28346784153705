import { ApiInterface } from "./types";
import { CertificationTemplateModal, LocationApi, LocationModel } from "./types/location";

export class Location implements LocationApi {
  apiInstance: ApiInterface;

  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  update(location: LocationModel) {
    return this.apiInstance.sendRequest(
      `/company/locations/${location.id}/`,
      "PATCH",
      location
    );
  }

  getOne(input: string) {
    return Promise.reject('Not implemented')
  }

  delete(input: string) {
    return Promise.reject('Not implemented')
  }

  getAll() {
    return Promise.reject('Not implemented')
  }

  create(input: LocationModel) {
    return Promise.reject('Not implemented')
  }

  updateCertTemplate(cert: CertificationTemplateModal) {
    return this.apiInstance.sendRequest(
      `/company/certification-templates/${cert.id}/`,
      "PATCH",
      cert
    );
  }

  createCertTemplate(cert: CertificationTemplateModal) {
    return this.apiInstance.sendRequest(
      `/company/certification-templates/`,
      "POST",
      cert
    );
  }

  deleteCertTemplate(cert: CertificationTemplateModal) {
    return this.apiInstance.sendRequest(
      `/company/certification-templates/${cert.id}/`,
      "DELETE"
    );
  }
}
