import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ImageContainer = styled.img`
  height: 70vh;
  width: 100vw;
`;
