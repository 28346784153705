import {
  eachDayOfInterval,
  format,
  endOfDay,
  parse,
  differenceInDays,
  startOfDay,
  compareAsc,
  isSameMonth,
  subDays,
  addDays
} from "date-fns";
import SimpleDate from "./SimpleDate";

export const formatDateLocalePickerDate = (date: Date) => {
  const minutes = format(date, 'HH:mm');
  const days = format(date, 'yyyy-MM-dd')
  return `${days}T${minutes}`;
}

export const getPeriodIntervalDays = (date: Date, periodLength: number) => {
  const endDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + periodLength
  );
  const intervalDaysList = eachDayOfInterval({ start: date, end: endDate });

  return intervalDaysList;
};

export const removeTimezoneFromDate = (date: Date) => {
  const dateCopy = new Date();
  dateCopy.setMonth(date.getMonth());
  dateCopy.setDate(date.getDate());
  dateCopy.setFullYear(date.getFullYear());
  dateCopy.setHours(date.getHours());
  dateCopy.setMinutes(date.getMinutes());
  return dateCopy;
}

export const getDayOfWeekLabel = (date: Date) => {
  const label = format(date, "eeee");
  return label;
};

export const getDayOfMonthNumber = (date: Date) => {
  const label = format(date, "d");
  return label;
};

export const getShiftTimeLabel = (date: Date) => {
  const minutes: any = SimpleDate.addLeadingZero(10, date.getMinutes());
  let militaryHours = date.getHours() % 12;
  let hours = militaryHours ? militaryHours : 12;
  const amPm = date.getHours() < 12 ? 'a' : 'p';
  if (minutes !== '00') {
    return `${hours}:${minutes}${amPm}`;
  }
  return `${hours}${amPm}`;
}

export const getDateForBackend = (date: Date) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss+00:00");
}

export const getMonthAndDayLabel = (date: Date) => {
  const formattedDate = removeTimezoneFromDate(date);
  const label = format(formattedDate, "LLL d");
  return label;
};

export const getMonthAndDayOfWeekLabel = (date: Date) => {
  const label = format(date, "eeee, LLL d");
  return label;
};

export const getFullMonthAndDayLabel = (date: Date) => {
  const formattedDate = removeTimezoneFromDate(date);
  const label = format(formattedDate, "LLLL d, yyyy");
  return label;
};

export const parseDateFromDateAndTimePicker = (date: string, time: string) => {
  const parsedDate = new Date(`${date}T${time}`);
  return parsedDate;
};

export const applyTimeToDate = (date: Date, time: string) => {
  const parsedDate = new Date(`${format(date, 'yyyy-MM-dd')}T${time}`);
  return parsedDate;
}

export const formatTimeString = (time: string) => {
  const parsedDate = parse(time, 'HH:mm:ss', new Date());
  return format(parsedDate, 'h:mm a');
}

export const getPeriodStartAndEndDates = (
  periodStart: string,
  periodLength: number,
  baseDate: Date
) => {
  const periodStartDate = SimpleDate.from(periodStart).getDate();
  const dayDiff = differenceInDays(baseDate, periodStartDate);
  const currentPeriod = subDays(baseDate, dayDiff % periodLength);
  const endCurrentPeriod = addDays(currentPeriod, periodLength - 1)
  return { currentPeriod, endCurrentPeriod };
};

export const getStartAndEndOfDay = (baseDate: Date) => {
  const startTime = startOfDay(baseDate);
  const endTime = endOfDay(baseDate);

  return { startTime, endTime };
};

export const getDateRangeLabel = (start: Date, end: Date, compact = true, separator = '-') => {
  const sameMonth = isSameMonth(start, end);
  if (sameMonth) {
    const monthLabel = format(start, compact ? "MMM" : 'MMMM');
    const startDay = format(start, compact ? "d" : 'do');
    const endDay = format(end, compact ? "d" : 'do');
    return `${monthLabel} ${startDay} ${separator} ${endDay}`;
  } else {
    const startMonthLabel = format(start, compact ? "MMM" : 'MMMM');
    const startDay = format(start, compact ? "d" : 'do');
    const endMonthLabel = format(end, compact ? "MMM" : 'MMMM');
    const endDay = format(end, compact ? "d" : 'do');
    return `${startMonthLabel} ${startDay} ${separator} ${endMonthLabel} ${endDay}`;
  }
};

export const getStartAndEndBoundaries = (startTime: string, endTime: string) => {
  const start = new Date(startTime);
  const actualStart = new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate(),  start.getUTCHours(), start.getUTCMinutes());
  const end = new Date(endTime);
  const actualEnd = new Date(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate(), end.getUTCHours(), end.getUTCMinutes());
  return [actualStart, actualEnd];
}

export const createDateFromString = (date: string): Date => {
  const year = parseInt(date.slice(0, 4));
  const month = parseInt(date.slice(5, 2)) - 1;
  const day = parseInt(date.slice(8, 4));
  return new Date(year, month, day);
}

export const getHourDifference = (start: Date, end: Date) => {
  const difference = Math.abs(start.getTime() - end.getTime());
  return Math.ceil(difference / (1000 * 3600));
}

export const formatYMD = (date: Date) => {
  return date.toISOString().split('T')[0];
}

export const durationToHoursAndMinutes = (duration: number) => {
  const hours = Math.floor(duration);
  const minutes = Math.floor((duration - hours) * 60);
  return `${hours}h ${minutes}m`;
}