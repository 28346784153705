import { ShiftModel } from "../../../../../classes/api/types/shift";
import SimpleDate from "../../../../../utils/SimpleDate";

export const formatDatesFromDroppedShift = (droppedItem: ShiftModel, day: Date): Date[] => {
  if (droppedItem.plannedEndTime && droppedItem.plannedStartTime) {
    const shiftStart = SimpleDate.from(droppedItem.plannedStartTime);
    const shiftEnd = SimpleDate.from(droppedItem.plannedEndTime);
    const plannedStart = shiftStart.getDate();
    const plannedEnd = shiftEnd.getDate();

    const start = new Date(day.getFullYear(), day.getMonth(), day.getDate())
    start.setHours(plannedStart.getHours());
    start.setMinutes(plannedStart.getMinutes());
    const end = new Date(day.getFullYear(), day.getMonth(), day.getDate())
    end.setHours(plannedEnd.getHours());
    end.setMinutes(plannedEnd.getMinutes());

    // Handle overnight shifts
    if (!shiftStart.isSameDay(shiftEnd)) {
      end.setDate(end.getDate() + 1);
    }
    return [start, end]
  }
  return [new Date(), new Date()]
}