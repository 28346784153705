import React from "react";

import * as S from "./index.styles";

export interface ButtonProps {
  backgroundColor?: string;
  onClick?: () => void;
  solid: boolean;
  type?: "button" | "submit" | "reset";
  size?: "large" | "medium" | "small";
  disabled?: boolean;
  style?: {
    [key: string]: string;
  };
  borderless?: boolean;
  gradient?: boolean;
}

const Button: React.FC<ButtonProps> = React.memo((props) => {
  const {
    solid,
    onClick,
    children,
    backgroundColor,
    type,
    style,
    disabled,
    size,
    borderless,
    gradient
  } = props;

  return (
    <S.Container
      solid={solid}
      onClick={onClick}
      disabled={disabled}
      backgroundColor={backgroundColor}
      type={type}
      style={style}
      size={size}
      borderless={borderless}
      gradient={gradient}
    >
      {children}
    </S.Container>
  );
});

export default Button;
