import { ConversationModel } from '../../classes/api/types/conversation';
import * as helpers from './helpers';

export interface ChatState {
  conversations: ConversationModel[]
}

const initialState: ChatState = {
  conversations: []
}

export enum ChatActionType {
  SET_CONVERSATIONS = "SET_CONVERSATIONS",
  ADD_MESSAGE = "ADD_MESSAGE",
  SET_CONVERSATION_MESSAGES = "SET_CONVERSATION_MESSAGES",
  APPEND_CONVERSATION_MESSAGES = "APPEND_CONVERSATION_MESSAGES",
  SET_READ_RECEIPT = "SET_READ_RECEIPT"
}

interface ChatAction {
  type: ChatActionType;
  payload?: any;
}

export const ChatReducer = (state: ChatState = initialState, action: ChatAction) => {
  switch (action.type) {
    case ChatActionType.SET_CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload.map((conversation: ConversationModel) => ({
          ...conversation,
          messages: []
        }))
      }
    case ChatActionType.ADD_MESSAGE:
      return {
        ...state,
        conversations: state.conversations.map(conversation => {
          if (conversation.id === action.payload.message.conversation) {
            const onlySentMessages = conversation.messages.filter(message => message.id !== undefined)
            return {
              ...conversation,
              hasUnreadMessages: !action.payload.isSelf,
              messages: [ ...onlySentMessages, action.payload.message ],
              latestMessage: action.payload.message,
              latestMessageAt: new Date().toISOString()
            }
          }
          return conversation;
        })
      }
    case ChatActionType.SET_CONVERSATION_MESSAGES:
      return {
        ...state,
        conversations: state.conversations.map(conversation => {
          if (conversation.id === action.payload.conversationId) {
            return {
              ...conversation,
              messages: action.payload.messages.reverse(),
              totalMessages: action.payload.count
            }
          }
          return conversation;
        })
      }
    case ChatActionType.SET_READ_RECEIPT:
      return {
        ...state,
        conversations: helpers.updateOneinList({ id: action.payload, hasUnreadMessages: false }, state.conversations)
      }
    default:
      return state;
  }
}