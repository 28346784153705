import { isSameDay, isToday, set } from "date-fns";
import React, { RefObject, useEffect, useState, useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { ShiftModel } from "../../../classes/api/types/shift";
import { RootState } from "../../../reducers";
import {
  getDayOfWeekLabel,
  getDayOfMonthNumber,
} from "../../../utils/timeUtils";
import Loader from "../../Loader";
import { Shift } from "./components/Shift";
import ShiftCell from "./components/ShiftCell";
import * as S from "./index.styles";
import { getDayTotals, getEmployeeTotals, getScheduledShifts, ScheduleData } from "./utils";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { EventModel, EventType } from "../../../classes/api/types/event";
import { EmployeeModel } from "../../../classes/api/types/employee";
import { LocationModel } from "../../../classes/api/types/location";
import { ScheduleActions, ScheduleTableContext } from "../../../containers/Manager/SchedulePage/state";
import EventsMapper from "./components/EventsMapper";
import AvatarWithName from "../../Avatar";
import { Select } from "../../NewForms/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import SimpleDate from "../../../utils/SimpleDate";

export interface ScheduleTableProps {
  employees: EmployeeModel[];
  days: Date[];
  tableRef: RefObject<any>;
  location: LocationModel | undefined
}

const ScheduleTable: React.FC<ScheduleTableProps> = (props) => {
  const { dispatch } = useContext(ScheduleTableContext);
  const [isDataReady, setIsDataReady] = useState(false);
  const { events } = useSelector((state: RootState) => state.events);
  const [employeeTotals, setEmployeeTotals] = useState<{
    [key: string]: number;
  }>();
  const [dayTotals, setDayTotals] = useState<{ [key: string]: number }>();
  const [schedule, setSchedule] = useState<ScheduleData[]>([]);
  const {
    days,
    tableRef,
    employees
  } = props;
  const [sortingRule, setSortingRule] = useState(undefined);
  const { holidays } = props.location ? props.location : { holidays: [] };

  const processNewShifts = useCallback(async (events: EventModel[]): Promise<void> => {
    if (events) {
      const calcs = [getEmployeeTotals(events), getDayTotals(events, employees)];
      const schedule = getScheduledShifts(days, events, employees);
      setSchedule(sortSchedule(schedule, sortingRule));
      Promise.all(calcs).then(([ employeeSums, days ]) => {
        setEmployeeTotals(employeeSums);
        setDayTotals(days);
        setIsDataReady(true);
      })
    }

  }, [days, employees, sortingRule]);

  const sortSchedule = (schedule: ScheduleData[], rule?: string): ScheduleData[] => {
    const sortedSchedule = [...schedule];
    if (rule === 'firstName') {
      sortedSchedule.sort((a, b) => a.employee.firstName.localeCompare(b.employee.firstName));
    } else if (rule === 'lastName') {
      sortedSchedule.sort((a, b) => a.employee.lastName.localeCompare(b.employee.lastName));
    } else if (rule === 'team') {
      sortedSchedule.sort((a, b) => a.employee.teams[0]?.localeCompare(b.employee.teams[0]));
    }
    return sortedSchedule;
  }

  useEffect(() => {
    processNewShifts(events);
  }, [events, processNewShifts, isDataReady, employees]);

  const getHolidayNameForDate = (day: Date): string | undefined => {
    const holiday = holidays.find((holiday) => SimpleDate.fromDateString(holiday.date).matchesDayAndMonth(day))
    return holiday ? holiday.name : undefined;
  }

  if (!days || !employeeTotals || !dayTotals || !isDataReady) {
    return <Loader withOverlay={true} />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <S.TableContainer ref={tableRef}>
        <colgroup>
          <S.DateCol />
          {days.map((day) => (
            <S.TableColumn 
              key={day.getTime()}
            />
          ))}
        </colgroup>

        <thead>
          <S.HeaderRow>
            <S.TableHeader isHoliday={false}>
              <S.SortContainer>
                <span>
                  <FontAwesomeIcon icon={faSort} style={{ marginRight: '5px' }} />
                  Sort
                </span>
                <Select
                  options={[
                    { value: 'firstName', label: 'First name' },
                    { value: 'lastName', label: 'Last name' },
                    { value: 'teamName', label: 'Team' },
                  ]}
                  onChange={(e: any) => setSortingRule(e.value)}
                />
              </S.SortContainer>
            </S.TableHeader>
            {days.map((day) => {
              const holiday = getHolidayNameForDate(day);
              return (
                <S.TableHeader key={day.getTime()} isHoliday={!!holiday}>
                  <S.Date isToday={isToday(day)}>
                    {getDayOfWeekLabel(day)}
                    <S.DateLabel>{getDayOfMonthNumber(day)}</S.DateLabel>
                    <S.HoursLabel color="info">{dayTotals[day.toDateString()]?.toFixed(2) || 0}</S.HoursLabel>
                    <S.HolidayLabel>{holiday}</S.HolidayLabel>
                  </S.Date>
                </S.TableHeader>
              );
            })}
          </S.HeaderRow>
        </thead>

        <tbody>
          {schedule.map((schedule: any) => {
            return (
              <S.TableRow key={schedule.employee.id}>
                <S.EmployeeHeader>
                  <S.EmployeeHeaderContainer>
                    <AvatarWithName 
                      employee={schedule.employee} 
                      linkTo={`manager/employees/${schedule.employee.id}`} 
                      style={{ maxWidth: '170px' }}
                    />
                    <S.HoursLabel color="info">{employeeTotals[schedule.employee.id]?.toFixed(2) || 0}</S.HoursLabel>
                  </S.EmployeeHeaderContainer>
                </S.EmployeeHeader>
                {schedule.schedule.map((events: EventModel[], index: number) => {
                  if (!events.length) {
                    // Blank cell
                    return <ShiftCell
                      key={`${schedule.employee.id}${index}`}
                      employee={schedule.employee}
                      day={schedule.days[index]}
                      disablePlaceholder={false}
                    />
                  }
                  return <EventsMapper
                    key={`${schedule.employee.id}${index}`}
                    events={events}
                    employee={schedule.employee}
                    day={schedule.days[index]}
                  />
                })}
              </S.TableRow>
            );
          })}
        </tbody>
      </S.TableContainer>
    </DndProvider>
  );
};

export default ScheduleTable;
