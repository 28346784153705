import { CrudApi, IResponse } from ".";
import { EmployeeFilter } from "../../../containers/Manager/Employees/components/EmployeesFilter";
import { GenericEvent } from "./event";

export interface BreakModel {
  id: string;
  startTime: string;
  endTime: string;
  duration: number;
  shift: string;
}

export interface ShiftModel extends GenericEvent {
  color: string;
  status: string;
  duration?: number;
  plannedDuration?: number;
  overtimeDuration?: number;
  startTime?: string;
  endTime?: string;
  plannedStartTime?: string;
  plannedEndTime?: string;
  breaks: BreakModel[];
  shiftTypeAssignment?: string;
}

export enum ShiftStatus {
  ACTIVE = "active",
  COMPLETE = "complete",
  PLANNED = "planned"
}

export interface ShiftApi extends CrudApi<ShiftModel> {
  clockIn(shiftData: ShiftModel): Promise<IResponse<ShiftModel>>;
  create: (input: ShiftModel | ShiftModel[]) => Promise<IResponse<ShiftModel>>;
  update: (input: ShiftModel | ShiftModel[]) => Promise<IResponse<ShiftModel>>;
  copySchedule: (periodStart: string, teamFilters: string[]) => Promise<IResponse<void>>;
  getScheduleExport: (periodStart: string, exportType: string, filters: EmployeeFilter, period: number) => Promise<IResponse<{ url: string }>>;
  clearSchedule: (periodStart: string) => Promise<IResponse<void>>;
  notifyTeam: (employees: string[]) => Promise<IResponse<void>>;
  suggestedShiftTimes: (employee: string) => Promise<IResponse<{ start: string; end: string, color: string }[]>>;
}
