import { HashRouter, useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { RoutesList } from "./routes"
import styled from "styled-components"
import { Blanket } from "../components/Modal/Modal";
import useOnClickOutside from "../hooks/useClickOutside";
import { Suspense, useRef } from "react";
import { IconButton } from "../components/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";

const StyledModalApp = styled.div`
  position: absolute;
  top: 0;
  right: -100%;
  height: 100%;
  width: calc(100% - 250px);
  max-width: 750px;
  background-color: white;
  display: flex;
  flex-direction: column;
  animation: slide 0.3s forwards;
  overflow-y: scroll;
  padding: 0 20px;
  border-left: 1px solid ${props => props.theme.lightestGrey};

  @keyframes slide {
    100% { right: 0; }
  }
`;

const Close = styled.div`
  width: 100%;
  display: flex;
  padding-top: 20px;
`;

const ModalBlanket = styled(Blanket)`
  z-index: 200;
`;

const ModalApp = () => {
  const location = useLocation();
  const ref = useRef(null);
  const history = useHistory();
  
  const closeModal = (e: any) => {
    history.replace('/');
  }

  useOnClickOutside(ref, closeModal)
  console.log(location.pathname)

  if (location.pathname === '/') return null;
  return (
    <ModalBlanket>
      <StyledModalApp ref={ref}>
        <Close>
          <IconButton style={{ width: '40px' }}>
            <FontAwesomeIcon icon={faArrowLeft} size="2x" onClick={closeModal} />
          </IconButton>
        </Close>
        <RoutesList />
      </StyledModalApp>
    </ModalBlanket>
  )
}


const ModalRouter = () => {

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <HashRouter hashType="noslash">
        <ModalApp />
      </HashRouter>
    </Suspense>
  )
}

export default ModalRouter;