import { ApiInterface } from "./types";
import { ConversationApi, ConversationModel } from "./types/conversation";

export class Conversation implements ConversationApi {
  apiInstance: ApiInterface;
  route = '/messaging/conversations/'

  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  create(conversation: ConversationModel) {
    return this.apiInstance.sendRequest(
      this.route,
      "POST",
      conversation
    );
  }

  update(conversation: ConversationModel) {
    return this.apiInstance.sendRequest(
      `${this.route}${conversation.id}/`,
      "PATCH",
      conversation
    );
  }

  getAll() {
    return this.apiInstance.sendRequest(this.route, "GET");
  }

  delete(conversation: any) {
    return this.apiInstance.sendRequest(
      `${this.route}${conversation.id}/`,
      "DELETE"
    );
  }

  getOne(input: string) {
    return Promise.reject('Not implemented')
  }
}
