import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EmployeeModel } from "../classes/api/types/employee";
import { colorPickerPalette } from "../components/Forms/Components/ColorPicker";
import { RootState } from "../reducers";

const useShiftColor = (employee?: EmployeeModel, defaultColor?: string): [string, (color: string) => void] => {

  const { location } = useSelector((state: RootState) => state.location);
  const [color, setColor] = useState<string>(defaultColor || colorPickerPalette[0]);

  useEffect(() => {
    if (employee && location && employee.teams.length && !defaultColor) {
      const employeeTeam = employee.teams[0];
      const locationTeam = location.teams.find((team) => team.id === employeeTeam);
      if (locationTeam) {
        setColor(locationTeam.color);
      }
    }
  }, [location, employee]);

  return [color, setColor];
};

export default useShiftColor;