import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { EventReducer, EventsState } from "./events";
import { ReviewReducer, ReviewsState } from "./reviews";
import { ShiftReducer, ShiftsState } from "./shifts";
import { LeaveDayReducer, LeaveDayState } from './leaveDays';
import { EmployeeReducer, EmployeesState } from './employees';
import { UserReducer, UserState } from './users'
import { LocationReducer, LocationState } from './locations';
import { SessionReducer, SessionState } from './session';
import thunk from 'redux-thunk';
import { LeaveRequestReducer, LeaveRequestsState } from "./leaveRequests";
import { MessagingReducer, MessagingState } from "./messaging";
import { ChatReducer, ChatState } from './chat'
import { createEpicMiddleware } from "redux-observable";
import { sideEffects } from "./sideEffects";

const allReducers = combineReducers({
  user: UserReducer,
  location: LocationReducer,
  events: EventReducer,
  shifts: ShiftReducer,
  reviews: ReviewReducer,
  leaveDays: LeaveDayReducer,
  employees: EmployeeReducer,
  session: SessionReducer,
  leaveRequests: LeaveRequestReducer,
  messaging: MessagingReducer,
  chat: ChatReducer
});

export interface RootState {
  user: UserState;
  shifts: ShiftsState
  reviews: ReviewsState;
  employees: EmployeesState;
  events: EventsState;
  leaveDays: LeaveDayState;
  location: LocationState;
  session: SessionState;
  leaveRequests: LeaveRequestsState;
  messaging: MessagingState;
  chat: ChatState;
}

const sideEffectMiddleware = createEpicMiddleware();

const store = createStore(allReducers, compose(
  applyMiddleware(thunk),
  applyMiddleware(sideEffectMiddleware),
  // @ts-ignore
  window.devToolsExtension ? window.devToolsExtension() : f => f
));

sideEffectMiddleware.run(sideEffects);

export default store;