import { ApiInterface } from "./types";
import { TeamApi, TeamModel } from "./types/employee";

export class Team implements TeamApi {
  apiInstance: ApiInterface;
  route = '/user/teams'
  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  create(team: TeamModel) {
    return this.apiInstance.sendRequest(
      `${this.route}/`,
      "POST",
      team
    );
  }

  update(team: TeamModel) {
    return this.apiInstance.sendRequest(
      `${this.route}/${team.id}/`,
      "PATCH",
      team
    );
  }

  delete(team: TeamModel) {
    return this.apiInstance.sendRequest(
      `${this.route}/${team.id}/`,
      'DELETE'
    );
  }

  getOne(id: string) {
    return this.apiInstance.sendRequest(`${this.route}/${id}`, "GET");
  }

  getAll() {
    return this.apiInstance.sendRequest(`${this.route}/`, "GET");
  }
}
