import { GenericEvent } from "../../classes/api/types/event";
import * as helpers from '../helpers';

export const updateEvent = (events: GenericEvent[], event: GenericEvent): GenericEvent[] => {
  const updated = helpers.updateById<GenericEvent>(events, event);
  return updated;
}

export const deleteEvent = (events: GenericEvent[], eventData: GenericEvent | GenericEvent[]): GenericEvent[] => {
  let updatedEvents = []
  if (Array.isArray(eventData)) {
    const ids = eventData.map((s: GenericEvent) => s.id);
    updatedEvents = events.filter((s: GenericEvent) => !ids.includes(s.id));
  }
  else {
    updatedEvents = helpers.removeById<GenericEvent>(events, eventData.id || '');
  }
  return [...updatedEvents];
}