import { ApiInterface } from "./types";
import { ReviewApi, ReviewModel } from "./types/review";

export class Review implements ReviewApi {
  apiInstance: ApiInterface;
  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  getAll() {
    return this.apiInstance.sendRequest(
      '/shift/reviews/',
      'GET'
    );
  }

  update(input: any) {
    const review = { ...input };
    delete review.timesheets;
    return this.apiInstance.sendRequest(
      `/shift/reviews/${review.id}/`,
      'PATCH',
      review
    );
  }

  create(review: ReviewModel) {
    // @ts-ignore
    delete review.timesheets;
    return this.apiInstance.sendRequest(
      '/shift/reviews/',
      'POST',
      review
    );
  }

  getReviewExport(input: any) {
    return this.apiInstance.sendRequest(
      `/shift/exports/reviews/${input.fileType}/${input.reviewId}`,
      'GET'
    );
  }

  delete(id: string) {
    return this.apiInstance.sendRequest(
      `/shift/reviews/${id}/`,
      'DELETE'
    );
  }

  getOne(id: string) {
    return this.apiInstance.sendRequest(
      `/shift/reviews/${id}/`,
      'GET'
    );
  }
}
