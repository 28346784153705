const tokenLabel = "token";

class StorageController {
  storeToken(token: string) {
    localStorage.setItem(tokenLabel, token);
  }
  retrieveToken() {
    const encryptedToken = localStorage.getItem(tokenLabel);
    if (encryptedToken) {
      return encryptedToken;
    }
    return;
  }
  clearToken() {
    localStorage.removeItem(tokenLabel);
  }
}

export default StorageController;
