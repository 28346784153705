import { ApiInterface } from "./types";
import { MessageModel, MessageApi } from "./types/message";

export class Message implements MessageApi {
  apiInstance: ApiInterface;
  route = '/messaging/messages/'

  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  create(message: MessageModel) {
    return this.apiInstance.sendRequest(
      this.route,
      "POST",
      message
    );
  }

  getAll(input: any) {
    return this.apiInstance.sendRequest(
      this.route,
      "GET",
      {},
      input
    );
  }
}
