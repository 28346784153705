import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import Button from "../../components/Button";
import { clockedTheme } from "../../theme";
import { ReactComponent as Logo } from "../../assets/icons/LogoBlue.svg";
import LoginBackground from "../../assets/icons/loginBackground.svg";
import * as S from "./index.styles";
import { Card, Header3, PageContainer, Subtitle1 } from "../common.styles";
import Loader from "../../components/Loader";
import { InputErrorMessage } from "../../components/Forms/Components/FormField/index.styles";
import { useActionLoader } from "../../hooks/useActionLoader";
import { Link, useLocation } from "react-router-dom";
import FormItem from "../../components/Forms/Components/FormItem";
import { login } from "../../reducers/users/actions";


export interface ILoginFormInputs {
  username: string;
  password: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login: React.FC = () => {
  const query = useQuery()
  const { register, errors: formErrors, handleSubmit } = useForm<
    ILoginFormInputs
  >();
  const { callAction, isLoading, error } = useActionLoader(login,
    '', 'The username and password you entered does not match our records')
    
  const onSubmit = (data: ILoginFormInputs) => {
    callAction(data);
  };

  useEffect(() => {
    const hardRefresh = query.get('refresh');
    if (hardRefresh) {
      // eslint-disable-next-line
      location.assign('/');
    }
  }, []);

  return (
    <PageContainer>
      {isLoading && <Loader withOverlay={true} fullScreen={true} />}
      <S.LoginContainer>
        <S.LogoContainer>
          <Logo />
        </S.LogoContainer>
        <Card style={{ 
          width: '400px', margin: 0,
          backgroundImage: `url(${LoginBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}>
          <S.Container onSubmit={handleSubmit(onSubmit)}>
            <Header3 color={clockedTheme.black} style={{ margin: '30px 0' }}>Sign into your account</Header3>
            <FormItem
              label="Email or username"
              name="username"
              type="text"
              inputRef={register({ required: true })}
              style={{ paddingRight: '0' }}
            />
          {formErrors && formErrors["username"] && (
            <InputErrorMessage>{"This field is required"}</InputErrorMessage>
          )}
            <FormItem
              label="Password"
              name="password"
              type="password"
              inputRef={register({ required: true })}
              style={{ paddingRight: '0' }}
            />
            {formErrors && formErrors["password"] && (
              <InputErrorMessage>{"This field is required"}</InputErrorMessage>
            )}
            <InputErrorMessage style={{ marginTop: '20px' }}>{error && 'Incorrect username or password'}</InputErrorMessage>
            <S.ButtonContainer>
              <Button solid={true} backgroundColor={clockedTheme.primary}>
                Login
              </Button>
            </S.ButtonContainer>
            <S.ButtonContainer>
              <Link to={'/forgot-password'}><Subtitle1>Forgot password</Subtitle1></Link>
            </S.ButtonContainer>
          </S.Container>
        </Card>
      </S.LoginContainer>
      <S.VersionNumber>{process.env.REACT_APP_VERSION || 'Dev build'}</S.VersionNumber>
    </PageContainer>
  );
};

export default Login;
