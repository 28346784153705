import { EmployeeModel } from '../../classes/api/types/employee';
import * as modifiers from './modifiers';

export interface EmployeesState {
  employees: EmployeeModel[]
}

const initialEmployeesState: EmployeesState = {
  employees: []
}

export enum EmployeeActionType {
  SET_EMPLOYEES = "SET_EMPLOYEES",
  ADD_EMPLOYEE = "ADD_EMPLOYEE",
  DELETE_EMPLOYEE = "DELETE_EMPLOYEE",
  UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE",
  CLEAR_EMPLOYEES = "CLEAR_EMPLOYEES"
}

interface EmployeeAction {
  type: EmployeeActionType;
  payload?: any;
}

export const EmployeeReducer = (state: EmployeesState = initialEmployeesState, action: EmployeeAction) => {
  switch (action.type) {
    case EmployeeActionType.SET_EMPLOYEES:
      return {
        ...state,
        employees: [ ...action.payload ]
      }
    case EmployeeActionType.ADD_EMPLOYEE:
      const employee = {
        ...action.payload,
        fullName: action.payload.firstName + ' ' + action.payload.lastName
      }
      return {
        ...state,
        employees: [ ...state.employees, employee ]
      }
    case EmployeeActionType.DELETE_EMPLOYEE:
      const updated = modifiers.deleteEmployee(state.employees, action.payload);
      return {
        ...state,
        employees: [ ...updated ]
      }
    case EmployeeActionType.UPDATE_EMPLOYEE:
      const updatedEmployees = modifiers.updateEmployee(state.employees, action.payload);
      return {
        ...state,
        employees: [...updatedEmployees]
      }
    case EmployeeActionType.CLEAR_EMPLOYEES:
      return {
        ...state,
        employees: []
      }
    default:
      return state;
  }
}