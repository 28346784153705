import { Dispatch } from "redux";
import { MessagingActionType } from ".";
import { AnnouncementModel } from "../../classes/api/types/announcement";
import { apiInstance } from "../../utils";

export const getAnnouncements = () => {
  return (dispatch: Dispatch) => {
    return apiInstance.announcements.getAll(null).then(res => {
      dispatch({
        type: MessagingActionType.SET_ANNOUNCEMENTS,
        payload: res.data
      })
    });
  }
}

export const addAnnouncement = ({ announcement }: { announcement: AnnouncementModel }) => {
  return (dispatch: Dispatch) => {
    return apiInstance.announcements.create(announcement).then(res => {
      dispatch({
        type: MessagingActionType.ADD_ANNOUNCEMENT,
        payload: res.data
      })
    });
  }
}

export const deleteAnnouncement = ({ announcement }: { announcement: AnnouncementModel }) => {
  return (dispatch: Dispatch) => {
    return apiInstance.announcements.delete(announcement).then(res => {
      dispatch({
        type: MessagingActionType.REMOVE_ANNOUNCEMENT,
        payload: announcement
      })
    });
  }
}