import { ShiftModel } from "../../classes/api/types/shift";
import * as modifiers from './modifiers';

export interface ShiftsState {
  shifts: ShiftModel[];
  selectedShift: ShiftModel | undefined;
  latestChange: ShiftModel | null
}

const initialShiftStoreState: ShiftsState = {
  shifts: [],
  selectedShift: undefined,
  latestChange: null
}

export enum ShiftStoreAction {
  CREATE_SHIFT = "CREATE_SHIFT",
  UPDATE_SHIFT = "UPDATE_SHIFT",
  BULK_UPDATE_SHIFTS = "BULK_UPDATE_SHIFTS",
  DELETE_SHIFT = "DELETE_SHIFT",
  SET_SELECTED_SHIFT = "SET_SELECTED_SHIFT",
  CLEAR_SHIFTS = "CLEAR_SHIFTS",
  SET_SHIFTS = "SET_SHIFTS"
}

interface ShiftAction {
  type: ShiftStoreAction;
  payload?: any;
}

export const ShiftReducer = (state: ShiftsState = initialShiftStoreState, action: ShiftAction) => {
    switch (action.type) {
      case ShiftStoreAction.SET_SHIFTS:
        return {
          ...state,
          shifts: action.payload
        }
      case ShiftStoreAction.CREATE_SHIFT:
        if (Array.isArray(action.payload)) {
          return {
            ...state,
            shifts: [ ...state.shifts, ...action.payload ],
            latestChange: action.payload[action.payload.length - 1]
          }
        }
        return {
          ...state,
          latestChange: action.payload,
          shifts: [ ...state.shifts, action.payload ]
        }

      case ShiftStoreAction.UPDATE_SHIFT:
        const shifts = modifiers.updateShift(state.shifts, action.payload);
        return {
          ...state,
          shifts
        }
      case ShiftStoreAction.BULK_UPDATE_SHIFTS:
        let newShifts_0 = [ ...state.shifts ];
        action.payload.forEach((s: ShiftModel) => {
          newShifts_0 = modifiers.updateShift(newShifts_0, s);
        });
        return {
          ...state,
          shifts: [ ...newShifts_0 ]
        }
      case ShiftStoreAction.DELETE_SHIFT:
        const newShifts = modifiers.deleteShift(state.shifts, action.payload);
        return {
          ...state,
          shifts: [...newShifts]
        }
      case ShiftStoreAction.CLEAR_SHIFTS:
        return {
          ...state,
          shifts: []
        }
      case ShiftStoreAction.SET_SELECTED_SHIFT:
        return {
          ...state,
          selectedShift: action.payload
        }
      default:
        return state;
    }
}