import styled, { css } from "styled-components";
import PhoneInput from 'react-phone-number-input'

const inputStyles = css`
  font-size: 1rem;
  outline: none;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;

  &:disabled {
    background: #e9e9e9;
  }
`

export const PhoneNumberInput = styled(PhoneInput)`
  ${inputStyles}

  border: 1px solid ${props => props.theme.lightestGrey};
  background-color: white;
  max-width: 300px;

  input {
    border: none;
    outline: none;
    color: ${(props) => props.theme.lightGrey};
    font-size: 14px;
  }
`;

export const Input = styled.input<{ hasError?: boolean }>`
  ${inputStyles}
  color: ${(props) => props.theme.lightGrey};
  border: 1px solid ${props => props.theme.lightestGrey};

  &[type="color"] {
    padding: 0;
    width: 50px;
    height: 50px;
  }
`;

export const InputContainerWithIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 4px;
  height: 40px;
  box-sizing: border-box;

  input {
    border: 1px solid ${props => props.theme.lightestGrey};
    border-radius: 0 4px 4px 0;
    margin-left: 0;
    margin-top: 0;
  }
`;

export const InputIcon = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.lightestGrey};
  height: 40px;
  border-radius: 4px 0 0 4px;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const SelectContainer = styled.select`
  background: white;
  border: 1px solid ${(props) => props.theme.lightestGrey};
  border-radius: 5px;
  color: ${(props) => props.theme.lightGrey};
  font-size: 1rem;
  padding: 10px;
  padding-left: 5px;
  width: 100%;

  option {
    border: 1px solid ${(props) => props.theme.lightestGrey};
    display: flex;
    min-height: 20px;
    padding: 0.5rem;
    white-space: pre;
  }
`;


export const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const RadioLabel = styled.label<{ size?: "small" | "large" }>`
  diplay: flex;
  align-items: center;
  justify-content: center;
  line-height: ${props => props.size == 'small' ? '50px' : '100px'};
  cursor: pointer;
  min-width: ${props => props.size == 'small' ? '100px' : '150px'}
  height: ${props => props.size == 'small' ? '50px' : '100px'}
  border-radius: 4px;
  border: 1px solid ${props => props.theme.lightestGrey};
  margin-right: 20px;
  margin-bottom: 20px;
  background: white;
  padding: 0 15px;
  border-radius: 4px;

  &:has(> input:checked) {
    border-color: ${props => props.theme.primary};
  }

  &:hover {
    border-color: ${props => props.theme.primary};
    box-shadow: ${props => props.theme.boxShadow.flat};
  }

  input:checked + svg {
    color: ${props => props.theme.primary};
  }

  input:checked + svg + span {
    font-weight: bold;
    color: ${props => props.theme.primary};
  }

  svg {
    color: ${props => props.theme.lightGrey};
    font-size: 26px;
    margin: 0 15px;
  }
`;