import { AnnouncementModel } from '../../classes/api/types/announcement';
import * as helpers from '../helpers';

export interface MessagingState {
  announcements: AnnouncementModel[]
}

const initialLeaveRequestState: MessagingState = {
  announcements: []
}

export enum MessagingActionType {
  SET_ANNOUNCEMENTS = "SET_ANNOUNCEMENTS",
  ADD_ANNOUNCEMENT = "ADD_ANNOUNCEMENT",
  REMOVE_ANNOUNCEMENT = "REMOVE_ANNOUNCEMENT"
}

interface MessagingAction {
  type: MessagingActionType;
  payload?: any;
}

export const MessagingReducer = (state: MessagingState = initialLeaveRequestState, action: MessagingAction) => {
  switch (action.type) {
    case MessagingActionType.SET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: [ ...action.payload ]
      }
    case MessagingActionType.ADD_ANNOUNCEMENT:
      return {
        ...state,
        announcements: [ action.payload, ...state.announcements ]
      }
    case MessagingActionType.REMOVE_ANNOUNCEMENT:
      console.log(action.payload);
      const announcements = helpers.removeById(state.announcements, action.payload.id);
      return {
        ...state,
        announcements: [ ...announcements ]
      }
    default:
      return state;
  }
}