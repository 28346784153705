import styled from 'styled-components';


export const Shift = styled.div<{
  color: string;
  endsNextDay?: boolean;
  isSelected?: boolean;
}>`
  background-color: ${(props) => props.color};
  border: 2px solid ${(props) => props.isSelected ? `#ff6249` : 'transparent'};
  border-radius: 5px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  left: ${(props) => (props.endsNextDay ? `50%` : `0%`)};
  opacity: ${(props) => props.isSelected && 0.8};
  padding: 0.5rem 0.2rem;
  position: relative;
  text-align: center;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
  min-width: 140px;
  margin: 5px auto;
  z-index: 1;
  color: black;
  font-size: 12px;

  button {
    display: none;
  }

  &:hover button {
    display: block;
  }
`;

export const AddAnotherShiftButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.primary};
  border: none;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translate(50%, 50%);
  text-align: center;
  cursor: pointer;
  padding: 0;

  &:hover {
    opacity: 1;
  }
`;

export const ShiftGrabIcon = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  padding-left: 5px;
  transform: translate(0, -50%);
  cursor: grab;
  opacity: 0.15;

  &:hover {
    opacity: 0.6;
  }
`;

export const HasBeenWorkedIcon = styled.div<{ color: string}>`
  position: absolute;
  right: 2px;
  top: 2px;
  color: ${props => props.color};
  background-color: white;
  border-radius: 100%;
  font-size: 8px;
  width: 12px;
  height: 12px;
  text-align: center;
  line-height: 12px;
`;

export const ShiftLabel = styled.div`
  display: flex;
  flex-direction: column;
`;