import { ILoginFormInputs } from "../../containers/Login";
import { storageInstance } from "../../utils";
import { ApiInterface } from "./types";
import { UserApi, UserModel } from "./types/user";


export class User implements UserApi {
  apiInstance: ApiInterface;

  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  getCurrentUser() {
    return this.apiInstance.sendRequest("/user/me/", "GET");
  }

  async login(loginData: ILoginFormInputs) {
    const tokenResponse = await this.apiInstance.sendRequest(
      "/auth/token/",
      "POST",
      loginData
    );
    this.apiInstance.token = tokenResponse.data.access;
    // REFACTOR HOW LOGIC WORKS FOR PASSWORD RESET LINKS
    const userResponse = await this.apiInstance.sendRequest(
      '/user/me/',
      'GET'
    )
    // IP restriction
    if (userResponse.status === 403) {
      tokenResponse.status = 403;
      tokenResponse.error = userResponse.data.message
    }
    else {
      storageInstance.storeToken(tokenResponse.data.access);
    }
    
    tokenResponse.data = userResponse.data;
    return tokenResponse
  }

  finishAccountSetup(data: { username: string, password: string, token: string}) {
    return this.apiInstance.sendRequest('/user/employee-user/account-setup/', 'POST', data, {}, {}, true);
  }

  updatePassword(password: { password: string, token: string }) {
    return this.apiInstance.sendRequest("/user/employee-user/reset-password/", "POST", password, {}, {}, true);
  }

  update(profile: UserModel) {
    return this.apiInstance.sendRequest(
      `user/self/`,
      "PATCH",
      profile
    );
  }

  updateEmployeeUser(profile: UserModel) {
    return this.apiInstance.sendRequest(
      `user/employee-user/${profile.id}/`,
      'PATCH',
      profile
    )
  }

  sendPasswordResetRequest(email: string) {
    return this.apiInstance.sendRequest(
      `/user/employee-user/forgot-password/`,
      "POST",
      { email },
      {},
      {},
      true
    );
  }

  resetPassword(
    data: { password: string, token: string }) {
    return this.apiInstance.sendRequest(
      `/employees/reset-password/`,
      "POST",
      data,
      {},
      {},
      true
    );
  }

  getNotifications() {
    return this.apiInstance.sendRequest(
      '/user/notifications/',
      'GET'
    );
  }

  clearNotifications() {
    return this.apiInstance.sendRequest(
      '/user/notifications/delete/',
      'POST'
    );
  }

  markNotificationsAsRead() {
    return this.apiInstance.sendRequest(
      '/user/notifications/clear/',
      'POST'
    );
  }

  getOne(input: string) {
    return Promise.reject('Not implemented')
  }

  getAll() {
    return Promise.reject('Not implemented')
  }

  delete(input: string) {
    return Promise.reject('Not implemented')
  }

  create(input: UserModel) {
    return Promise.reject('Not implemented')
  }
}
