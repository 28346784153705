import styled from "styled-components";

export const Container = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
  width: 50%;
  font-size: 1.5rem;
`;

export const Label = styled.span`
  color: #4699ff;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoContainer = styled.div`
  margin-bottom: 2rem;
  width: 20%;
`;

export const VersionNumber = styled.span`
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 11px;
`