import { useEffect, useState } from "react"
import { useContext } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { EmployeeModel } from "../../../../../../classes/api/types/employee"
import { ShiftModel } from "../../../../../../classes/api/types/shift"
import { Header3 } from "../../../../../../containers/common.styles"
import { ScheduleActions, ScheduleTableContext } from "../../../../../../containers/Manager/SchedulePage/state"
import { useActionLoader } from "../../../../../../hooks/useActionLoader"
import { RootState } from "../../../../../../reducers"
import { bulkUpdateShifts, createShift } from "../../../../../../reducers/shifts/actions"
import SimpleDate from "../../../../../../utils/SimpleDate"
import { applyTimeToDate, getDateForBackend } from "../../../../../../utils/timeUtils"
import Modal from "../../../../../Modal/Modal"
import { ShiftForm } from "../../ShiftForm"

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

interface Props {
  hide: {(): void}
}

export const CreateManyShiftsModalForm = ({ hide }: Props) => {

  const { scheduleState, dispatch } = useContext(ScheduleTableContext);
  const { employees } = useSelector((state: RootState) => state.employees);
  const { callAction: bulkCreate, done, isLoading } = useActionLoader(createShift,
    'Shifts created',
    `Couldn't update shifts`
  )
  const [firstEmployee, setFirstEmployee] = useState<EmployeeModel | undefined>()

  useEffect(() => {
    if (scheduleState.multiSelectedShifts.length) {
      setFirstEmployee(employees.find((e) => e.id === scheduleState.multiSelectedShifts[0].employee));
    }
  }, [employees, scheduleState.multiSelectedShifts])
 
  const onSave = (updates: any) => {
    const shifts = scheduleState.multiSelectedShifts.map((shift: any) => {
      const newStartTime = SimpleDate.from(updates.plannedStartTime).get24TimeLabel(false);
      const newEndTime = SimpleDate.from(updates.plannedEndTime).get24TimeLabel(false);
      const newStartDate = applyTimeToDate(new Date(shift.plannedStartTime), newStartTime);
      const newEndDate = applyTimeToDate(new Date(shift.plannedEndTime), newEndTime);
      
      if (newStartDate > newEndDate) {
        newEndDate.setDate(newEndDate.getDate() + 1);
      }

      return {
        ...shift,
        plannedStartTime: getDateForBackend(newStartDate),
        plannedEndTime: getDateForBackend(newEndDate),
        tag: updates.tag,
        color: updates.color
      }
    });
    bulkCreate(shifts);
  }

  useEffect(() => {
    if (done && !isLoading) {
      dispatch({
        type: ScheduleActions.TRACK_CHANGE,
        payload: scheduleState.multiSelectedShifts.map((shift: ShiftModel) => shift.employee)
      })
      hide();
    }
  }, [done, isLoading, hide, dispatch])
  
  console.log(scheduleState.multiSelectedShifts)
  return <Modal onClickOut={hide}>
    <Container>
      <Header3 style={{ marginBottom: '30px' }}>Create {scheduleState.multiSelectedShifts.length} shifts</Header3>
      <ShiftForm
        hide={() => {}}
        onSave={onSave}
        fixedPosition
        size="medium"
        employee={firstEmployee}
      />
    </Container>
  </Modal>
}