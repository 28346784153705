import { ApiInterface } from "./types";
import { EventApi, EventModel } from "./types/event";

export class Event implements EventApi {
  apiInstance: ApiInterface;

  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  getAll({ startDate, endDate}: { startDate: string; endDate: string }) {
    return this.apiInstance.sendRequest(
      "/shift/events/",
      "GET",
      {},
      { 
        start_gte: startDate, 
        end_lte: endDate
      }
    );
  }

  getActiveEvents() {
    return this.apiInstance.sendRequest(
      "/shift/events/",
      "GET",
      {},
      { active: 'true' }
    );
  }

  getOne(input: string) {
    return Promise.reject('Not implemented')
  }

  delete(input: string) {
    return Promise.reject('Not implemented')
  }

  update(input: EventModel) {
    return Promise.reject('Not implemented')
  }

  create(input: EventModel) {
    return Promise.reject('Not implemented')
  }
}
