import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import ColorPicker, { colorPickerPalette } from "../ColorPicker";
import * as S from "./index.styles";

export interface InputProps {
  name: string;
  type?: string;
  inputRef?: any;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  defaultValue?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  step?: string | number;
  selectOptions?: { label: string; value: string }[];
  radioOptions?: { label: string; value: string, Icon: any }[];
  multiValues?: { label: string; value: string }[];
  multiSelectOptions?: { label: string; value: string }[];
  control?: any;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    defaultValue,
    disabled,
    hasError,
    inputRef,
    name,
    onChange,
    selectOptions = [],
    radioOptions = [],
    step,
    type,
    value,
    multiValues = [],
    multiSelectOptions = [],
    control
  } = props;
  return (
    <S.InputContainer>
      {type === "select" ? (
        <S.SelectContainer
          defaultValue={defaultValue}
          value={value}
          name={name}
          id={name}
          ref={inputRef}
          disabled={disabled}
        >
          {selectOptions.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </S.SelectContainer>
      ) 
      : type === 'multiselect'
      ? <Select
          name={name}
          id={name}
          // @ts-ignore
          value={multiValues}
          options={multiSelectOptions}
          // @ts-ignore
          onChange={onChange}
          isMulti
      />
      : type === 'radio'
      ? <S.RadioGroup>
        {radioOptions.map(({ label, value, Icon }) => (
          <>
          <S.RadioLabel htmlFor={label}>
            <input
              id={label}
              name={name}
              ref={inputRef}
              disabled={disabled}
              key={value}
              value={value}
              onChange={onChange}
              type={type}
            />
            {Icon && <Icon />}
            <span>{label}</span></S.RadioLabel>
          </>
        ))}
      </S.RadioGroup>
      : type === 'color'
      ? <ColorPicker
          color={value || colorPickerPalette[0]}
          onChange={c => onChange ? onChange({ target: { value: c, name } } as any) : null}
        />
      : type === 'phone'
      ? <Controller 
          control={control}
           name={name}
           defaultValue={value?.toString()}
           render={field => (
              <S.PhoneNumberInput
                {...field}
                defaultCountry="CA"
                international={false}
              />
           )}
        />
      : (
        <>
          <S.Input
            defaultValue={defaultValue}
            value={value}
            type={type}
            name={name}
            id={name}
            ref={inputRef}
            onChange={onChange}
            disabled={disabled}
            step={step}
            hasError={hasError}
            autoCapitalize="off"
          />
        </>
      )}
    </S.InputContainer>
  );
};

export default Input;
