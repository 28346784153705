import { differenceInSeconds, isSameDay, startOfDay } from "date-fns";
import { EmployeeModel } from "../../../classes/api/types/employee";
import { EventModel, EventType, GenericEvent } from "../../../classes/api/types/event";

import { ShiftModel } from "../../../classes/api/types/shift";
import SimpleDate from "../../../utils/SimpleDate";
import { createDateFromString, getDateForBackend } from "../../../utils/timeUtils";

export type NumericObject = { [key: string]: number };
export type NumericResultCallback = (result: NumericObject) => void;

const dateDifference = (start: Date, end: Date) => {
  return differenceInSeconds(start, end) / 60 / 60;
}

export const getEmployeeTotals = (events: EventModel[] ): Promise<NumericObject> => {
  return new Promise((resolve, reject) => {
    const employeeTotals: NumericObject = {};
    if (!events) {
      return;
    }
    
    events.filter((event: EventModel) => event.resourcetype === EventType.SHIFT).forEach((shift) => {
      const { employee } = shift;
      let duration = shift.plannedDuration;
  
      employeeTotals[employee] =
        (employeeTotals[employee] || 0) + (duration || 0);
    });
    resolve(employeeTotals);
  })
};

export const getShiftDurationBreakdown = (shift: ShiftModel) => {
  const { plannedStartTime, plannedEndTime } = shift;
  if (!plannedStartTime || !plannedEndTime) {
    return [];
  }
  const startDate = SimpleDate.from(plannedStartTime).getDate();
  const endDate = SimpleDate.from(plannedEndTime).getDate();

  if (isSameDay(startDate, endDate)) {
    const duration = dateDifference(endDate, startDate);
    return [{ key: startDate.toDateString(), value: duration }];
  } else {
    const startDateDuration = dateDifference(startOfDay(endDate), startDate);
    const endDateDuration = dateDifference(endDate, startOfDay(endDate));

    return [
      { key: startDate.toDateString(), value: startDateDuration },
      { key: endDate.toDateString(), value: endDateDuration }
    ];
  }
};

export const getDayTotals = (events: EventModel[], teamFilter: EmployeeModel[]): Promise<NumericObject> => {
  return new Promise((resolve, reject) => {
    if (!events) {
      return;
    }
    const employeeIds = teamFilter.map((employee) => employee.id);
    const filteredEvents = events.filter((event: EventModel) => employeeIds.includes(event.employee));
    const dayTotals: NumericObject = {};
    filteredEvents.filter((event: EventModel) => event.resourcetype === EventType.SHIFT).forEach((event) => {
      const shift = event as ShiftModel;
      const results = getShiftDurationBreakdown(shift);
      results.forEach((result) => {
        const { key, value } = result;
        dayTotals[key] = (dayTotals[key] || 0) + (value || 0);
      });
    });
    resolve(dayTotals);
  })
};

export type ScheduleData = {
  employee: EmployeeModel,
  schedule: GenericEvent[][],
  days: Date[]
}

export const getScheduledShifts = (days: Date[], events: EventModel[], employees: any[]): ScheduleData[] => {
  const schedule: any = [];
  employees.forEach((employee) => {
    const employeeSchedule: ScheduleData = {
      employee,
      schedule: [],
      days: []
    };
    days.forEach((day) => {
      const foundEvents = events.filter((event: EventModel) => {
        if (event.resourcetype === EventType.SHIFT && event.plannedStartTime && event.plannedEndTime) {
          // @ts-ignore
          return event.employee === employee.id && isSameDay(day, SimpleDate.from(event.plannedStartTime).getDate())
        }
        // @ts-ignore
        return event.employee === employee.id && event.date && isSameDay(day, SimpleDate.from(event.date).getDate())
      });
      employeeSchedule.schedule.push(foundEvents);
      employeeSchedule.days.push(day);
    });
    schedule.push(employeeSchedule);
  });
  return schedule;
}

export const getDefaultShift = (employee: EmployeeModel | undefined, day: Date) => {
    const start = new Date(day.getFullYear(), day?.getMonth(), day?.getDate());
    const end = new Date(day.getFullYear(), day?.getMonth(), day?.getDate());
    start.setHours(9)
    start.setMinutes(0)
    end.setHours(17)
    end.setMinutes(0)
    return {
      id: `temp-${start.getDate()}-${employee?.id}`,
      employee: employee?.id || '',
      plannedStartTime: getDateForBackend(start),
      plannedEndTime: getDateForBackend(end)
    }
};