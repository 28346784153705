import { useState } from "react";
import { useContext } from "react";
import { ShiftModel } from "../../../../../../classes/api/types/shift";
import { ScheduleActions, ScheduleTableContext } from "../../../../../../containers/Manager/SchedulePage/state";
import { useActionLoader } from "../../../../../../hooks/useActionLoader";
import { bulkUpdateShifts, deleteShift } from "../../../../../../reducers/shifts/actions";
import { ShiftForm } from "../../ShiftForm"


export const UpdateOneShiftForm = ({ hide }: { hide: {(): void}}) => {
    const { callAction: callUpdateAction } = useActionLoader(bulkUpdateShifts, 
        'Shift updated', `Couldn't update shift`);
      const { callAction: callDeleteAction } = useActionLoader(deleteShift, 
        'Shift deleted', `Coulnd't delete shift`);

  const { dispatch, scheduleState } = useContext(ScheduleTableContext);

  const onSubmit = (data: Partial<ShiftModel>) => {
    dispatch({
      type: ScheduleActions.TRACK_CHANGE,
      payload: [scheduleState.selectedShift.employee]
    })
    callUpdateAction([data]);
    hide();
  };

  const onDelete = (data: Partial<ShiftModel>) => {
    dispatch({
      type: ScheduleActions.INCREMENT_CHANGE_COUNT,
      payload: 1
    })
    callDeleteAction(data)
  }

  return <ShiftForm
    hide={hide}
    onSave={onSubmit}
    onDelete={onDelete}
  />

}