export interface SessionState {
  selectedDates?: {
    startDate: string;
    endDate: string;
  }
}

const initialSessionState: SessionState = {
 
}

export enum SessionActionType {
  SET_SELECTED_DATES = "SET_SELECTED_DATES"
}

interface SessionAction {
  type: SessionActionType;
  payload?: any;
}

export const SessionReducer = (state: SessionState = initialSessionState, action: SessionAction) => {
  switch (action.type) {
    case SessionActionType.SET_SELECTED_DATES:
      return {
        ...state,
        selectedDates: action.payload
      }
    default:
      return state;
  }
}