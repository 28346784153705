import { ofType } from "redux-observable";
import { ShiftStoreAction } from "../shifts";
import { withLatestFrom, map } from "rxjs";
import { LocationActionType } from "../locations";
import { ShiftModel } from "../../classes/api/types/shift";

const getTagsFromShiftPayload = (payload: ShiftModel[] | ShiftModel) => {
  if (Array.isArray(payload)) {
    return payload.map((shift: ShiftModel) => shift.tag);
  }
  return [ payload.tag || '' ];
};

const getTagsNotIn = (tags: any[], newTags: any) => {
  const lowerCaseTags = tags.map((tag: string) => tag.toLowerCase());
  return newTags.filter((tag: string) => !lowerCaseTags.includes(tag.toLowerCase()));
};

export const shiftUpdateEffect = (action$: any, state$: any) => action$.pipe(
  ofType(ShiftStoreAction.BULK_UPDATE_SHIFTS, ShiftStoreAction.CREATE_SHIFT, ShiftStoreAction.UPDATE_SHIFT),
  withLatestFrom(state$),
  map(([action, state]: any) => {
    const { location } = state.location;
    const tags = getTagsNotIn(location.shiftTags, getTagsFromShiftPayload(action.payload));
    if (tags.length) {
      const allTags = [ ...location.shiftTags, ...tags ];
      const uniqueTags = allTags.filter((tag: string, index: number) => allTags.indexOf(tag) === index || tag === '');
      location.shiftTags = uniqueTags;
    }
    return {
      type: LocationActionType.UPDATE_LOCATION,
      payload: location
    }
  })
);

