import { useRef, useState } from "react";
import * as S from "./index.styles";
import { ReactComponent as ChevronDown } from '../../assets/icons/chevronDown.svg';
import useOnClickOutside from "../../hooks/useClickOutside";

interface Option {
  onSelect: any;
  text?: string;
  subtext?: string;
  component?: any;
  icon?: any;
  type?: string;
  disabled?: boolean;
}

interface Props {
  options: Option[];
}

const ActionsDropdown = ({ options }: Props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpen(false));

  return (
    <S.DropdownContainer ref={ref}>
      <S.Select onClick={() => setOpen(!open)} open={open}>
        <S.Placeholder>Actions <ChevronDown /></S.Placeholder>
        {open && 
          <S.Options>
            {options.map((option: Option, index: number) => (
              <S.Option 
                key={`${option.text}-${index}`}
                onClick={option.onSelect} 
                type={option.type}
                disabled={option.disabled}
              >
                {option.icon && <option.icon style={{ color: 'black' }} />}
                <S.TextContainer>
                  {option.text || <option.component />}
                  {option.subtext && <S.SubText>{option.subtext}</S.SubText>}
                </S.TextContainer>
              </S.Option>
            ))}
          </S.Options>
        }
      </S.Select>
    </S.DropdownContainer>
  )
};

export default ActionsDropdown;