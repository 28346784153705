import styled, { css } from "styled-components";

export const PageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  min-height: 100vh;
  width: 100%;
`;

export const Content = styled.div`
  padding: 2rem;
  margin: 4rem 0;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const ContentContainer = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${props => props.theme.background};
`;

export const PageHeader = styled.span`
  color: #0a2540;
  font-family: CeraRoundBasic-Medium;
  font-size: 1.5rem;

  svg {
    margin-right: 20px;
  }
`;

export const PageSubHeader = styled.span<{ color?: string }>`
  color: ${(props) => props.color || "#9d9d9d"};
  font-family: CeraRoundBasic-Regular;
  font-size: 2rem;
  text-align: start;
`;

export const HeaderContainer = styled.div<{ center?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.center ? `center` : `space-between`)};
  margin-bottom: 2rem;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
`;

export const ActionContainer = styled.div<{ center?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.center ? `center` : `flex-end`)};
  margin-top: 1rem;
  width: 100%;
`;

export const FilterContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 12rem;
`;

export const OnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const OnboardingHeader = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  padding: 0 2rem;
`;

export const OnboardingHeaderTitle = styled.h1`
  color: ${(props) => props.theme.primary};
  font-family: CeraRoundBasic-Regular;
`;

export const OnboardingSubHeader = styled.div`
  color: "#A1A1A1";
  font-family: CeraRoundBasic-Regular;
  font-size: 1rem;
`;

export const Header1 = styled.h1<{ color?: string }>`
  font-family: CeraRoundBasic-Medium;
  svg {
    margin-right: 15px;
  }
`;

export const Header2 = styled.h2<{ color?: string }>`
  color: ${(props) => props.color || props.theme.primary};
  font-family: CeraRoundBasic-Medium;
  font-size: 1.5rem;
  svg {
    margin-right: 15px;
  }
`;

export const Header3 = styled.h3<{ color?: string }>`
  color: ${(props) => props.color || props.theme.primary};
  font-family: CeraRoundBasic-Medium;
  margin: 0;
  svg {
    margin-right: 15px;
  }
`;

export const Header4 = styled.span<{ color?: string }>`
  color: ${(props) => props.color || props.theme.primary};
  font-family: CeraRoundBasic-Medium;
  font-size: 1rem;
  svg {
    margin-right: 15px;
  }
`;

export const Header5 = styled.span<{ color?: string }>`
  color: ${(props) => props.color || props.theme.primary};
  font-family: CeraRoundBasic-Medium;
  font-size: 0.83rem;
`;

export const Subtitle1 = styled.span<{ color?: string, bold?: boolean }>`
  color: ${(props) => props.color || props.theme.primary};
  font-family: CeraRoundBasic-Regular;
  font-size: 1rem;
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
`;

export const Text = styled.p<{ color?: string, bold?: boolean }>`
  color: ${(props) => props.color || props.theme.standardText};
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  margin: 5px 0 0 0;
  line-height: 1.5;
`;

export const scrollbarStyles = css`
  /* width */
  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => `${props.theme.primary}80`};
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.primary};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.primary};
  }
`;


export const Card = styled.div<{ width?: string, height?: string }>`
  width: ${props => props.width || 'auto'};
  border-radius: 5px;
  box-shadow: ${props => props.theme.boxShadow.flat};
  border: 1px solid ${props => props.theme.cardBorder};
  padding: 20px;
  height: ${props => props.height || 'auto'};
  position: relative;
  background: white;
  box-sizing: border-box;
`;