import { format } from "date-fns";
import { Dispatch } from "redux";
import { EventActionType } from ".";
import { EventType } from "../../classes/api/types/event";
import { apiInstance } from "../../utils";
import { ShiftStoreAction } from "../shifts";
import { LeaveDayActionType } from "../leaveDays";


export const getAllEvents = ({ startDate, endDate }: { startDate: Date, endDate: Date }) => {
  return (dispatch: Dispatch) => {
    const start = format(startDate, 'yyyy-MM-dd');
    const end = format(endDate, 'yyyy-MM-dd');
    return apiInstance.events.getAll({ startDate: start, endDate: end }).then(res => {
      dispatch({
        type: EventActionType.SET_EVENTS,
        payload: res.data
      });
      dispatch({
        type: ShiftStoreAction.SET_SHIFTS,
        payload: res.data.filter(event => event.resourcetype === EventType.SHIFT)
      });
      dispatch({
        type: LeaveDayActionType.SET_LEAVE_DAYS,
        payload: res.data.filter(event => event.resourcetype === EventType.LEAVE_DAY)
      });
    })
  }
}

export const getActiveEvents = () => {
  return (dispatch: Dispatch) => {
    return apiInstance.events.getActiveEvents().then(res => {
      dispatch({
        type: EventActionType.SET_ACTIVE_EVENTS,
        payload: res.data
      });
    })
  }
}