import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 50%;

  h4 {
    margin-bottom: 60px;
  }

  button {
    margin-top: 30px;
  }
`;