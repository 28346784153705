import * as modifiers from './modifiers';

export interface LeaveRequestsState {
  requests: []
}

const initialLeaveRequestState: LeaveRequestsState = {
  requests: []
}

export enum LeaveRequestActionType {
  SET_LEAVE_REQUESTS = "SET_LEAVE_REQUESTS",
  UPDATE_LEAVE_REQUEST = "UPDATE_LEAVE_REQUEST",
  CLEAR_LEAVE_REQUESTS = "CLEAR_LEAVE_REQUESTS"
}

interface LeaveRequestAction {
  type: LeaveRequestActionType;
  payload?: any;
}

export const LeaveRequestReducer = (state: LeaveRequestsState = initialLeaveRequestState, action: LeaveRequestAction) => {
  switch (action.type) {
    case LeaveRequestActionType.SET_LEAVE_REQUESTS:
      return {
        ...state,
        requests: [ ...action.payload ]
      }
    case LeaveRequestActionType.UPDATE_LEAVE_REQUEST:
      const requests = modifiers.updateLeaveRequest(state.requests, action.payload);
      return {
        ...state,
        requests
      }
    default:
      return state;
  }
}