import { CrudApi, IResponse } from ".";

export enum EventType {
  SHIFT = "Shift",
  LEAVE_DAY = "LeaveDay"
}

export enum LeaveType {
  VACATION = "vacation",
  SICK = "sick",
  BEREAVEMENT = "bereavement",
  HOLIDAY = "holiday",
  MEDICAL_APPOINTMENT = "medical appointment",
}

export interface GenericEvent {
  id?: string;
  employee: string;
  timesheet: string;
  resourcetype?: EventType;
  type?: LeaveType;
  tag?: string;
  [key: string]: any;
}

export interface EventApi extends CrudApi<EventModel> {
  getActiveEvents: () => Promise<IResponse<GenericEvent[]>>;
}


export type EventModel = GenericEvent;