import styled from 'styled-components';

export const ContentInput = styled.textarea`
  width: 100%;
  height: 75%;
  border: none;
  font-size: 16px;
  padding: 30px;
  color: ${props => props.theme.lightGrey};
  box-sizing: border-box;
  font-family: ${(props) => props.theme.standardFont};
  outline: none;
  line-height: 1.5;
  margin-top: 20px;

  &::placeholder {
    font-weight: bold;
    font-size: 24px;
    color: ${props => props.theme.lightestGrey};
  }
`;

export const ActionContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25%;
  border-top: 1px solid ${props => props.theme.lightestGrey};
`

export const NotificationOptions = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    width: 200px;
    margin-bottom: 0;
  }

  & p:last-of-type {
    font-size: 12px;
    width: 200px;
  }
`;

export const NotificationOptionLabel = styled.label<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  padding-top: 20px;
  width: 200px;

  input:checked + svg {
    color: ${props => props.theme.primary};
  }

  input:checked + svg + span {
    color: ${props => props.theme.primary};
  }

  svg {
    margin: 0 10px;
  }

  ${props => !props.disabled && `
    &:hover {
      color: ${props.theme.primary};
      cursor: pointer;
    }
  `}

`;

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  button {
    margin-right: 0;
  }
`;

export const SummaryText = styled.p`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: ${props => props.theme.darkWarning};
  margin: 0;
  width: 100%;
  text-align: center;
`;
