import styled from "styled-components"
import { Text } from "../../../../common.styles"

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`

export const ActionsPane = styled.div`
  background-color: white;
  border: 1px solid ${props => props.theme.lightestGrey};
  padding: 12px 15px 12px 0;
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  font-family: ${props => props.theme.headingFontFamily};
  
  label {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    color: ${props => props.theme.lightGrey} !important;
  }

  div {
    margin-right: 15px;
  }
`