import styled from "styled-components";

export interface IFormBaseProps<T> {
  onSubmit: (data: T) => void;
  style?: { [key: string]: string };
  resetOnSubmit?: boolean;
  disableForm?: boolean;
}

// TODO: ADD RESPONSIVE LAYOUT
export const FormContainer = styled.form`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
`;

export const CenteredFormContainer = styled.form`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  justify-content: center;
  justify-items: center;
`;
