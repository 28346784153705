import React, { useEffect } from "react";

import Loader from "../../../components/Loader";
import { useActionLoader } from "../../../hooks/useActionLoader";
import { ReactComponent as Logo } from "../../../assets/icons/LogoBlue.svg";
import { ReactComponent as SuccessImage } from "../../../assets/icons/setupSuccess.svg";
import {
  OnboardingContainer,
  OnboardingHeader,
  OnboardingHeaderTitle,
  Subtitle1
} from "../../common.styles";
import FormBuilder from "../../../components/Forms/Components/FormBuilder";
import { useHistory, useParams } from "react-router";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { finishAccountSetup } from "../../../reducers/users/actions";
import { useForm } from "react-hook-form";
import { Input, InputContainer, Label } from "../../../components/NewForms/Inputs";
import * as S from './index.styles'
import { useQueryParams } from "../../../hooks/useQueryParams";

const ProfileSetup: React.FC = () => {
  const { token } = useParams<{ token: string }>()
  const { queryParams } = useQueryParams();
  const { user } = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const { register, watch, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    defaultValues: {
      username: queryParams.get('use') || '',
      password: '',
      confirmPassword: ''
    }
  });
  const { callAction: finishSetup, isLoading, done } = useActionLoader(
    finishAccountSetup,
    'Your Huddle account is ready to go',
    `Couldn't complete account setup`
  );

  useEffect(() => {
    if (done) {
      history.push('/')
    }
  }, [done])


  const onSubmit = async (data: { username: string, password: string, confirmPassword: string }) => {
    finishSetup({
      username: data.username,
      password: data.password,
      token
    });
  };

  return (
    <OnboardingContainer>
      {isLoading && <Loader withOverlay={true} fullScreen={true} />}
      {!user 
        ? <>
          <OnboardingHeader>
            <Logo width={200} style={{ marginBottom: '60px' }} />
            <OnboardingHeaderTitle>Welcome to Huddle!</OnboardingHeaderTitle>
            <Subtitle1>
              Select a password so you can start clocking in
            </Subtitle1>
          </OnboardingHeader>
          <S.SetupForm onSubmit={handleSubmit(onSubmit)}>
          <InputContainer error={errors.password?.message}>
              <Label>Username</Label>
              <Input
                type="username"
                name="username"
                required
                inputSize="large"
                ref={register({ required: true })}
              />
            </InputContainer>
            <InputContainer error={errors.password?.message}>
              <Label>Password</Label>
              <Input
                type="password"
                name="password"
                required
                inputSize="large"
                ref={register({ required: true })}
              />
            </InputContainer>
            <InputContainer error={errors.confirmPassword?.message}>
              <Label>Confirm Password</Label>
              <Input
                type="password"
                name="confirmPassword"
                required
                inputSize="large"
                ref={register({ required: true, validate: (value) => {
                  if (value !== watch('password')) return 'Passwords do not match';
                } })}
              />
            </InputContainer>
            <Button type="submit" solid={true}>Finish</Button>
          </S.SetupForm>
        </>
        : <>
          <Logo width={200} style={{ marginBottom: '60px' }} />
          <SuccessImage height={200} />
          <OnboardingHeader>
            <OnboardingHeaderTitle>You're good to go!</OnboardingHeaderTitle>
            <Subtitle1>
              Your account setup is complete. You're ready to start clocking in
            </Subtitle1>
          </OnboardingHeader>
          <Link to={'/'}><Button solid={true}>Finish</Button></Link>
        </>
      }
    </OnboardingContainer>
  );
};

export default ProfileSetup;
