import { useReducer } from "react";
import { createContext } from "react";
import { useSelector } from "react-redux";
import { ShiftModel } from "../../../classes/api/types/shift";

export enum ScheduleModes {
  SINGLE_SELECT,
  MULTI_SELECT
}

interface ScheduleState {
  selectedShift: ShiftModel | {};
  multiSelectedShifts: ShiftModel[];
  clipboardShift: ShiftModel | undefined;
  selectMode: ScheduleModes;
  changesCount: number;
  employeeChanges: string[];
  teamFilters: string[];
}

const initialState: ScheduleState = {
  selectedShift: {},
  multiSelectedShifts: [],
  clipboardShift: undefined,
  selectMode: ScheduleModes.SINGLE_SELECT,
  changesCount: 0,
  employeeChanges: [],
  teamFilters: []
}

export const ScheduleTableContext = createContext<any>(initialState);

export enum ScheduleActions {
  SET_SELECTED_SHIFT = "SET_SELECTED_SHIFT",
  ADD_TO_SELECTED_SHIFTS = "ADD_TO_SELECTED_SHIFTS",
  REMOVE_FROM_SELECTED_SHIFTS = "REMOVE_FROM_SELECTED_SHIFTS",
  SET_SELECT_MODE = "SET_SELECT_MODE",
  CLEAR_SELECTED_SHIFTS = "CLEAR_SELECTED_SHIFTS",
  TRACK_CHANGE = "TRACK_CHANGE",
  INCREMENT_CHANGE_COUNT = "INCREMENT_CHANGE_COUNT",
  RESET_TRACKED_CHANGES = "RESET_TRACKED_CHANGES",
  SET_TEAM_FILTERS = "SET_TEAM_FILTERS"
}


const shiftReducer = (state: ScheduleState, action: { type: ScheduleActions, payload: any}) => {
  switch (action.type) {
    case ScheduleActions.SET_SELECTED_SHIFT:
      return {
        ...state,
        selectedShift: action.payload
      }
    case ScheduleActions.ADD_TO_SELECTED_SHIFTS:
      if (action.payload.id.startsWith('temp')) {
        if (state.multiSelectedShifts.some((s: ShiftModel) => !s.id?.startsWith('temp'))) {
          return {
            ...state,
            multiSelectedShifts: [ action.payload]
          }
        }
      }
      else {
        if (state.multiSelectedShifts.some((s: ShiftModel) => s.id?.startsWith('temp'))) {
          return {
            ...state,
            multiSelectedShifts: [ action.payload]
          }
        }
      }
      return {
        ...state,
        selectedShift: {},
        multiSelectedShifts: [ ...state.multiSelectedShifts, action.payload]
      }
    case ScheduleActions.REMOVE_FROM_SELECTED_SHIFTS:
      return {
        ...state,
        multiSelectedShifts: state.multiSelectedShifts.filter((s: ShiftModel) => s.id !== action.payload.id)
      }
    case ScheduleActions.CLEAR_SELECTED_SHIFTS:
      return {
        ...state,
        multiSelectedShifts: []
      }
    case ScheduleActions.SET_SELECT_MODE:
      return {
        ...state,
        selectMode: action.payload,
        multiSelectedShifts: [],
        selectedShift: {}
      }
    case ScheduleActions.TRACK_CHANGE:
      return {
        ...state,
        employeeChanges: [ ...new Set([...state.employeeChanges, ...action.payload]) ],
        changesCount: state.changesCount + action.payload.length
      }
    case ScheduleActions.INCREMENT_CHANGE_COUNT:
      return {
        ...state,
        changesCount: state.changesCount + action.payload
      }
    case ScheduleActions.RESET_TRACKED_CHANGES:
      return {
        ...state,
        changesCount: 0,
        employeeChanges: []
      }
    case ScheduleActions.SET_TEAM_FILTERS:
      return {
        ...state,
        teamFilters: action.payload
      }
    default:
      return state
  }
}

export const ScheduleContextProvider = ({ children }: any) => {
  const [scheduleState, dispatch] = useReducer(shiftReducer, initialState);

  return (
    <ScheduleTableContext.Provider value={{ scheduleState, dispatch }}>
      { children }
    </ScheduleTableContext.Provider>
  )
}