import { faBars, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isSameDay } from "date-fns";
import React, { useEffect, useState, useContext, MouseEvent } from "react";
import { useMemo } from "react";
import { useDrag } from "react-dnd";
import ReactTooltip from "react-tooltip";
import { EmployeeModel } from "../../../../../classes/api/types/employee";
import { ShiftModel, ShiftStatus } from "../../../../../classes/api/types/shift";
import { ScheduleActions, ScheduleModes, ScheduleTableContext } from "../../../../../containers/Manager/SchedulePage/state";
import SimpleDate from "../../../../../utils/SimpleDate";
import { getShiftTimeLabel } from "../../../../../utils/timeUtils";
import { getDefaultShift } from "../../utils";
import { CreateOneShiftForm } from "../ShiftFormHelpers/CreateOneShiftForm";
import { UpdateOneShiftForm } from "../ShiftFormHelpers/UpdateOneShiftForm";
import * as S from "./index.styles";

export interface ShiftProps {
    shift: ShiftModel;
    employee: EmployeeModel
    showAddMoreButton: boolean;
  }

export const Shift = ({ shift, employee, showAddMoreButton }: ShiftProps) => {
    const { scheduleState, dispatch } = useContext(ScheduleTableContext);
    const [showModal, setShowModal] = useState(false);
    const [showCreateNewModal, setShowCreateNewModal] = useState(false);

    const [collectedProps, dragRef] = useDrag(() => ({
      type: 'shift',
      item: shift
    }));

    const isSelected = useMemo(() => {
      if (scheduleState.selectMode === ScheduleModes.MULTI_SELECT) {
        return scheduleState.multiSelectedShifts.some((s: ShiftModel) => s.id === shift.id);
      }
      return scheduleState.selectedShift.id === shift.id
    }, [shift, scheduleState])
  
    const [sameDay, setSameDay] = useState(false);
    const [dates, setDates] = useState({ 
      // @ts-ignore
      startDate: SimpleDate.from(shift.plannedStartTime).getDate(),
      // @ts-ignore
      endDate: SimpleDate.from(shift.plannedEndTime).getDate()
    })

    useEffect(() => {
      setDates({
        // @ts-ignore
        startDate: SimpleDate.from(shift.plannedStartTime).getDate(),
        // @ts-ignore
        endDate: SimpleDate.from(shift.plannedEndTime).getDate()
      })
    }, [shift])
  
    useEffect(() => {
      setSameDay(isSameDay(dates.startDate, dates.endDate))
    }, [shift, dates.endDate, dates.startDate]);
  
  
    const handleClick = (e: any) => {
      if (scheduleState.selectMode === ScheduleModes.MULTI_SELECT) {
        if (isSelected) {
          dispatch({
            type: ScheduleActions.REMOVE_FROM_SELECTED_SHIFTS,
            payload: shift
          })
        }
        else {
          dispatch({
            type: ScheduleActions.ADD_TO_SELECTED_SHIFTS,
            payload: shift
          })
        }
      }
      else {
        setShowModal(true);
        dispatch({
          type: ScheduleActions.SET_SELECTED_SHIFT,
          payload: shift
        })
      }
    }

    const onAnotherShiftAdd = (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      dispatch({
        type: ScheduleActions.SET_SELECTED_SHIFT,
        payload: getDefaultShift(employee, new Date(shift.plannedStartTime as string))
      });
      setShowCreateNewModal(true)
    }
  
    return (
      <>
        {showModal && 
          <UpdateOneShiftForm 
            hide={() => setShowModal(false)}
          />
        }
        {showCreateNewModal
          && <CreateOneShiftForm 
            hide={() => setShowCreateNewModal(false)} 
            employee={employee}
          />
        }
        <S.Shift
          color={shift.color}
          endsNextDay={!sameDay}
          isSelected={isSelected}
          onClick={handleClick}
          ref={dragRef}
        >
          <S.ShiftGrabIcon>
            <FontAwesomeIcon icon={faBars} />
          </S.ShiftGrabIcon>
          <S.ShiftLabel>
            <span>{shift.tag}</span>
            <span>{getShiftTimeLabel(dates.startDate)} - {getShiftTimeLabel(dates.endDate)}</span>
          </S.ShiftLabel>
          {(showAddMoreButton && scheduleState.selectMode !== ScheduleModes.MULTI_SELECT)
            ? <S.AddAnotherShiftButton onClick={onAnotherShiftAdd} data-for={`${shift.id}-shift`} data-tip>
                +
              </S.AddAnotherShiftButton>
            : null
          }
          {shift.status === ShiftStatus.COMPLETE &&
            <>
              <S.HasBeenWorkedIcon color={shift.color} data-tip data-for={`${shift.id}-worked-status`}>
                <FontAwesomeIcon icon={faCheck} />
              </S.HasBeenWorkedIcon>
              <ReactTooltip id={`${shift.id}-worked-status`} place="top" effect="solid">
                <span>Shift was worked</span>
              </ReactTooltip>
            </>
          }
        </S.Shift>
      </>
    )
  }