import { IResponse } from ".";
import { PaginatedResponse } from "..";

export interface MessageModel {
    id?: string;
    conversation: string;
    sender: {
      id: string;
      fullName: string;
      firstName: string;
      lastName: string;
      avatar: string;
    };
    text: string;
    createdAt: string;
    updatedAt: string;
}

export enum ChatMessageType {
  TEXT = "text",
}


export interface MessageApi {
  create: (input: MessageModel) => Promise<IResponse<MessageModel>>;
  getAll: (input: any) => Promise<PaginatedResponse<MessageModel>>;
}