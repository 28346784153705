import styled from 'styled-components';

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 60px);
  overflow: hidden;
`;

export const MessagesContainer = styled.div`
  padding: 20px;
  overflow-y: auto;
  flex: 2;
  display: flex;
  flex-direction: column-reverse;
`;

export const ChatNameContainer = styled.div`
  margin-left: 10px;
`;

export const Message = styled.div<{ isMine: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.isMine ? 'flex-end' : 'flex-start'};
  margin-bottom: 10px;
`;

export const MessageContainer = styled.div<{ isMine: boolean }>`
  display: flex;
  flex-direction: ${props => props.isMine ? 'row-reverse' : 'row'};
  align-items: center;
  min-height: 100%;
  width: 100%;

  img {
    margin: ${props => props.isMine ? '0 0 0 10px' : '0 10px 0 0'};
  }
`;

export const AvatarContainer = styled.div`
  width: 40px;
`;

export const MessageBubbleContainer = styled.div<{ isMine: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  justify-content: ${props => props.isMine ? 'flex-end' : 'flex-start'};
  align-items: ${props => props.isMine ? 'flex-end' : 'flex-start'};

  a {
    margin-bottom: 5px;
  }

  a:hover {
    text-decoration: underline;
    text-decoration-color: #c7c4c4;
  }

  span {
    font-size: 12px;
    color: #c7c4c4;;
    width: 100%;
    margin: 5px 0;
    text-align: ${props => props.isMine ? 'right' : 'left'};
  }

  svg {
    position: absolute;
    bottom: 33px;
    right: 8px;  
    opacity: 0.7;
    font-size: 10px;
  }
`;

export const MessageBubble = styled.div<{ isMine: boolean, isSingleEmoji: boolean, isPending: boolean }>`
  display: flex;
  background-color: ${props => props.isMine ? '#92ced47a' : props.theme.lightestGrey};
  border-radius: ${props => props.isMine ? '10px 10px 0 10px' : '10px 10px 10px 0'};
  padding: ${props => props.isSingleEmoji ? '10px 20px' : '20px 30px'};
  font-size: ${props => props.isSingleEmoji ? '50px' : '16px'};
  opacity: ${props => props.isPending ? '0.5' : '1'};
  white-space: pre-wrap;
`;

export const DateMarker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DateLabel = styled.span`
  font-size: 12px;
  color: #c7c4c4;
  padding: 5px 10px;
`;

export const ConversationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

export const ConversationName = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  svg {
    margin-right: 10px;
  }

  h3 {
    margin: 0;
  }
  span {
    font-size: 14px;
    color: ${props => props.theme.lightGrey};
  }
`

export const InfinitLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

export const InfiniteLoaderMessage = styled.span`
  font-size: 12px;
  color: ${props => props.theme.lightGrey};
`;