import React from "react";
import notFound from "../../../assets/icons/404.svg";
import * as S from "./index.styles";

const NotFound: React.FC = () => {
  return (
    <S.Container>
      <S.ImageContainer src={notFound} alt="Coming Soon" />
    </S.Container>
  );
};

export default NotFound;
