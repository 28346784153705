import EventEmitter from "eventemitter3";

class WebSocketManager extends EventEmitter {
  private url: string;
  private socket: WebSocket | null;
  private shouldReconnect: boolean;
  private reconnectTimeout: number;
  private messageQueue: string[];

  constructor(url: string) {
    super();
    this.url = url;
    this.socket = null;
    this.shouldReconnect = true;
    this.reconnectTimeout = 3000;
    this.messageQueue = [];
  }

  public connect(): void {
    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
      this.emit("open");
      this.flushQueue();
      console.log("Connected to websocket server")
    };

    this.socket.onmessage = (event) => {
      this.emit("message", event.data);
    };

    this.socket.onclose = () => {
      this.emit("close");
      console.log("Disconnected from websocket server")
      if (this.shouldReconnect) {
        console.log("Reconnecting to websocket server")
        setTimeout(() => this.connect(), this.reconnectTimeout);
      }
    };

    this.socket.onerror = (error) => {
      console.log('Websocket error: ', error);
      this.emit("error", error);
    };
  }

  public close(): void {
    console.log("Closing websocket connection")
    this.shouldReconnect = false;
    if (this.socket) {
      this.socket.close();
    }
  }

  public send(message: string): void {
    console.log('Socket', this.socket);
    console.log('Socket is closed: ', this.socket?.readyState === WebSocket.CLOSED)

    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      console.log("Sending message", message)
      this.socket.send(message);
    } else {
      console.log("Putting message on message queue", message)
      this.messageQueue.push(message);
    }
  }

  public clearSubscriptions(): void {
    this.removeAllListeners();
  }

  private flushQueue(): void {
    this.messageQueue.forEach((message) => {
      this.send(message);
    });
    this.messageQueue = [];
  }
}

export default WebSocketManager;