import styled from "styled-components";

export const ChatInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 85%;
  height: 130px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  flex-grow: 1;
`;

export const ChatActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;

  button {
    margin-left: 10px;
  }
`;

export const ChatInputWrapper = styled.div`
  min-height: 60px;
  max-height: 400px;
  max-width: 800px;
  width: 85%;
  border: 1px solid ${props => props.theme.lighterGrey};
  border-radius: 5px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  background-color: white;
`;

export const ChatInput = styled.div.attrs({ contentEditable: true, role: 'textbox' })`
  padding: 10px;
  resize: none;
  outline: none;
  width: 80%;
  max-width: 80%;
  white-space: pre-wrap;
`;

export const SendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: ${props => props.theme.primary};
  box-shadow: ${props => props.theme.boxShadow.cardMedium};
  color: white;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.darkPrimary};
  }
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
`;

export const ChatConnectionStatus = styled.div`
  width: 100%;
  padding-left: 10px;
  font-size: 12px;
  box-sizing: border-box;
  svg {
    margin-right: 10px;
  }
`;