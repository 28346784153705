import React from "react";

import PasswordUpdateForm, {
  IPasswordFormInput
} from "../../../components/Forms/PasswordUpdateForm";
import { useActionLoader } from "../../../hooks/useActionLoader";
import { ReactComponent as Logo } from "../../../assets/icons/LogoBlue.svg";
import { ReactComponent as SuccessImage } from "../../../assets/icons/setupSuccess.svg";
import {
  OnboardingContainer,
  OnboardingHeader,
  OnboardingHeaderTitle,
  Text
} from "../../common.styles";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { clockedTheme } from "../../../theme";
import { updatePassword } from "../../../reducers/users/actions";

const PasswordReset: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const { callAction, done, error } = useActionLoader<{ password: string, token: string}>(
    updatePassword,
    'Password reset successfully',
    `Couldn't update password`
  );
  const onSubmit = async (data: IPasswordFormInput) => {
    const newPassword = data.password;
    callAction({ password: newPassword, token });
  };

  return (
    <OnboardingContainer>
      {(!done || error)
      ? <>
        <OnboardingHeader>
          <Logo width={200} style={{ marginBottom: '60px' }} />
          <OnboardingHeaderTitle>Reset your password</OnboardingHeaderTitle>
          <Text style={{ maxWidth: '400px'}}>Try use something memorable and unique!</Text>
        </OnboardingHeader>
        <PasswordUpdateForm onSubmit={onSubmit} />
        {error && <Text style={{marginTop: '30px'}} color={clockedTheme.alertRed}>{error}. You can try restting your password again <a href="/forgot-password">here</a></Text>}
      </>
      : <>
        <Logo width={200} style={{ marginBottom: '60px' }} />
        <SuccessImage height={200} />
        <OnboardingHeader>
          <OnboardingHeaderTitle>Your password has been reset!</OnboardingHeaderTitle>
          <Text>
            You can now login with your new password
          </Text>
        </OnboardingHeader>
        <Link to={'/'}><Button solid={true}>Login</Button></Link>
      </>
      }
      
    </OnboardingContainer>
  );
};

export default PasswordReset;
