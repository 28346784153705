import React from "react";

import * as S from "./index.styles";
import { getDateRangeLabel } from "../../utils/timeUtils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

export interface DatePickerProps {
  onClick: (value: number) => void;
  periodStartDate: Date;
  periodEndDate: Date;
  compact: boolean;
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { onClick, periodStartDate, periodEndDate, compact } = props;

  const handleClick = (value: number) => {
    onClick(value);
  };

  return (
    <S.Container>
      <S.Arrow onClick={() => handleClick(-1)}><FontAwesomeIcon icon={faChevronLeft} /></S.Arrow>
      <span>{getDateRangeLabel(periodStartDate, periodEndDate, compact)}</span>
      <S.Arrow onClick={() => handleClick(1)}><FontAwesomeIcon icon={faChevronRight} /></S.Arrow>
    </S.Container>
  );
};

export default DatePicker;
