import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ReactNode, useContext } from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components"
import { EmployeeModel } from "../../../../../classes/api/types/employee";
import { ShiftModel, ShiftStatus } from "../../../../../classes/api/types/shift";
import { ScheduleActions, ScheduleModes, ScheduleTableContext } from "../../../../../containers/Manager/SchedulePage/state";
import { useActionLoader } from "../../../../../hooks/useActionLoader";
import { createShift } from "../../../../../reducers/shifts/actions";
import SimpleDate from "../../../../../utils/SimpleDate";
import { getDateForBackend } from "../../../../../utils/timeUtils";
import { getDefaultShift } from "../../utils";
import { CreateOneShiftForm } from "../ShiftFormHelpers/CreateOneShiftForm";
import { formatDatesFromDroppedShift } from "./helpers";

interface Props {
  children?: ReactNode[] | ReactNode;
  employee?: EmployeeModel;
  day: Date;
  disablePlaceholder?: boolean;
}

const Placeholder = styled.div<{ visible: boolean, selected: boolean }>`
  border: 2px solid ${props => props.selected ? props.theme.primary : props.theme.lightestGrey};
  color: ${props => props.selected ? props.theme.primary : props.theme.lightestGrey};
  opacity: ${props => props.visible ? '0.7' : '0'};
  font-size: 20px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 80%;
  margin: auto;

  &:hover {
    opacity: 0.7;
  }
`;

const AddShiftPlaceholder = ({ day, employee }: Props) => {

  const { scheduleState, dispatch } = useContext(ScheduleTableContext);
  const [showModal, setShowModal] = useState(false);
  const { callAction: callCreateAction } = useActionLoader(createShift, 
    'Shift created', `Couldn't create shift`);
  const isSelected = useMemo(() => {
    return scheduleState.multiSelectedShifts.some((s: ShiftModel) => {
      if (employee && s.plannedStartTime) {
        return s.employee === employee.id && new Date(s.plannedStartTime).getDate() === day.getDate()
      }
      return false;
    })
  }, [day, employee, scheduleState])

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: 'shift',
      drop: (item: ShiftModel) => {
        saveDroppedShift(item);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      })
    }),
    []
  )

  const handleClick = (e: any) => {
    const shift = getDefaultShift(employee, day);
    if (scheduleState.selectMode === ScheduleModes.MULTI_SELECT) {
      if (!isSelected) {
        dispatch({
          type: ScheduleActions.ADD_TO_SELECTED_SHIFTS,
          payload: shift
        })
      }
      else {
        dispatch({
          type: ScheduleActions.REMOVE_FROM_SELECTED_SHIFTS,
          payload: shift
        })
      }
    }
    else {
      setShowModal(true)
      dispatch({
        type: ScheduleActions.SET_SELECTED_SHIFT,
        payload: shift
      });
    }
  }

  const saveDroppedShift: any = useCallback((droppedItem: ShiftModel) => {
    if (droppedItem && droppedItem.plannedEndTime && droppedItem.plannedStartTime) {
      const [start, end] = formatDatesFromDroppedShift(droppedItem, day);   
      if (droppedItem.startTime === null) {
        delete droppedItem.startTime;
      }
      if (droppedItem.endTime === null) {
        delete droppedItem.endTime;
      }   
      callCreateAction({
        ...droppedItem,
        status: ShiftStatus.PLANNED,
        duration: 0,
        plannedStartTime: getDateForBackend(start),
        plannedEndTime: getDateForBackend(end),
        employee: employee?.id
      });
    }
  }, [employee, day, callCreateAction])


  return <>
    {showModal
      && <CreateOneShiftForm 
        hide={() => setShowModal(false)} 
        employee={employee}
      />
    }
    <Placeholder onClick={handleClick} visible={showModal || isSelected} ref={dropRef} selected={isSelected}>
      <FontAwesomeIcon icon={faPlus} />
      {isOver && <p>Drop to add</p>}
    </Placeholder>
  </>
}

const ShiftCell = ({ children, employee, day, disablePlaceholder }: Props) => {

  return (
    <td style={{ position: 'relative', padding: '0 5px' }}>
      { children 
        ? children 
        : !disablePlaceholder && <AddShiftPlaceholder 
            employee={employee}
            day={day}
        /> 
      }
    </td>
  )
}


export default ShiftCell;