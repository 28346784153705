import styled from 'styled-components';
import Creatable from 'react-select/creatable';

export const Container = styled.div<{ position: { x: string, y: string }}>`
  width: 460px;
  height: 380px;
  background-color: white;
  border-radius: 5px;
  ${props => {
    if (props.position.x || props.position.y) {
      return `
        position: absolute;
        top: ${props.position.y};
        right: ${props.position.x};
        z-index: 15;
        transform: translate(95%, 0);
        box-shadow: ${props.theme.boxShadow.cardLarge};
      `
    }
  }}
  padding: 15px;
  box-sizing: border-box;

  h5 {
    width: 100%;
    text-align: center;
  }
`

export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const MainForm = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SuggestedTimes = styled.div`
  width: 40%;
  margin-left: 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const Label = styled.label`
  width: 30%;
`

export const TimeInput = styled.input`
  box-sizing: border-box;
  color: ${(props) => props.theme.lightGrey};
  font-size: 14px;
  outline: none;
  padding: 8px;
  width: 70%;
  margin: 8px 4px;
  border: 1px solid ${props => props.theme.lightestGrey};
  border-radius: 4px;
  font-family: "CeraRoundBasic-Regular";
`

export const TagSelect = styled(Creatable)`
  width: 70%;
  margin: 8px 4px;
`

export const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ColorContainer = styled.div<{hide: any}>`
  opacity: ${props => props.hide ? '0.3' : '1'};
  display: flex;
  width: 100%;
  align-items: center;
`

export const ColorWarning = styled.span`
  font-size: 10px;
  color: ${props => props.theme.lightGrey};
`

export const SuggestedTimeButton = styled.button<{ backgroundColor: string }>`
  width: 140px;
  height: 25px;
  border: none;
  outline: none;
  background-color: ${props => props.backgroundColor};
  border-radius: 30px;
  margin-top: 15px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    opacity: 0.8;
  }

  & svg {
    margin-left: 5px;
    color: ${props => props.theme.successGreen};
  }
`;