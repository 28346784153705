import { ApiInterface } from "./types";
import { ShiftTypeApi, ShiftTypeModel } from "./types/shiftType";

export class ShiftType implements ShiftTypeApi {
  apiInstance: ApiInterface;

  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  create(shiftType: ShiftTypeModel) {
    return this.apiInstance.sendRequest(
      "/company/shift-types/",
      "POST",
      shiftType
    );
  }

  update(shiftType: ShiftTypeModel) {
    return this.apiInstance.sendRequest(
      `/company/shift-types/${shiftType.id}/`,
      "PATCH",
      shiftType
    );
  }

  getAll() {
    return this.apiInstance.sendRequest("/company/shift-types/", "GET");
  }

  delete(shiftType: ShiftTypeModel) {
    return this.apiInstance.sendRequest(
      `/company/shift-types/${shiftType.id}/`,
      "DELETE"
    );
  }

  getOne(input: string) {
    return Promise.reject('Not implemented')
  }
}
