import { faComment, faEnvelope, faMobile, faMobileAlt, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { AnnouncementModel } from '../../../classes/api/types/announcement';
import Button from '../../../components/Button';
import { useActionLoader } from '../../../hooks/useActionLoader';
import { RootState } from '../../../reducers';
import { addAnnouncement } from '../../../reducers/messaging/actions';
import * as S from './index.styles';
import { PageHeader } from '../../common.styles';
import { useHistory } from 'react-router-dom';
import { useConfirmDialog } from '../../../components/Confirm';

const CreateAnnouncement = () => {
  const [text, setText] = useState('');
  const { user } = useSelector((state: RootState) => state.user);
  const { location } = useSelector((state: RootState) => state.location);
  const { employees } = useSelector((state: RootState) => state.employees);
  const { callAction: createAnnouncement, done } = useActionLoader(addAnnouncement,
    'Announcement sent!',
    `Couldn't send announcement`
  );
  const [teamsFilter, setTeamsFilter] = useState<{ label: string, value: string }[]>([]);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [sendViaEmail, setSendViaEmail] = useState(false);
  const [sendViaSMS, setSendViaSMS] = useState(false);
  const history = useHistory();

  const { ConfirmationDialog, confirm } = useConfirmDialog({
    message: 'Are you sure you want to discard this announcement?',
    buttons: {
      yes: 'Yes',
      no: 'No'
    }
  });

  useEffect(() => {
    if (!employeeCount) {
      setEmployeeCount(employees.length);
    }
  }, [employees]);

  useEffect(() => {
    if (location) {
      const filteredEmps = employees.filter((emp) => emp.teams.some((team) => teamsFilter.some((tf) => tf.value === team)));
      setEmployeeCount(filteredEmps.length);
    }
  }, [teamsFilter]);

  useEffect(() => {
    if (done) {
      history.goBack();
    }
  }, [done])

  const onSave = () => {
    if (user) {
      const payload = {
        text,
        location: user.location.id,
        emailSent: sendViaEmail,
        textSent: sendViaSMS,
        writeTeams: teamsFilter.map((tf) => tf.value)
      }
      createAnnouncement({ announcement: payload });
    }
  }

  const cancel = async () => {
    if (text.length === 0) {
      history.goBack();
    } else {
      confirm().then((confirmed) => {
        if (confirmed) {
          history.goBack();
        }
      });
    }
  }

  return <>
    <PageHeader>Create Announcement</PageHeader>
    <ConfirmationDialog />
    <S.ContentInput
      value={text}
      onChange={e => setText(e.target.value)}
      placeholder="Tell your team about some awesome news"
    />
    <S.ActionContainer>
          <Select
            isMulti
            value={teamsFilter}
            options={location?.teams.map(e => ({ value: e.id || '', label: e.name })) || []}
            onChange={(e: any) => setTeamsFilter(e)}
            placeholder="Filter by team"
            styles={{
              control: (provided) => ({
                ...provided,
                width: 420
              })
            }}
          />
          <S.NotificationOptions>
            <S.NotificationOptionLabel disabled={false}>
              <input 
                type="checkbox" 
                checked={sendViaEmail}
                onChange={e => setSendViaEmail(e.target.checked)} 
              />
              <FontAwesomeIcon icon={faEnvelope} />
              <span>Email</span>
            </S.NotificationOptionLabel>
            <S.NotificationOptionLabel disabled={false}>
              <input 
                type="checkbox" 
                checked={sendViaSMS}
                onChange={e => setSendViaSMS(e.target.checked)} 
                disabled={false}
              />
              <FontAwesomeIcon icon={faComment} />
              <span>Text message</span>
            </S.NotificationOptionLabel>
            <p>Users will also receive a notification via their mobile app</p>
          </S.NotificationOptions>
          <S.ButtonContainer>
            <Button
              solid={false}
              borderless
              onClick={cancel}
            >Cancel</Button>
            <div
              data-tip
              data-for="sendAnnouncement"
            >
              {(text.length === 0) &&
                <ReactTooltip
                  id="sendAnnouncement"
                  place="top"
                  effect="solid"
                >
                  Add some text to your announcement message first
                </ReactTooltip>
              }
              <Button 
                solid 
                onClick={onSave} 
                disabled={text.length === 0}
                size='large'
              >Send
              </Button>
            </div>
          </S.ButtonContainer>
    </S.ActionContainer>
  </>
}

export default CreateAnnouncement;