import styled from "styled-components";

export const ContentContainer = styled.div<{ isFullScreen: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  overflow: hidden;

  ${props => props.isFullScreen && `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100vw;
    height: 100vh;

    table {
      margin: 0 !important;
      padding-top: 10px;
      height: 100%;
      overflow: auto;
      width: 100%;
    }

    > div {
      width: 100vw;
      padding: 10px 20px;
    }
  `}
`;
