import React from 'react';
import styled from 'styled-components';
import { EmployeeModel } from '../../classes/api/types/employee';
import HashLink from '../HashLink';

export const AvatarImg = styled.img<{ size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  a {
    color: ${props => props.theme.black};
    &:hover {
      text-decoration: underline;
    }
  }
`

export const Avatar = ({ size, withHashLinkTo, src }: { size: number, withHashLinkTo?: string, src: string }) => {
  if (withHashLinkTo) {
    return (
      <HashLink to={withHashLinkTo}>
        <AvatarImg size={size} src={src} />
      </HashLink>
    )
  }
  return <AvatarImg size={size} src={src} />
}

type Props = {
  employee: EmployeeModel;
  size?: number;
  linkTo?: string;
  style?: React.CSSProperties;
}

export const AvatarWithName = ({ employee, linkTo, size=25, style={} }: Props) => (
  <Container style={style}>
    <Avatar src={employee.avatar} size={size} />
    {linkTo
      ? <span style={{ marginLeft: 10 }}><HashLink to={linkTo}>{employee.fullName}</HashLink></span>
      : <span style={{ marginLeft: 10 }}>{employee.fullName}</span>
    }
  </Container>
)

export default AvatarWithName;