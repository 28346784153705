import axios, { AxiosInstance, Method } from "axios";
import { storageInstance } from "../../utils";
import { Shift } from "./shift";
import { Timesheet } from "./timesheet";
import { ApiInterface } from "./types";
import { ShiftApi } from "./types/shift";
import { TimesheetApi } from "./types/timesheet";
import { UserApi } from "./types/user";
import { LeaveDay } from "./leaveDay";
import { User } from "./user";
import { LeaveDayApi } from "./types/leaveDay";
import { LocationApi } from "./types/location";
import { EmployeeApi, TeamApi } from "./types/employee";
import { ReviewApi } from "./types/review";
import { Review } from "./review";
import { Location } from "./location";
import { EventApi } from "./types/event";
import { Event } from "./event";
import { Employee } from "./employee";
import { JobApi } from "./types/job";
import { Job } from "./job";
import { LeaveRequestApi } from "./types/leaveRequest";
import { LeaveRequestCommentApi } from "./types/leaveRequestComment";
import { LeaveRequest } from "./leaveRequest";
import { LeaveRequestComment } from "./leaveRequestComment";
import { AnnouncementApi } from "./types/announcement";
import { Announcement } from "./announcement";
import { Team } from "./team";
import { Reports } from "./reports";
import { ReportsApi } from "./types/reports";
import { ShiftTypeApi } from "./types/shiftType";
import { ShiftType } from "./shiftType";
import { ConversationApi } from "./types/conversation";
import { Conversation } from "./conversation";
import { MessageApi } from "./types/message";
import { Message } from "./message";
import ApiError from "../errors/ApiError";

export const isOk = (status: number) => status >= 200 && status < 300;

type Response = {
  data?: any;
  validationErrors?: any;
  status: number;
  error?: any
}

export type PaginatedResponse<T> = {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
  }
}

class API implements ApiInterface {
  instance: AxiosInstance;
  baseUrl: string;
  locations: LocationApi;
  users: UserApi;
  teams: TeamApi;
  shifts: ShiftApi;
  timesheets: TimesheetApi;
  leaveDays: LeaveDayApi;
  employees: EmployeeApi;
  reviews: ReviewApi;
  events: EventApi;
  jobs: JobApi;
  leaveRequests: LeaveRequestApi;
  leaveRequestComments: LeaveRequestCommentApi
  announcements: AnnouncementApi
  reports: ReportsApi
  shiftTypes: ShiftTypeApi
  conversations: ConversationApi
  messages: MessageApi
  token: string | undefined;

  constructor() {
    this.baseUrl = process.env.REACT_APP_SERVER_BASE_URL || "";
    this.instance = axios.create({
      baseURL: this.baseUrl,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    });
    this.token = storageInstance.retrieveToken();
    this.users = new User(this);
    this.shifts = new Shift(this);
    this.timesheets = new Timesheet(this);
    this.leaveDays = new LeaveDay(this);
    this.reviews = new Review(this);
    this.locations = new Location(this);
    this.employees = new Employee(this);
    this.events = new Event(this);
    this.jobs = new Job(this);
    this.leaveRequests = new LeaveRequest(this);
    this.leaveRequestComments = new LeaveRequestComment(this);
    this.announcements = new Announcement(this);
    this.teams = new Team(this);
    this.reports = new Reports(this)
    this.shiftTypes = new ShiftType(this);
    this.conversations = new Conversation(this);
    this.messages = new Message(this);
  }

  setToken(token: string) {
    this.token = token;
  };

  async sendRequest(
    url: string,
    method: Method,
    data?: { [key: string]: any },
    params?: { [key: string]: any },
    headers?: { [key: string]: any },
    skipAuth?: boolean
  ) {
      const head = {
        ...headers
      }
      if (!skipAuth) {
        head.Authorization = `Bearer ${this.token}`;
      }

      return new Promise(async (resolve, reject) => {
        const response: Response = {
          data: undefined,
          validationErrors: undefined,
          status: 0,
          error: undefined
        };
        this.instance.request({
          url,
          method,
          data,
          params,
          headers: head,
          validateStatus(status) {
            return status < 500;
          },
        }).then(res => {
          response.status = res.status;
          if (res.data) {
            response.data = res.data;
          }
          if (res.status === 401) {
            reject(new ApiError(res.status, res?.data.error));
          }
          if (res.status === 400) {
            if (res.data?.validationErrors) {
              reject(new ApiError(res.status, res.data.validationErrors[0]));
            }
            else if (Object.keys(res.data).length) {
              const firstError = res.data[Object.keys(res.data)[0]]
              reject(new ApiError(res.status, firstError));
            }
          }
          else if (!isOk(res.status)) {
            reject(new ApiError(res.status, res.data));
          }
          resolve(response);
        })
      })
  }

  cleanData(data?: { [key: string]: any }) {
    if (!data) {
      return {};
    }

    const cleanData: { [key: string]: any } = {};
    Object.entries(data).forEach(([key, value]) => {
      if (value && value !== "") {
        cleanData[key] = value;
      }
    });

    return cleanData;
  }
}

export default API;
