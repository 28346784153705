import { OnboardingContainer, OnboardingHeader, OnboardingHeaderTitle, Subtitle1, Text } from "../../common.styles";
import { ReactComponent as Logo } from "../../../assets/icons/LogoBlue.svg";
import { ReactComponent as SuccessImage } from "../../../assets/icons/setupSuccess.svg";
import FormBuilder from "../../../components/Forms/Components/FormBuilder";
import { useActionLoader } from "../../../hooks/useActionLoader";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { clockedTheme } from "../../../theme";
import { sendPasswordResetRequest } from "../../../reducers/users/actions";
import { useForm } from "react-hook-form";
import { Input, InputContainer, Label } from "../../../components/NewForms/Inputs";
import * as S from './ForgotPassword.styles';

const ForgotPassword = () => {
  const { register, formState: { errors }, handleSubmit, control } = useForm({ mode: 'onBlur' });
  const { callAction, isLoading, done, error } = useActionLoader(sendPasswordResetRequest, 
    "", "There was an error sending the password reset email. Please try again later.");
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (done && !isLoading) {
      setComplete(true);
    }
  }, [done, isLoading]);

  const sendPasswordLink = (data: any) => {
    callAction(data.email);
  }

  return (
    <OnboardingContainer>
      {!complete 
        ? <>
          <OnboardingHeader>
            <Logo width={200} style={{ marginBottom: '60px' }} />
            <OnboardingHeaderTitle>Reset your password</OnboardingHeaderTitle>
            <Text style={{ maxWidth: '400px'}}>Enter the email used on your account. If the account exists, we'll send you a link you can use to reset your password</Text>
          </OnboardingHeader>
          <S.Form onSubmit={handleSubmit(sendPasswordLink)}>
            <InputContainer error={errors.email?.message}>
              <Label>Email</Label>
              <Input
                type="email"
                name="email"
                ref={register({ required: "Email is required" })}
                inputSize="large"
              />
            </InputContainer>
            <Button solid={true} gradient size="medium" type="submit" disabled={isLoading}>Send reset link</Button>
          </S.Form>
        </>
      : <>
        <Logo width={200} style={{ marginBottom: '60px' }} />
        <SuccessImage height={200} />
        <OnboardingHeader>
          <OnboardingHeaderTitle>Password reset link sent</OnboardingHeaderTitle>
          <Text>
            A password reset link has been sent to your email
          </Text>
        </OnboardingHeader>
      </>
      }
    </OnboardingContainer>
  )
};

export default ForgotPassword;