import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import JobTracker from "../../../../../classes/jobs/JobTracker"
import Button from "../../../../../components/Button"
import Modal from "../../../../../components/Modal/Modal"
import { RootState } from "../../../../../reducers"
import { copyScheduleToPeriod } from "../../../../../reducers/shifts/actions"
import { clockedTheme } from "../../../../../theme"
import { Header2, Text } from "../../../../common.styles"


const Detail = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`

type Props = {
  close: {(): void},
  startPeriod: Date,
  employees: string[]
}

export const CopyScheduleModal = ({ close, startPeriod, employees }: Props) => {
  const [done, setDone] = useState(false);

  const createJob = () => {
    const start = format(startPeriod, 'yyyy-MM-dd');
    copyScheduleToPeriod(start, employees).then((res: any) => {
      setDone(true);
      JobTracker.watchJob(res.data.taskId);
    });
  }

  return (
    <Modal
      onClickOut={close}
      compact
    >
      <Header2 style={{marginBottom: '0'}}><FontAwesomeIcon icon={faCopy}/>Copy Schedule to Next Period</Header2>
      {!done
        ? <Detail>
            <Text>You're about to copy these shifts to the next period. This can take up to a minute to complete</Text>
          </Detail>
        : <>
          <Text color={clockedTheme.successGreen}><FontAwesomeIcon icon={faCheck} /> We're copying your shifts over for you.</Text>
          <Text>You can keep using the app and we'll let you know when we're done</Text>
        </>
        }
      <Detail>
        {!done 
          ? <>
            <Button solid={false} borderless backgroundColor={clockedTheme.alertRed} onClick={close}>Cancel</Button>
            <Button solid onClick={createJob}>Confirm</Button>
          </>
          : <Button solid onClick={close}>Done</Button>
        }
      </Detail>
    </Modal>
  )
}