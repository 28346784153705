import { Dispatch } from "redux";
import { UserActionType } from ".";
import { UserModel } from "../../classes/api/types/user";
import { apiInstance, storageInstance } from "../../utils";
import { EmployeeActionType } from "../employees";
import { EventActionType } from "../events";
import { LocationActionType } from "../locations";
import { ReviewActionType } from "../reviews";
import { ShiftStoreAction } from "../shifts";
import { LeaveDayActionType } from "../leaveDays";
import ApiError from "../../classes/errors/ApiError";

export const getCurrentUser = () => {
  return (dispatch: Dispatch) => {
    return apiInstance.users.getCurrentUser().then(res => {
      dispatch({
        type: UserActionType.SET_USER,
        payload: res.data
      });
      dispatch({
        type: LocationActionType.SET_LOCATION,
        payload: res.data.location
      });
    });
  }
}

export const login = (data: { username: string, password: string }) => {
  return (dispatch: Dispatch) => {
    return apiInstance.users.login(data).then(res => {
      dispatch({
        type: UserActionType.SET_USER,
        payload: res.data
      });
      dispatch({
        type: LocationActionType.SET_LOCATION,
        payload: res.data.location
      });
    });
  }
}

export const logout = () => {
  return (dispatch: Dispatch) => {
    storageInstance.clearToken();
    dispatch({
      type: UserActionType.CLEAR_USER
    });
    dispatch({
      type: ShiftStoreAction.CLEAR_SHIFTS
    });
    dispatch({
      type: ReviewActionType.CLEAR_REVIEWS
    });
    dispatch({
      type: EmployeeActionType.CLEAR_EMPLOYEES
    });
    dispatch({
      type: EventActionType.CLEAR_EVENTS
    });
    dispatch({
      type: LeaveDayActionType.CLEAR_LEAVE_DAYS
    });
  }
};

export const updatePassword = (password: { password: string, token: string }) => {
  return (dispatch: Dispatch) => {
    return apiInstance.users.updatePassword(password);
  }
}

export const updateUser = (user: UserModel) => {
  return (dispatch: Dispatch) => {
    return apiInstance.users.update(user).then(res => {
      dispatch({
        type: UserActionType.UPDATE_USER,
        payload: user
        });
    });
  }
}

export const updateEmployeeUser = (user: UserModel) => {
  return (dispatch: Dispatch) => {
    return apiInstance.users.updateEmployeeUser(user).then(res => {
      dispatch({
        type: UserActionType.UPDATE_USER,
        payload: user
        });
    });
  }
}

export const sendPasswordResetRequest = (email: string) => {
  return (dispatch: Dispatch) => {
    return apiInstance.users.sendPasswordResetRequest(email);
  }
}

export const finishAccountSetup = (data: any) => {
  return (dispatch: Dispatch) => {
    return apiInstance.users.finishAccountSetup(data).then(res => {
      dispatch({
        type: UserActionType.CLEAR_USER,
        payload: null
        });
    });
  }
}

export const getNotifications = () => {
  return (dispatch: Dispatch) => {
    return apiInstance.users.getNotifications().then(res => {
      dispatch({
        type: UserActionType.SET_NOTIFICATIONS,
        payload: res.data
        });
    });
  }
}

export const markNotificationsRead = () => {
  return (dispatch: Dispatch) => {
    return apiInstance.users.markNotificationsAsRead().then(res => {
      dispatch({
        type: UserActionType.MARK_NOTIFICATIONS_READ,
        payload: null
      });
    });
  }
}

export const clearNotifications = () => {
  return (dispatch: Dispatch) => {
    return apiInstance.users.clearNotifications().then(res => {
      dispatch({
        type: UserActionType.CLEAR_NOTIFICATIONS,
        payload: null
        });
    });
  }
}