export interface AppRouteProps {
  route: string;
  label: string;
  Icon?: any,
  component?: React.FC<any>;
  hidden: boolean;
}

export interface StandardRouteMatch {
  mode: string;
  currentRoute?: string;
}

export const standardRouteString = "/:mode/:currentRoute";
export const baseRouteString = "/:mode";
