import styled from 'styled-components';
import data from '@emoji-mart/data'
import { Picker } from 'emoji-mart'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faSmile } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import useOnClickOutside from '../../hooks/useClickOutside';
import * as S from './index.styles';

const FloatingMenu = styled.div`
    position: absolute;
    bottom: 60px;
    right: 0;
    z-index: 11;
`

type MenuProps = {
  close: () => void
  onSelect: (emoji: any) => void
}

const FloatingEmojiMenu = ({ close, onSelect }: MenuProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, close)

  useEffect(() => {
    // @ts-ignore
    new Picker({ ref, data,
      onEmojiSelect: onSelect,
    });
  }, [])

  return <FloatingMenu ref={ref}></FloatingMenu>
}

type Props = {
  onEmojiPick: (emoji: any) => void;
}

const EmojiPicker = ({ onEmojiPick }: Props) => {
  const [pickerVisible, setPickerVisible] = useState(false);

  const pickEmoji = (newEmoji: any) => {
    setPickerVisible(false);
    onEmojiPick(newEmoji.native);
  }

  return <div>
    {pickerVisible && <FloatingEmojiMenu 
      close={() => setPickerVisible(false)} 
      onSelect={pickEmoji}
    />}
    <S.TransparentButton 
      onClick={() => setPickerVisible(true)}
      type="button"
    >
      <FontAwesomeIcon icon={faSmile} size="1x" />
    </S.TransparentButton>
  </div>
}

export default EmojiPicker;