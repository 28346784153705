import { Dispatch } from "redux";
import { EmployeeActionType } from ".";
import { EmployeeModel } from "../../classes/api/types/employee";
import { apiInstance } from "../../utils";

export const getEmployees = () => {
  return (dispatch: Dispatch) => {
    return apiInstance.employees.getAll(null).then(res => {
      const employees = res.data.map((employee: any) => {
        const flatEmployee = {
          ...employee.certificateExpiries,
          ...employee
        };
        delete flatEmployee.certificateExpiries;
        return flatEmployee;
      });
      dispatch({
        type: EmployeeActionType.SET_EMPLOYEES,
        payload: employees
      })
    })
  }
}

export const createEmployee = (employee: EmployeeModel) => {
  return (dispatch: Dispatch) => {
    return apiInstance.employees.create(employee).then(res => {
      dispatch({
        type: EmployeeActionType.ADD_EMPLOYEE,
        payload: {
          ...employee,
          ...res.data,
        }
      })
      return res;
    })
  }
}

export const updateEmployee = (employee: EmployeeModel) => {
  return (dispatch: Dispatch) => {
    return apiInstance.employees.update(employee).then(res => {
      dispatch({
        type: EmployeeActionType.UPDATE_EMPLOYEE,
        payload: {
          ...employee,
          ...res.data
        }
      });
    })
  }
}


export const deleteEmployee = (employee: EmployeeModel) => {
  return (dispatch: Dispatch) => {
    return apiInstance.employees.delete(employee).then(res => {
      dispatch({
        type: EmployeeActionType.DELETE_EMPLOYEE,
        payload: employee.id
      })
    })
  }
}