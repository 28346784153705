import { ApiInterface } from "./types";
import { JobApi } from "./types/job";

export class Job implements JobApi {
  apiInstance: ApiInterface;
  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  checkStatus(taskId: string) {
      return this.apiInstance.sendRequest(`/job/status/${taskId}`, 'GET')
  }
}
