import { ConversationModel } from "../../classes/api/types/conversation";


export const updateOneinList = (updates: Partial<ConversationModel>, convoList: ConversationModel[]) => {
  return convoList.map(convo => {
    if (convo.id === updates.id) {
      return {
        ...convo,
        ...updates
      }
    }
    return convo;
  })
}