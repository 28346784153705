import { EventModel, GenericEvent } from "../../classes/api/types/event";
import { ShiftModel } from "../../classes/api/types/shift";
import * as modifiers from './modifiers';

export interface EventsState {
  activeEvents: GenericEvent[],
  events: GenericEvent[];
}

const initialEventsState: EventsState = {
  activeEvents: [],
  events: []
}

export enum EventActionType {
  SET_EVENTS = "SET_EVENTS",
  SET_ACTIVE_EVENTS = "SET_ACTIVE_EVENTS",
  ADD_EVENT = "ADD_EVENT",
  UPDATE_EVENT = "UPDATE_EVENT",
  DELETE_EVENT = "DELETE_EVENT",
  CLEAR_EVENTS = "CLEAR_EVENTS",
  BULK_UPDATE_EVENTS = "BULK_UPDATE_EVENTS"
}

interface EventAction {
  type: EventActionType;
  payload?: any;
}

export const EventReducer = (state: EventsState = initialEventsState, action: EventAction) => {
    switch (action.type) {
      case EventActionType.SET_EVENTS:
        return {
          ...state,
          events: [ ...action.payload ]
        }
      case EventActionType.ADD_EVENT:
        if (Array.isArray(action.payload)) {
          return {
            ...state,
            events: [ ...state.events, ...action.payload ]
          }
        }
        return {
          ...state,
          events: [ ...state.events, action.payload ]
        }
      case EventActionType.UPDATE_EVENT:
        const events = modifiers.updateEvent(state.events, action.payload);
        return {
          ...state,
          events: [...events]
        }
      case EventActionType.DELETE_EVENT:
        const newEvents = modifiers.deleteEvent(state.events, action.payload);
        return {
          ...state,
          events: [...newEvents]
        }
      case EventActionType.CLEAR_EVENTS:
        return {
          ...state,
          events: []
        }
      case EventActionType.SET_ACTIVE_EVENTS:
        return {
          ...state,
          activeEvents: action.payload
        }
      case EventActionType.BULK_UPDATE_EVENTS:
        let newEvents_0 = [ ...state.events ];
        action.payload.forEach((s: EventModel) => {
          newEvents_0 = modifiers.updateEvent(newEvents_0, s);
        });
        return {
          ...state,
          events: [ ...newEvents_0 ]
        }
      default:
        return state;
    }
}