import styled, { keyframes } from "styled-components";
import { clockedTheme } from "../../theme";
// import { ReactComponent as LoaderIcon } from "../../assets/icons/Loader.svg";

const sizeMap = {
  small: "40px",
  medium: "60px",
  large: "80px",
}

export const Container = styled.div<{
  withOverlay?: boolean;
  fullScreen?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 4px;
`;

export const spiningAnimation = keyframes`
  from { transform: rotate(0deg)}
  to { transform: rotate(360deg)}
`;

// export const LoaderContainer = styled(LoaderIcon)`
//   animation-name: ${spiningAnimation};
//   animation-duration: 6s;
//   animation-iteration-count: infinite;
//   animation-timing-function: linear;
//   /* overflow: hidden; */
// `;

export const LoaderContainer = styled.div<{ size?: 'small' | 'medium' | 'large' }>`
  border: 10px solid white; /* Light grey */
  border-top: 10px solid ${(props) => props.theme.primary}; /* Blue */
  border-radius: 50%;
  width: ${(props) => sizeMap[props.size || 'medium']};
  height: ${(props) => sizeMap[props.size || 'medium']};
  animation: ${spiningAnimation} 1s linear infinite;
`;
