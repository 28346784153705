import { ApiInterface } from "./types";
import { ReportsApi } from "./types/reports";

export class Reports implements ReportsApi {
  apiInstance: ApiInterface;
  route = '/reports/'

  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  certificationsReport() {
    return this.apiInstance.sendRequest(
      `${this.route}certifications/`,
      "GET"
    );
  }

  certificationsReportExport() {
    return this.apiInstance.sendRequest(
      `${this.route}certifications/export/`,
      "GET"
    );
  };

  totalHoursReport(startDate: string, endDate: string) {
    return this.apiInstance.sendRequest(
      `${this.route}total-hours-worked/`,
      "GET",
      {},
      { start_date: startDate, end_date: endDate }
    );
  }

  totalHoursReportExport(startDate: string, endDate: string) {
    return this.apiInstance.sendRequest(
      `${this.route}total-hours-worked/export/`,
      "GET",
      {},
      { start_date: startDate, end_date: endDate }
    );
  }

  totalHoursByRoleReport(startDate: string, endDate: string) {
    return this.apiInstance.sendRequest(
      `${this.route}total-hours-worked-by-role/`,
      "GET",
      {},
      { start_date: startDate, end_date: endDate }
    );
  }

  totalHoursByRoleExport(startDate: string, endDate: string) {
    return this.apiInstance.sendRequest(
      `${this.route}total-hours-worked-by-role/export/`,
      "GET",
      {},
      { start_date: startDate, end_date: endDate }
    );
  }

  totalNightHoursByRoleReport(startDate: string, endDate: string) {
    return this.apiInstance.sendRequest(
      `${this.route}total-night-hours-by-role/`,
      "GET",
      {},
      { start_date: startDate, end_date: endDate }
    );
  }

  nightHoursByRoleExport(startDate: string, endDate: string) {
    return this.apiInstance.sendRequest(
      `${this.route}total-night-hours-by-role/export/`,
      "GET",
      {},
      { start_date: startDate, end_date: endDate }
    );
  }

  leaveReport(startDate: string, endDate: string) {
    return this.apiInstance.sendRequest(
      `${this.route}leave-report/`,
      "GET",
      {},
      { start_date: startDate, end_date: endDate }
    );
  }

  leaveReportExport(startDate: string, endDate: string) {
    return this.apiInstance.sendRequest(
      `${this.route}leave-report/export/`,
      "GET",
      {},
      { start_date: startDate, end_date: endDate }
    );
  }
}
