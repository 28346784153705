import { useState, useEffect, useRef, useContext } from 'react';
import { MessageModel } from '../../../../../classes/api/types/message';
import { useSelector } from 'react-redux';
import { apiInstance } from '../../../../../utils';
import { groupMessages } from './utils';
import * as S from './index.styles';
import { useActionLoader } from '../../../../../hooks/useActionLoader';
import { setConversationMessages } from '../../../../../reducers/chat/actions';
import { SocketContext } from '../../../../../components/ChatManager';

/**
 * Custom hook for managing the messages in a conversation
 */
export const useChatMessages = () => {
  const page = useRef<number>(0);
  const [groupedMessages, setGroupedMessages] = useState<{[key: string]: MessageModel[][]}>();
  const { callAction: updateConvoMessages } = useActionLoader(setConversationMessages)
  const [messages, setMessages] = useState<MessageModel[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { conversations } = useSelector((state: any) => state.chat);
  const [loading, setLoading] = useState<boolean>(false);
  const [conversationId, setConversationId] = useState<string>('');
  const { latestMessage } = useContext(SocketContext);


  const PAGE_SIZE = 20; // same as backend

  useEffect(() => {
    if (latestMessage?.conversation === conversationId) {
      setMessages([...messages, latestMessage]);
      setGroupedMessages(groupMessages([...messages, latestMessage]));
    };
  }, [latestMessage]);

  // Gets the next page of messages from the API and formats it into groups for display
  const loadNextPage = (nextPage: number) => {
    setLoading(true);
    apiInstance.messages.getAll({ conversation: conversationId, page: nextPage }).then(res => {
      const { results, count } = res.data;
      const newMessages = [...results.reverse(), ...messages].reverse();
      setMessages(newMessages);
      updateConvoMessages({ conversationId, messages: newMessages, count });
      setGroupedMessages(groupMessages(newMessages))
      setHasMore(count > newMessages.length);
    })
    .catch(err => {
      setHasMore(false);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  // Loads the conversation from the store and sets the messages and grouped messages
  const loadConversation = (conversationId: string) => {
    setConversationId(conversationId);
    if (conversations.length) {
      const conversation = conversations.find((conversation: any) => conversation.id === conversationId);
      if (conversation) {
        setGroupedMessages(groupMessages(conversation.messages));
        setMessages(conversation.messages);
        if (conversation.totalMessages) {
          setHasMore(conversation.totalMessages > conversation.messages.length);
          page.current = Math.floor(conversation.messages.length / PAGE_SIZE);
        } 
        else {
          setHasMore(true);
          page.current = 0;
        }
      }
    }
  };

  return {
    groupedMessages,
    getNextPage: () => {
      page.current = page.current + 1;
      loadNextPage(page.current);
    },
    hasMore,
    loading,
    loadConversation
  }
};