import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import zxcvbn from "zxcvbn";
import Button from "../../../components/Button";
import {
  CenteredFormContainer,
  IFormBaseProps
} from "../../../components/Forms/FormBase";
import FormField from "../../../components/Forms/Components/FormField";
import FormItem from "../Components/FormItem";

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 40rem;
`;

export const FeedbackMessage = styled.p`
  text-align: center;
`;

export interface IPasswordFormInput {
  password: string;
  passwordConfirmation: string;
}

const PasswordUpdateForm: React.FC<IFormBaseProps<IPasswordFormInput>> = (
  props
) => {
  const { onSubmit } = props;
  const { register, watch, errors, handleSubmit } = useForm<
    IPasswordFormInput
  >();
  const watchPassword = watch("password", "");
  const [passwordScore, setPasswordScore] = useState<number>(0);
  const [suggestions, setSuggestions] = useState<string | undefined>();
  const [showFeedback, setShowFeedback] = useState(false);

  const strength: { [key: number]: string } = {
    0: "Worst",
    1: "Bad",
    2: "Weak",
    3: "Good",
    4: "Strong"
  };

  useEffect(() => {
    const result = zxcvbn(watchPassword);
    setPasswordScore(result.score);
    setSuggestions(result.feedback.suggestions[0]);
  }, [watchPassword]);

  return (
    <CenteredFormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormItem
        name="password"
        type="password"
        label="New password:"
        inputRef={register({ required: true })}
        onChange={() => setShowFeedback(true)}
        style={{ width: "23rem", padding: '0' }}
      />
      <FormItem
        name="passwordConfirmation"
        type="password"
        label="Confirm password:"
        inputRef={register({
          validate: {
            equal: (value) =>
              value === watchPassword || "The passwords do not match"
          }
        })}
        style={{ width: "23rem", padding: '0' }}
        error={errors["passwordConfirmation"]?.message}
      />
      {showFeedback && (
        <FeedbackContainer>
          <FeedbackMessage>
            Password strength: &nbsp;
            <strong>{strength[passwordScore]}. </strong>
            {suggestions}
          </FeedbackMessage>
        </FeedbackContainer>
      )}
      <Button solid={true} style={{ justifySelf: "center" }}>
        Continue
      </Button>
    </CenteredFormContainer>
  );
};

export default PasswordUpdateForm;
