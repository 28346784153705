import { faCopy, faFileCsv, faFileExcel, faFilePdf, faPaperPlane, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import { useCallback, useEffect } from "react"
import { useMemo } from "react"
import { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { EmployeeModel } from "../../../../../classes/api/types/employee"
import ActionsDropdown from "../../../../../components/ActionsDropdown"
import Button from "../../../../../components/Button"
import Confirm, { useConfirm } from "../../../../../components/Confirm"
import { CreateManyShiftsModalForm } from "../../../../../components/Tables/ScheduleTable/components/ShiftFormHelpers/CreateManyShiftsModal"
import { UpdateManyShiftsModalForm } from "../../../../../components/Tables/ScheduleTable/components/ShiftFormHelpers/UpdateManyShiftsModalForm"
import { Toggle } from "../../../../../components/Toggle"
import { useActionLoader } from "../../../../../hooks/useActionLoader"
import { useQueryParams } from "../../../../../hooks/useQueryParams"
import { RootState } from "../../../../../reducers"
import { deleteAllShiftsInSchedule, deleteShift, getScheduleExport, notifyTeam } from "../../../../../reducers/shifts/actions"
import { clockedTheme } from "../../../../../theme"
import { Header4, Text } from "../../../../common.styles"
import EmployeesFilter, { EmployeeFilter } from "../../../Employees/components/EmployeesFilter"
import { ScheduleActions, ScheduleModes, ScheduleTableContext } from "../../state"
import { CopyScheduleModal } from "../CopyScheduleModal"
import { NotifyTeamModal } from "../NotifyTeamModal"
import * as S from './index.styles';
import PrintCustomizationModal from "../PrintCustomizationModal"

type Props = {
  period: Date[];
  filteredEmployees: EmployeeModel[];
  onFilter: (employees: EmployeeModel[]) => any;
  isFullScreen: boolean;
  onToggleFullScreen: (tog: boolean) => void;
}

export const ScheduleHeader = ({ period, filteredEmployees, onFilter, isFullScreen, onToggleFullScreen }: Props) => {

  const { employees } = useSelector((state: RootState) => state.employees);
  const { location } = useSelector((state: RootState) => state.location);
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState<EmployeeFilter>({ teamFilters: [], managerFilters: [], shiftTypeFilters: [] });
  const { callAction: clearSchedule } = useActionLoader(deleteAllShiftsInSchedule,
    'Schedule cleared',
    "Couldn't clear schedule"
  )
  const { callAction: bulkDelete } = useActionLoader(deleteShift,
    'Shifts deleted',
    "Couldn't delete shifts"
  )
  const { scheduleState: { selectMode, multiSelectedShifts, changesCount, employeeChanges, teamFilters }, dispatch } = useContext(ScheduleTableContext);
  const { queryParams, setParam, clearParams } = useQueryParams();

  const { confirmShowing, showConfirm, cancel, confirm } = useConfirm(() => clearSchedule(format(period[0], 'yyyy-MM-dd')));
  const { 
    confirmShowing: confirmNotifyShowing, 
    showConfirm: showNotifyConfirm, 
    cancel: cancelNotify, confirm: confirmNotify
  } = useConfirm(() => {});

  const [showBulkUpdate, setShowBulkUpdate] = useState(false);
  const [showBulkCreate, setShowBulkCreate] = useState(false);
  const [showPrintCustomization, setShowPrintCustomization] = useState(false);
  
  const getExport = useCallback(async (fileType: 'csv' | 'excel' | 'pdf', exportPeriod?: number) => {
    if (!location) return;
    const start = format(period[0], 'yyyy-MM-dd');
    const periodToExport = exportPeriod ? exportPeriod : location.periodLength / 7;
    const res = await getScheduleExport(start, fileType, filters, periodToExport)
    window.open(res.data.url, '_blank');
  }, [period])
  
  const changeCountLabel = (count: number) => `${count} ${count === 1 ? 'change' : 'changes'}`

  const customizePdfPrint = (weeks: number) => {
    getExport('pdf', weeks)
    setShowPrintCustomization(false);
  }

  const actions = useMemo(() => [
    {
      text: 'Send to team',
      subtext: `${changeCountLabel(changesCount)} made`,
      onSelect: showNotifyConfirm,
      icon: () => <FontAwesomeIcon icon={faPaperPlane} />
    },
    {
      text: 'Copy shifts to next period',
      onSelect: () => setShowModal(true),
      icon: () => <FontAwesomeIcon icon={faCopy} />
    },
    {
      text: 'Export Excel',
      onSelect: () => getExport('excel'),
      icon: () => <FontAwesomeIcon icon={faFileExcel} />
    },
    {
      text: 'Export PDF',
      onSelect: () => setShowPrintCustomization(true),
      icon: () => <FontAwesomeIcon icon={faFilePdf} />
    },
    {
      text: 'Clear schedule',
      type: 'danger',
      onSelect: showConfirm,
      icon: () => <FontAwesomeIcon icon={faTrash} />
    }
  ], [changesCount, getExport, showConfirm])

  const toggleMultiSelect = (on: boolean) => {
    if (on) {
      dispatch({
        type: ScheduleActions.SET_SELECT_MODE,
        payload: ScheduleModes.MULTI_SELECT
      })
    }
    else {
      dispatch({
        type: ScheduleActions.SET_SELECT_MODE,
        payload: ScheduleModes.SINGLE_SELECT
      })
    }
  }

  const deleteShifts = () => {
    bulkDelete(multiSelectedShifts)
    dispatch({
      type: ScheduleActions.CLEAR_SELECTED_SHIFTS,
      payload: null
    })
  }

  const clearShifts = () => {
    dispatch({
      type: ScheduleActions.CLEAR_SELECTED_SHIFTS,
      payload: null
    })
  }

  const update = () => {
    if (multiSelectedShifts.length) {
      setShowBulkUpdate(true)
    }
  }

  const create = () => {
    if (multiSelectedShifts.length) {
      setShowBulkCreate(true)
    }
  }

  const hideUpdateModal = () => {
    setShowBulkUpdate(false)
    dispatch({
      type: ScheduleActions.CLEAR_SELECTED_SHIFTS,
      payload: null
    });
  }

  const hideCreateModal = () => {
    setShowBulkCreate(false)
    dispatch({
      type: ScheduleActions.CLEAR_SELECTED_SHIFTS,
      payload: null
    });
  }

  useEffect(() => {
    if (queryParams.get('view') === 'full') {
      onToggleFullScreen(true);
    }
  }, []);

  const onFullScreenToggle = (on: boolean) => {
    if (!on) {
      clearParams();
    }
    else {
      setParam('view', 'full');
    }
    onToggleFullScreen(on);
  }

  return (
    <S.Header>
      {showBulkUpdate && <UpdateManyShiftsModalForm hide={hideUpdateModal} />}
      {showBulkCreate && <CreateManyShiftsModalForm hide={hideCreateModal} />}
      {showPrintCustomization && 
        <PrintCustomizationModal 
          onClose={() => setShowPrintCustomization(false)}
          onConfirm={customizePdfPrint}
      />}
      <ActionsDropdown options={actions} />
      {showModal && <CopyScheduleModal 
        startPeriod={period[0]} 
        close={() => setShowModal(false)} 
        employees={filteredEmployees.map(e => e.id || '') || []}
      />}
      {confirmNotifyShowing && <NotifyTeamModal
        close={cancelNotify}
        startPeriod={period[0]}
      />}
      {confirmShowing && <Confirm 
        message={`You're about to delete all shifts between ${format(period[0], 'MMM d')} - ${format(period[1], 'MMM d')}. Are you sure?`}
        type="danger"
        onCancel={cancel}
        onConfirm={confirm}
      />}
      
      <S.ActionsPane>
        {selectMode === ScheduleModes.MULTI_SELECT &&
          <>
          {multiSelectedShifts.length && multiSelectedShifts[0].id.startsWith('temp')
            ? <S.Action>
              <Button solid size="small"
                onClick={create}
              >Create</Button>
            </S.Action>
            : <S.Action>
            <Button solid size="small"
              onClick={update}
            >Update</Button>
          </S.Action>
          }
            <S.Action>
              <Button solid={false} borderless
                size="small"
                onClick={clearShifts}
              >Clear selected</Button>
            </S.Action>
            <S.Action>
              <Button solid={false} borderless
                size="small" backgroundColor={clockedTheme.alertRed}
                onClick={deleteShifts}
              >Delete</Button>
            </S.Action>

          </>
        }
        <S.Action>
          <Toggle onToggle={toggleMultiSelect} />
          <label style={{ color: clockedTheme.black}}>Multi select</label>
        </S.Action>
        <S.Action>
          <Toggle onToggle={onFullScreenToggle} controlledValue={isFullScreen} />
          <label style={{ color: clockedTheme.black}}>Focus view</label>
        </S.Action>
      </S.ActionsPane>
      <EmployeesFilter
        employees={employees}
        onFilter={onFilter}
        localStorageKey="scheduleEmployeesFilter"
        onFiltersChange={setFilters}
      />
    </S.Header>
  )
}