import { faSpinner, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ConversationModel } from '../../../../../classes/api/types/conversation';
import { MessageModel } from '../../../../../classes/api/types/message';
import { Avatar } from '../../../../../components/Avatar';
import { SocketContext } from '../../../../../components/ChatManager';
import Loader from '../../../../../components/Loader';
import useReadReceipt from '../../../../../hooks/useReadReceipt';
import { RootState } from '../../../../../reducers';
import SimpleDate from '../../../../../utils/SimpleDate';
import ChatInput from '../ChatInput';
import * as S from './index.styles';
import HashLink from '../../../../../components/HashLink';
import { useChatMessages } from './useChatMessages';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const InfiniteScrollTrigger = forwardRef(({ hasMore }: { hasMore: boolean, }, ref: any) => {
  if (!hasMore) {
    return null;
  }
  return (
    <S.InfinitLoaderContainer ref={ref}>
      <S.InfiniteLoaderMessage>
        <FontAwesomeIcon icon={faSpinner} spin />
      </S.InfiniteLoaderMessage>
    </S.InfinitLoaderContainer>
  )
});

const Conversation = () => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const { conversations } = useSelector((state: RootState) => state.chat);
  const [conversation, setConversation] = useState<ConversationModel | null>(null);
  const { user } = useSelector((state: RootState) => state.user);
  const previousConversation = useRef<ConversationModel>();
  const setReadReceipt = useReadReceipt();
  const { groupedMessages, getNextPage, hasMore, loading, loadConversation } = useChatMessages();
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: getNextPage
  });


  useEffect(() => {
    const conversation = conversations.find((conversation: ConversationModel) => conversation.id === conversationId);
    const conversationHasChanged = previousConversation?.current?.id !== conversationId;
    if (conversationId && conversations.length && conversationHasChanged) {
      if (conversation) {
        setConversation(conversation);
        loadConversation(conversationId);
        if (conversation.hasUnreadMessages) {
          setReadReceipt(conversationId);
        }
      }
      previousConversation.current = conversation;
    }
  }, [conversations, conversationId])

  if (!conversation || !user?.id) {
    return <Loader withOverlay fullScreen />
  }

  return (
    <S.ChatContainer>
      <S.ConversationHeader>
        <S.ConversationName>
          <Avatar src={conversation.avatar} size={40} />
          <S.ChatNameContainer>
            <h3>
              {conversation.name}</h3>
            <span>{conversation.membersDescription}</span>
          </S.ChatNameContainer>
        </S.ConversationName>
      </S.ConversationHeader>
      <S.MessagesContainer>
        {conversation && groupedMessages && Object.keys(groupedMessages).reverse().map((date: string) => {
          return <div key={date}>
            <S.DateMarker><S.DateLabel>{date}</S.DateLabel></S.DateMarker>
            {groupedMessages[date].map((messageGroup: MessageModel[], index: number) => {
              return messageGroup.map((message: MessageModel, subIndex: number) => {
                const isMine = user.id === message.sender.id;
                return (
                  <S.Message isMine={isMine} key={message.id}>
                    <S.MessageContainer isMine={isMine}>
                      <S.AvatarContainer>{subIndex === 0 && (
                          isMine
                            ? <Avatar src={user.avatar} size={25} />
                            : <Avatar src={message.sender.avatar} size={25} withHashLinkTo={`/manager/employees/${message.sender.id}`} />
                        )
                      }</S.AvatarContainer>
                      <S.MessageBubbleContainer isMine={isMine}>
                        {subIndex === 0 && (
                          isMine
                            ? <span>You</span>
                            : <HashLink to={`/manager/employees/${message.sender.id}`}>
                              <span>
                                {message.sender.fullName}
                              </span>
                            </HashLink>
                          )
                        }
                        {!message.id && <FontAwesomeIcon icon={faSpinner} spin />}
                        <S.MessageBubble 
                          isMine={isMine}
                          isSingleEmoji={/^\p{Extended_Pictographic}$/u.test(message.text)}
                          isPending={!message.id}
                        >
                            {message.text}
                        </S.MessageBubble>
                        {subIndex === messageGroup.length - 1 && 
                          <span>{new SimpleDate(new Date(message.createdAt)).getTimeLabel()}</span>
                        }
                      </S.MessageBubbleContainer>
                    </S.MessageContainer>
                  </S.Message>
                )
              })
            })}
          </div>}
        )}
        {!loading && <InfiniteScrollTrigger hasMore={hasMore} ref={sentryRef} />}
      </S.MessagesContainer>
      <ChatInput userId={user.id} conversationId={conversationId} />
    </S.ChatContainer>
  )
};

export default Conversation;
