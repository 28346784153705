import { ReactNode, useState } from "react";
import styled from "styled-components";
import { ButtonContainer } from "../../containers/Login/index.styles";
import { clockedTheme } from "../../theme";
import Button from "../Button";
import Modal from "../Modal/Modal";

interface Props {
    message: string;
    onConfirm: any;
    onCancel: any;
    type?: 'danger' | 'warning'
}

const ConfirmContainer = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > div {
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    width: 80%;
  }
`;

const Confirm = ({ message, onConfirm, onCancel, type }: Props) => {

  return (
    <Modal onClickOut={onCancel} compact>
      <ConfirmContainer>
        <p>{message}</p>
        <ButtonContainer>
          <Button solid={false} borderless onClick={onCancel}>Cancel</Button>
          <Button 
            solid={true} 
            onClick={onConfirm} 
            backgroundColor={type === 'danger' ? clockedTheme.alertRed : clockedTheme.primary}
          >Yes</Button>
        </ButtonContainer>
      </ConfirmContainer>
    </Modal>
  )
};


export const useConfirm = ( onConfirm: { (input?: any): any }) => {
  const [confirmShowing, setConfirmShowing] = useState(false);

  const showConfirm = () => {
    setConfirmShowing(true);
  }

  const cancel = () => {
    setConfirmShowing(false);
  }

  const confirm = () => {
    setConfirmShowing(false);
    onConfirm();
  }

  return {confirmShowing, showConfirm, confirm, cancel};
}

type ConfirmProps = {
  message: ReactNode,
  buttons?: {
    yes: string;
    no: string;
  };
  type?: 'danger'
}

export const useConfirmDialog = ({ message, buttons, type }: ConfirmProps) => {
  const [promise, setPromise] = useState<any>();

  const confirm = () => new Promise((resolve, reject) => {
    setPromise({ resolve });
  });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const ConfirmationDialog = () => {
    return !!promise 
      ? <Modal onClickOut={handleClose} compact>
        <ConfirmContainer>
          <div>{message}</div>
          <ButtonContainer>
            <Button solid={false} borderless onClick={handleCancel}>{buttons ? buttons.no : 'Cancel'}</Button>
            <Button 
              solid={true} 
              onClick={handleConfirm} 
              backgroundColor={type === 'danger' ? clockedTheme.alertRed : clockedTheme.primary}
            >{ buttons ? buttons.yes : 'Yes'}</Button>
          </ButtonContainer>
        </ConfirmContainer>
      </Modal>
    : null
  };
  return { ConfirmationDialog, confirm };
};

export default Confirm;