import mixpanel from 'mixpanel-browser';

class Mixpanel {

  shouldTrack = false

  constructor() {
    if (process.env.REACT_APP_ENV === 'prod' && process.env.REACT_APP_MIXPANEL_TOKEN) {
      this.shouldTrack = true;
    }
  }
  
  init() {
    if (this.shouldTrack) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '');
    }
  }

  identify(id: string) {
    if (this.shouldTrack) {
      mixpanel.identify(id);
    }
  }

  track(event: string) {
    if (this.shouldTrack) {
      mixpanel.track(event);
    }
  }

  meta(obj: any) {
    if (this.shouldTrack) {
      mixpanel.people.set(obj);
    }
  }

}

export default new Mixpanel()