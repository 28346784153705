import styled, { css } from 'styled-components';
import ReactSelect from "react-select";
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils/urlUtils';

const SectionContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.lightestGrey};
  padding: 20px 0 80px 0;
  margin: 20px 0 40px 0;
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const SectionDetails = styled.div`
  width: 40%;
  padding-right: 30px;
`;

const FormPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLink = styled(Link)`
  margin: 0;
  font-weight: bold;
  color: ${(props) => props.theme.black} !important;
`;

type Props = {
  title: string,
  description?: React.ReactNode
  inputDirection?: 'row' | 'column'
}

const Section = ({ title, description, children, inputDirection }: PropsWithChildren<Props>) => (
  <SectionContainer>
    <SectionDetails>
      <Header>{title}</Header>
      <SubHeader>{description}</SubHeader>
    </SectionDetails>
    <SectionInputs direction={inputDirection}>
      {children}
    </SectionInputs>
  </SectionContainer>
)

const SectionInputs = styled.div<{ direction?: 'row' | 'column' }>`
  width: 60%;
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  flex-wrap: wrap;

  > div {
    width: auto !important;
    margin-right: 40px;
    margin-bottom: 30px;
  }
`;

const Header = styled.h3`
  margin: 0;
  font-weight: bold;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SubHeader = styled.span`
  margin-top: 15px;
  margin-bottom: 30px;
  font-size: 0.9rem;
  color: ${(props) => props.theme.lightGrey};
`;

export const StyledHelpText = styled.span`
  font-size: 12px;
  margin-top: 5px;
  color: ${props => props.theme.lightGrey};
`;

export const InputHelpText = ({ children }: PropsWithChildren<{}>) => (
  <StyledHelpText>{children}</StyledHelpText>
)

export const Form = {
  Section, SectionInputs, Header: FormPageHeader, SubHeader, Actions, InputHelpText
}

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;
  width: 100%;
`;

const Error = styled.span`
  color: ${(props) => props.theme.alertRed};
  font-size: 0.8rem;
  margin-top: 5px;
`;

export const InputContainer = ({ error, children }: PropsWithChildren<{ error?: string }>) => {
  return (
    <StyledInputContainer>
      {children}
      {error && <Error>{error}</Error>}
    </StyledInputContainer>
  )
}

const inputSizes = {
  small: '100px',
  medium: '200px',
  large: '300px',
  extraLarge: '400px'
}

const inputStyles = css`
  font-size: 1rem;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  color: ${(props) => props.theme.lightGrey};
  border: 1px solid ${props => props.theme.lightestGrey};

  &:disabled {
    background: #e9e9e9;
  }
`;

export const Input = styled.input<{ inputSize?: keyof typeof inputSizes, centered?: boolean }>`
  ${inputStyles}
  ${(props) => props.inputSize && `width: ${inputSizes[props.inputSize]};`}
  ${(props) => props.centered && 'text-align: center;'}
`;

export const TextArea = styled.textarea`
  ${inputStyles}
`;

export const Label = styled.label`
  font-size: 0.8rem;
  color: ${(props) => props.theme.lightGrey};
  margin-bottom: 5px;

  svg {
    margin-right: 5px;
  }
`;

export const Select = styled(ReactSelect)<{ inputSize?: keyof typeof inputSizes }>`
  width: 100%
  max-width: 250px;
  margin: 0;
  ${(props) => props.inputSize && `width: ${inputSizes[props.inputSize]};`}
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;