import { LeaveDayModel } from '../../classes/api/types/leaveDay';
import * as helpers from '../helpers';

export const updateLeaveDay = (leaveDays: LeaveDayModel[], leaveDay: LeaveDayModel): LeaveDayModel[] => {
  const updated = helpers.updateById<LeaveDayModel>(leaveDays, leaveDay);
  return updated;
}

export const deleteLeaveDay = (leaveDays: LeaveDayModel[], leaveDayId: string): LeaveDayModel[] => {
  const updated = helpers.removeById<LeaveDayModel>(leaveDays, leaveDayId);
  return [...updated];
}