import { ReviewModel } from '../../classes/api/types/review';
import * as modifiers from './modifiers';
import { TimesheetModel } from '../../classes/api/types/timesheet';

export interface ReviewsState {
  reviews: ReviewModel[];
}

const initialReviewStoreState: ReviewsState = {
  reviews: []
}

export enum ReviewActionType {
  SET_REVIEWS = "SET_REVIEWS",
  ADD_REVIEW  = "ADD_REVIEW",
  UPDATE_REVIEW = "UPDATE_REVIEW",
  DELETE_REVIEW = "DELETE_REVIEW",
  CLEAR_REVIEWS = "CLEAR_REVIEWS"
}

interface ReviewAction {
  type: ReviewActionType;
  payload?: any;
}

export const ReviewReducer = (state: ReviewsState = initialReviewStoreState, action: ReviewAction) => {
  switch (action.type) {
    case ReviewActionType.ADD_REVIEW:
      return {
        ...state,
        reviews: [ ...state.reviews, action.payload ]
      }
    case ReviewActionType.SET_REVIEWS:
      return {
        ...state,
        reviews: [ ...action.payload ]
      }
    case ReviewActionType.UPDATE_REVIEW:
      const reviews = modifiers.updateReview(state.reviews, action.payload);
      return {
        ...state,
        reviews
      }
    case ReviewActionType.DELETE_REVIEW:
      const newReviews = modifiers.deleteReview(state.reviews, action.payload);
      return {
        ...state,
        reviews: [...newReviews]
      }
    case ReviewActionType.CLEAR_REVIEWS:
      return {
        ...state,
        reviews: []
      }
    default:
      return state;
  }
}