
interface Generic {
  [key: string]: any
}

export const removeById = <T extends Generic>(arr: T[], id: string): T[] => {
  const index = arr.findIndex(
    (item) => item.id === id
  );
  const updated = [...arr];
  updated.splice(index, 1);
  return [...updated];
}

export const updateById = <T extends Generic>(arr: T[], obj: T): T[] => {
  const index = arr.findIndex(
    (item) => item.id === obj.id
  );
  const updated = [...arr];
  updated[index] = obj;
  return [...updated];
}