import { ApiInterface } from "./types";
import { AnnouncementApi, AnnouncementModel } from "./types/announcement";

export class Announcement implements AnnouncementApi {
  apiInstance: ApiInterface;
  route = '/messaging/announcements/'

  constructor(ApiInstance: ApiInterface) {
    this.apiInstance = ApiInstance;
  }

  create(announcement: AnnouncementModel) {
    return this.apiInstance.sendRequest(
      this.route,
      "POST",
      announcement
    );
  }

  update(announcement: AnnouncementModel) {
    return this.apiInstance.sendRequest(
      `${this.route}${announcement.id}/`,
      "PATCH",
      announcement
    );
  }

  getAll() {
    return this.apiInstance.sendRequest(this.route, "GET");
  }

  delete(announcement: any) {
    return this.apiInstance.sendRequest(
      `${this.route}${announcement.id}/`,
      "DELETE"
    );
  }

  getOne(input: string) {
    return Promise.reject('Not implemented')
  }
}
