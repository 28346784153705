import React from 'react';
import styled from 'styled-components';


interface ModalProps {
  onClickOut: any;
  children: any;
  compact?: boolean;
}

export const Blanket = styled.div`
  backdrop-filter: blur(4px);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div<{ compact?: boolean}>`
  background-color: white;
  width: 60%;
  max-width: 600px;
  min-height: ${props => props.compact ? '300px' : '50%'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-shadow: 3px 3px 15px #ccccccb5;
  padding: 50px;
  box-sizing: border-box;
`;

const Modal = ({ onClickOut, children, compact }: ModalProps) => {

  const handleClickOut = (e: any) => {
    e.stopPropagation();
    if (e.target === e.currentTarget) {
      onClickOut();
    }
  }

  return (
    <Blanket onClick={handleClickOut}>
      <ModalContainer compact={compact}>
        {children}
      </ModalContainer>
    </Blanket>
  )
}

export default Modal;