import { MessageModel } from "../../../../../classes/api/types/message";
import SimpleDate from "../../../../../utils/SimpleDate";

type GroupedMessages = {
  [key: string]: MessageModel[][];
}

export const groupMessages = (messages: MessageModel[]): GroupedMessages => {
  const groupedMessages: GroupedMessages = {};
  messages.forEach((message: MessageModel) => {
    const day = SimpleDate.from(message.createdAt).getMonthAndDayLabel();
    if (!groupedMessages[day]) {
      groupedMessages[day] = [];
    }
    if (groupedMessages[day].length === 0) {
      groupedMessages[day].push([message]);
    } else {
      const lastMessage = groupedMessages[day][groupedMessages[day].length - 1];
      if (lastMessage[0].sender.id === message.sender.id) {
        lastMessage.push(message);
      } else {
        groupedMessages[day].push([message]);
      }
    }
  });
  return groupedMessages;
};