import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from "styled-components";
import { clockedTheme } from "./theme";
import PageLayout from "./containers/Layout";
import { Provider } from "react-redux";
import store from "./reducers";
import Mixpanel from './utils/mixpanel';
import ModalRouter from "./routes/ModalRouter";
import { ChatManager } from "./components/ChatManager";

const App: React.FC = () => {

  useEffect(() => {
    Mixpanel.init();
  }, []);

  return (
    <ThemeProvider theme={clockedTheme}>
      <Provider store={store}>
        <BrowserRouter>
          <Toaster />
            <ChatManager>
              <PageLayout />
            </ChatManager>
        </BrowserRouter>
        <ModalRouter />
      </Provider>
    </ThemeProvider>
  );
};

export default App;
