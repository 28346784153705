import toast from 'react-hot-toast';
import { apiInstance } from '../../utils';

enum JobStatuses {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS"
}


class JobTracker {

  jobStatuses: Map<string, any> = new Map();
  toasts: Map<string, any> = new Map();


  watchJob(taskId: string) {
    const checker = setInterval(() => {
      apiInstance.jobs.checkStatus(taskId).then((res: any) => {
        if (res.data === JobStatuses.SUCCESS) {
          clearInterval(this.jobStatuses.get(taskId));
          this.jobStatuses.delete(taskId);
          const toastId = this.toasts.get(taskId);
          toast.dismiss(toastId);
          toast.success('Shift copy complete')
        }
        else if (res.data === JobStatuses.PENDING) {
          const existingToastId = this.toasts.get(taskId);
          if (!existingToastId) {
            const toastId = toast.loading('Copying schedule...');
            this.toasts.set(taskId, toastId);
          }
        }
        else {
          // Something went wrong
          clearInterval(this.jobStatuses.get(taskId));
          this.jobStatuses.delete(taskId);
          const toastId = this.toasts.get(taskId);
          toast.dismiss(toastId);
        }
      })
    }, 1000);
    this.jobStatuses.set(taskId, checker);
  }
}

export default new JobTracker();