import { useEffect, useState } from "react"
import { useContext } from "react"
import styled from "styled-components"
import { ShiftModel } from "../../../../../../classes/api/types/shift"
import { Header3 } from "../../../../../../containers/common.styles"
import { ScheduleActions, ScheduleTableContext } from "../../../../../../containers/Manager/SchedulePage/state"
import { useActionLoader } from "../../../../../../hooks/useActionLoader"
import { bulkUpdateShifts } from "../../../../../../reducers/shifts/actions"
import SimpleDate from "../../../../../../utils/SimpleDate"
import { applyTimeToDate, getDateForBackend } from "../../../../../../utils/timeUtils"
import Modal from "../../../../../Modal/Modal"
import { ShiftForm } from "../../ShiftForm"

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

interface Props {
  hide: {(): void}
}

export const UpdateManyShiftsModalForm = ({ hide }: Props) => {

  const { scheduleState, dispatch } = useContext(ScheduleTableContext);
  const { callAction: bulkUpdate, done, isLoading } = useActionLoader(bulkUpdateShifts,
    'Shifts updated',
    `Couldn't update shifts`
  )
 
  const onSave = (updates: any, updateColors: boolean) => {
    const shifts = scheduleState.multiSelectedShifts.map((shift: any) => {
      const newStartTime = SimpleDate.from(updates.plannedStartTime).get24TimeLabel(false);
      const newEndTime = SimpleDate.from(updates.plannedEndTime).get24TimeLabel(false);
      const newStartDate = applyTimeToDate(new Date(shift.plannedStartTime), newStartTime);
      const newEndDate = applyTimeToDate(new Date(shift.plannedEndTime), newEndTime);
      return {
        ...shift,
        tag: updates.tag ? updates.tag : shift.tag,
        color: updateColors ? updates.color : shift.color,
        plannedStartTime: getDateForBackend(newStartDate),
        plannedEndTime: getDateForBackend(newEndDate)
      }
    });
    bulkUpdate(shifts);
  }

  useEffect(() => {
    if (done && !isLoading) {
      dispatch({
        type: ScheduleActions.TRACK_CHANGE,
        payload: scheduleState.multiSelectedShifts.map((s: ShiftModel) => s.employee)
      })
      hide();
    }
  }, [done, isLoading, dispatch, scheduleState, hide])

  return <Modal onClickOut={hide}>
    <Container>
      <Header3 style={{ marginBottom: '30px' }}>Update {scheduleState.multiSelectedShifts.length} shifts</Header3>
      <ShiftForm
        hide={() => {}}
        onSave={onSave}
        fixedPosition
        hideColor
        size="medium"
      />
    </Container>
  </Modal>
}