import React from 'react';
import { EmployeeModel } from "../../../../../classes/api/types/employee";
import { EventModel, EventType, GenericEvent } from "../../../../../classes/api/types/event";
import { ShiftModel } from "../../../../../classes/api/types/shift";
import { VacationCell, VacationText } from "../../index.styles";
import { Shift } from "../Shift";
import ShiftCell from "../ShiftCell";
import { LeaveDayModel } from '../../../../../classes/api/types/leaveDay';

type Props = {
  events: EventModel[];
  employee: EmployeeModel;
  day: Date;
}

const EventsMapper = ({ events, employee, day }: Props) => {

  if (events.some((event: GenericEvent) => event.resourcetype === EventType.LEAVE_DAY)) {
    const leaveDay = events.find((event: GenericEvent) => event.resourcetype === EventType.LEAVE_DAY) as LeaveDayModel;
    return (
      <VacationCell>
        <VacationText>{leaveDay.locationHolidayName || leaveDay.resourcetype}</VacationText>
      </VacationCell>
    )
  }

  return (
    <ShiftCell
      employee={employee}
      day={day}
      disablePlaceholder
    >
      {events.map((event: EventModel, index: number) => (
        <Shift 
          key={event.id} 
          shift={event as ShiftModel} 
          employee={employee} 
          showAddMoreButton={index === events.length - 1}
        />
      ))}
    </ShiftCell>
  )
}

export default EventsMapper;