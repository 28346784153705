import React, { useEffect, useState } from "react";
import useOnClickOutside from "../../../../hooks/useClickOutside";
import * as S from "./index.styles";

export interface IColorPicker {
  color: string;
  onChange?: (color: string) => void;
}

export const colorPickerPalette = [
  "#4AAEFF",
  "#94D4FF",
  "#24D35A",
  "#78F49B",
  "#FF4D4D",
  "#FF9797",
  "#A150FF",
  "#D197FF",
  "#FC8D42",
  "#FFCC99",
  "#44BEC0",
  "#85E8E8",
  "#B37310",
  "#EDB469",
  "#FFEF5A",
  "#FFF5A9",
];

const ColorPicker: React.FC<IColorPicker> = (props) => {
  const { color, onChange } = props;
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsPickerOpen(false));
  const [value, setValue] = useState(color);

  useEffect(() => {
    setValue(color);
  }, [color])

  const handleColorChange = (color: string) => {
    onChange && onChange(color);
    setValue(color);
    setIsPickerOpen(false);
  };

  return (
    <S.Container>
      <S.InputContainer
        onClick={() => setIsPickerOpen(!isPickerOpen)}
        // onBlur={() => setIsPickerOpen(false)}
        type="button"
      >
        <S.ColorDisplay color={value} />
        &#9660;
      </S.InputContainer>
      {isPickerOpen && (
        <S.ColorContainer ref={ref}>
          {colorPickerPalette.map((color) => (
            <S.ColorDisplay
              key={color}
              color={color}
              onClick={() => handleColorChange(color)}
            />
          ))}
        </S.ColorContainer>
      )}
    </S.Container>
  );
};

export default ColorPicker;
