import React from "react";
import Input from "../Input";

import * as S from "./index.styles";

export interface FormItemProps {
  name: string;
  label?: string;
  type?: string;
  inputRef?: any;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  defaultValue?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  step?: string | number;
  selectOptions?: { label: string; value: string }[];
  radioOptions?: { label: string; value: string, Icon: any }[];
  multiValues?: { label: string; value: string }[];
  multiSelectOptions?: { label: string; value: string }[];
  control?: any;
  style?: {
    [key: string]: string;
  };
}

const FormItem: React.FC<FormItemProps> = (props) => {
  const {
    name,
    label,
    type = "text",
    inputRef,
    onChange,
    error,
    defaultValue,
    value,
    children,
    disabled,
    step,
    selectOptions = [],
    radioOptions = [],
    style,
    multiValues = [],
    multiSelectOptions = [],
    control
  } = props;

  const hasError = !!error;
  return (
    <S.FieldSet style={style} hasError={hasError} disabled={disabled} fullWidth={!!radioOptions.length}>
      <S.Label htmlFor={name}>{label}</S.Label>
      <Input
        defaultValue={defaultValue}
        disabled={disabled}
        hasError={hasError}
        inputRef={inputRef}
        name={name}
        onChange={onChange}
        selectOptions={selectOptions}
        radioOptions={radioOptions}
        step={step}
        type={type}
        value={value}
        multiValues={multiValues}
        multiSelectOptions={multiSelectOptions}
        control={control}
      />
      <S.InputErrorMessage>{error}</S.InputErrorMessage>
      {children}
    </S.FieldSet>
  );
};

export default FormItem;
