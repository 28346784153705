import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "../../../components/DatePicker";
import Loader from "../../../components/Loader";
import {
  HeaderContainer,
  PageHeader
} from "../../common.styles";
import { eachDayOfInterval } from "date-fns";
import {
  getPeriodStartAndEndDates
} from "../../../utils/timeUtils";
import * as S from "./index.styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserClock } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { getAllEvents } from "../../../reducers/events/actions";
import { getEmployees } from "../../../reducers/employees/actions";
import { useActionLoader } from "../../../hooks/useActionLoader";
import { ScheduleHeader } from "./components/ScheduleHeader";
import { EmployeeModel } from "../../../classes/api/types/employee";
import { ScheduleContextProvider } from "./state";
import ScheduleTable from "../../../components/Tables/ScheduleTable";

const SchedulePage: React.FC = () => {
  const { location } = useSelector((state: RootState) => state.location);
  const { selectedDates } = useSelector((state: RootState) => state.session);
  const { employees } = useSelector((state: RootState) => state.employees);
  const { callAction: fetchEvents, isLoading } = useActionLoader(getAllEvents);
  const { callAction: fetchEmployees } = useActionLoader(getEmployees);
  const [filteredEmployees, setFilteredEmployees] = useState<EmployeeModel[]>([]);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [baseDate, setBaseDate] = useState(new Date());
  const [currentPeriod, setCurrentPeriod] = useState<Date>();
  const [endCurrentPeriod, setEndCurrentPeriod] = useState<Date>();
  const tableRef = useRef();

  // Need to store these values on the company model
  const periodStartString = location ? location.periodStartDate : '';
  const periodLength = location ? location.periodLength : 14;

  useEffect(() => {
    const { currentPeriod, endCurrentPeriod } = getPeriodStartAndEndDates(
      periodStartString,
      periodLength,
      baseDate
    );
    setCurrentPeriod(currentPeriod);
    setEndCurrentPeriod(endCurrentPeriod);
    if (!selectedDates || selectedDates.startDate !== currentPeriod.toISOString()) {
      fetchEvents({ startDate: currentPeriod, endDate: endCurrentPeriod });
    }
  }, [baseDate]);

  useEffect(() => {
    setFilteredEmployees(employees);
  }, [employees]);

  useEffect(() => {
    if (!employees.length) {
      fetchEmployees(null);
    }
    // eslint-disable-next-line
  }, []);

  const days = useMemo(() => {
    if (currentPeriod && endCurrentPeriod) {
      return eachDayOfInterval({
        start: currentPeriod,
        end: endCurrentPeriod
      })
    }
    return []
  }, [currentPeriod, endCurrentPeriod]);

  if (!employees || !currentPeriod || !endCurrentPeriod) {
    return <Loader withOverlay={true} />;
  }

  const updateBaseDate = (number: number) => {
    const newBaseDate = new Date(
      baseDate.getFullYear(),
      baseDate.getMonth(),
      baseDate.getDate() + number * periodLength
    );
    setBaseDate(newBaseDate);
  };

  return (
    <ScheduleContextProvider>
      <HeaderContainer>
        <PageHeader><FontAwesomeIcon icon={faUserClock} />Schedule the team</PageHeader>
        <DatePicker
          periodStartDate={currentPeriod}
          periodEndDate={endCurrentPeriod}
          onClick={updateBaseDate}
          compact
        />
      </HeaderContainer>
      <ScheduleHeader 
        period={[currentPeriod, endCurrentPeriod]} 
        filteredEmployees={filteredEmployees}
        onFilter={setFilteredEmployees}
        isFullScreen={isFullScreen}
        onToggleFullScreen={setIsFullScreen}
      />
      {(isLoading || !days)
        ? <Loader />
        : <Suspense fallback={<Loader />}>
          <S.ContentContainer isFullScreen={isFullScreen}>
            {isFullScreen &&
              <ScheduleHeader 
                period={[currentPeriod, endCurrentPeriod]} 
                filteredEmployees={filteredEmployees}
                onFilter={setFilteredEmployees}
                isFullScreen={isFullScreen}
                onToggleFullScreen={setIsFullScreen}
              />
            }
            <ScheduleTable
              employees={filteredEmployees}
              days={days}
              tableRef={tableRef}
              location={location}
            />
          </S.ContentContainer>
        </Suspense>
      }

    </ScheduleContextProvider>
  );
};

export default SchedulePage;
