import { LeaveDayModel } from '../../classes/api/types/leaveDay';
import * as modifiers from './modifiers';

export interface LeaveDayState {
    leaveDays: LeaveDayModel[];
    latestChange: LeaveDayModel | null;
}

const initialLeaveDaysState: LeaveDayState = {
  leaveDays: [],
  latestChange: null
}

export enum LeaveDayActionType {
  SET_LEAVE_DAYS = "SET_LEAVE_DAYS",
  ADD_LEAVE_DAY = "ADD_LEAVE_DAY",
  UPDATE_LEAVE_DAY = "UPDATE_LEAVE_DAY",
  DELETE_LEAVE_DAY = "DELETE_LEAVE_DAY",
  CLEAR_LEAVE_DAYS = "CLEAR_LEAVE_DAYS"
}

interface LeaveDayAction {
  type: LeaveDayActionType;
  payload?: any;
}

export const LeaveDayReducer = (state: LeaveDayState = initialLeaveDaysState, action: LeaveDayAction) => {
  switch (action.type) {
    case LeaveDayActionType.SET_LEAVE_DAYS:
      return {
        ...state,
        leaveDays: [ ...action.payload ]
      }
    case LeaveDayActionType.ADD_LEAVE_DAY:
      return {
        ...state,
        latestChange: action.payload,
        leaveDays: [ ...state.leaveDays, action.payload ]
    }
    case LeaveDayActionType.UPDATE_LEAVE_DAY:
      const leaveDays = modifiers.updateLeaveDay(state.leaveDays, action.payload);
      return {
        ...state,
        leaveDays: [...leaveDays]
      }
    case LeaveDayActionType.DELETE_LEAVE_DAY:
      const newLeaveDays = modifiers.deleteLeaveDay(state.leaveDays, action.payload.id);
      return {
        ...state,
        leaveDays: [...newLeaveDays]
      }
    case LeaveDayActionType.CLEAR_LEAVE_DAYS:
      return {
        ...state,
        leaveDays: []
      }
    default:
      return state;
  }
}