export const clockedTheme = {
  primary: "#67A6F2",
  darkPrimary: '#3b679c',
  lightPrimary: "#d0e7ff",
  secondary: "white",
  lightWarning: "#ffe4b3",
  warning: "#ffc459",
  darkWarning: "#f09c00",
  alertRed: "#f18888",
  lightGrey: "#6f6b6b",
  lighterGrey: "#CFCFCF",
  lightestGrey: "#dedede",
  lightestGrey2: "#f9f9f9",
  offWhite: "#f1f1f170",
  successGreen: "#1EB365",
  standardText: "#696969",
  black: '#5a5a5a',
  lightGreen: '#29db7f80',
  darkGreen: '#17874d',
  lightRed: '#ffcccc',
  darkRed: '#cc0000',
  cardBorder: '#e5e5e5',
  standardFont: "CeraRoundBasic-Regular",
  boxShadow: {
    flat: `1px 1px 4px rgba(0, 0, 0, 0.1);`,
    card: `
    0 2.7px 3.8px -12px rgba(0, 0, 0, 0.04),
    0 8.9px 12.7px -12px rgba(0, 0, 0, 0.06),
    0 40px 57px -12px rgba(0, 0, 0, 0.1);
  `,
    cardMedium: `
      0 2.7px 3.8px -24px rgba(0, 0, 0, 0.04),
      0 8.9px 12.7px -24px rgba(0, 0, 0, 0.06),
      0 40px 57px -24px rgba(0, 0, 0, 0.1);
    `,
    cardSmall: `
      0 2.7px 3.8px -36px rgba(0, 0, 0, 0.04),
      0 8.9px 12.7px -36px rgba(0, 0, 0, 0.06),
      0 40px 57px -36px rgba(0, 0, 0, 0.1);
    `,
    cardLarge: `
      0px 2.5px 2.2px rgba(0, 0, 0, 0.022),
      0px 5.9px 5.3px rgba(0, 0, 0, 0.032),
      0px 11.1px 10px rgba(0, 0, 0, 0.04),
      0px 19.9px 17.9px rgba(0, 0, 0, 0.048),
      0px 37.2px 33.4px rgba(0, 0, 0, 0.058),
      0px 89px 80px rgba(0, 0, 0, 0.08)
    `
  },
  varietyColors: {
    purple: '#B66FBB',
    blue: '#42BFC2',
    brown: '#C88E4B'
  },
  headingFontFamily: 'CeraRoundBasic-Medium',
};

export const flexColumnBase = {
  display: "flex",
  "flex-direction": "column"
};
