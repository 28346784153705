import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { EmployeeModel } from '../../../../../classes/api/types/employee';
import { ShiftTypeAssignmentModel } from '../../../../../classes/api/types/shiftType';
import Button from '../../../../../components/Button';
import HashLink from '../../../../../components/HashLink';
import useOnClickOutside from '../../../../../hooks/useClickOutside';
import { RootState } from '../../../../../reducers';
import { clockedTheme } from '../../../../../theme';
import * as S from './index.styles';

export type EmployeeFilter = {
  teamFilters: string[];
  managerFilters: string[];
  shiftTypeFilters: string[];
}

type Props = {
  employees: EmployeeModel[];
  onFilter: (employees: EmployeeModel[]) => any;
  localStorageKey?: string;
  onFiltersChange?: (filters: EmployeeFilter) => any;
}

const EmployeesFilter = ({ employees, onFilter, localStorageKey, onFiltersChange }: Props) => {
  const { location } = useSelector((state: RootState) => state.location);
  const { user } = useSelector((state: RootState) => state.user);
  const [teamFilters, setTeamFilters] = useState<string[]>([]);
  const [managerFilters, setManagerFilters] = useState<string[]>([]);
  const [shiftTypeFilters, setShiftTypeFilters] = useState<string[]>([]);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const ref = useRef<HTMLDivElement>(null);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useOnClickOutside(ref, () => setCollapsed(true))

  useEffect(() => {
    const managerFiltersTmp: string[] = [];
    // If we have a local storage key, try to load filters from there
    if (localStorageKey) {
      const filters = localStorage.getItem(localStorageKey);
      if (filters) {
        const { teamFilters, managerFilters, shiftTypeFilters } = JSON.parse(filters);
        setTeamFilters(teamFilters || []);
        setShiftTypeFilters(shiftTypeFilters || []);
        managerFiltersTmp.push(...managerFilters);
      }
    }
    // Don't add filter if the user is super user (since we hide their filter)
    else if (user?.id && !user.isSuperuser && !managerFiltersTmp.includes(user.id)) {
      managerFiltersTmp.push(user.id);
    }

    setManagerFilters(managerFiltersTmp);
    setHasLoaded(true);
  }, [employees]);

  useEffect(() => {
    filterEmployees();
    if (onFiltersChange) {
      onFiltersChange({ teamFilters, managerFilters, shiftTypeFilters });
    }
    if (localStorageKey && hasLoaded) {
      storeFilters();
    }
  }, [teamFilters, managerFilters, hasLoaded, shiftTypeFilters])

  const storeFilters = () => {
    if (localStorageKey === undefined) return;
    const filters = { teamFilters, managerFilters, shiftTypeFilters };
    localStorage.setItem(localStorageKey, JSON.stringify(filters));
  };

  const filterEmployees = () => {
    let filteredEmployees = [...employees].filter(e => !e.archivedAt);
    if (teamFilters.length) {
      filteredEmployees = filteredEmployees.filter((e: EmployeeModel) => e.teams.some((t: string) => teamFilters.includes(t)));
    }
    if (managerFilters.length) {
      filteredEmployees = filteredEmployees.filter((e: EmployeeModel) => managerFilters.includes(e.Manager));
    }
    if (shiftTypeFilters.length) {
      filteredEmployees = filteredEmployees.filter((e: EmployeeModel) => {
        return e.shiftTypeAssignments?.some((t: ShiftTypeAssignmentModel) => shiftTypeFilters.includes(t.shiftType));
      })
    }
    onFilter(filteredEmployees);
  }

  const selectManager = (id: string) => {
    if (managerFilters.includes(id)) {
      setManagerFilters(managerFilters.filter((m: string) => m !== id));
    } else {
      setManagerFilters([...managerFilters, id]);
    }
  }

  const selectTeam = (id: string) => {
    if (teamFilters.includes(id)) {
      setTeamFilters(teamFilters.filter((m: string) => m !== id));
    } else {
      setTeamFilters([...teamFilters, id]);
    }
  }

  const selectShiftType = (id: string) => {
    if (shiftTypeFilters.includes(id)) {
      setShiftTypeFilters(shiftTypeFilters.filter((m: string) => m !== id));
    } else {
      setShiftTypeFilters([...shiftTypeFilters, id]);
    }
  };

  const clearFilters = () => {
    setTeamFilters([]);
    setManagerFilters([]);
    setShiftTypeFilters([]);
  }
  
  return (
    <S.Container>
      <S.Header>
        <Button 
            solid={false} 
            borderless
            backgroundColor={clockedTheme.black} 
            size="small"
            onClick={() => setCollapsed(false)}
            style={{ fontWeight: 'bold', fontSize: '14px', color: clockedTheme.lightGrey }}
          >
            <FontAwesomeIcon icon={faFilter} />
            Filters ({teamFilters.length + managerFilters.length + shiftTypeFilters.length})
        </Button>
      </S.Header>
      {!collapsed && 
        <S.FiltersDropdown ref={ref}>
          <S.DropDownActions>
            <Button size="small" solid={false} borderless onClick={clearFilters}>
            {(!!teamFilters.length || !!managerFilters.length || !!shiftTypeFilters.length) &&
              <>
              <FontAwesomeIcon icon={faTimes} />
              Clear filters
              </>
            }
            </Button>
          </S.DropDownActions>
          <S.InnerContainer>
            <S.FilterSection>
              <p>Roles</p>
              {!location?.shiftTypes.length &&
                <span>You don't have any roles. <HashLink to={'manager/settings/roles'}>Click here to create one.</HashLink></span>
              }
              {location?.shiftTypes.map((shiftType: any) => (
                <S.Chip key={shiftType.id}>
                  <input type="checkbox" id={shiftType.id} onChange={() => selectShiftType(shiftType.id)} checked={shiftTypeFilters.includes(shiftType.id)} />
                  <label htmlFor={shiftType.id}>{shiftType.name}</label>
                </S.Chip>
              ))}
            </S.FilterSection>
            <S.FilterSection>
              <p>Teams</p>
              <S.Chips>
                {location?.teams.length === 0 &&
                  <span>You don't have any teams. <HashLink to={'manager/team/teams/new'}>Click here to create one.</HashLink></span>
                }
                {location?.teams.map((team: any) => {
                  return <S.Chip key={team.id}>
                    <input type="checkbox" id={team.id} onChange={() => selectTeam(team.id)} checked={teamFilters.includes(team.id)} />
                    <label htmlFor={team.id}>{team.name}</label>
                  </S.Chip>
                })}
              </S.Chips>
            </S.FilterSection>
            <S.FilterSection>
              <p>Managers</p>
              <S.Chips>
                {location?.managers.filter(m => !m.lastName.toLowerCase().includes('superuser')).map((manager: any) => {
                  return <S.Chip key={manager.id}>
                  <input type="checkbox" id={manager.id} onChange={() => selectManager(manager.id)} checked={managerFilters.includes(manager.id)} />
                  <label htmlFor={manager.id}>{manager.firstName} {manager.lastName}</label>
                </S.Chip>
                })}
              </S.Chips>
            </S.FilterSection>
          </S.InnerContainer>
        </S.FiltersDropdown>
      }
    </S.Container>
  )
};

export default EmployeesFilter;