import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import toast from 'react-hot-toast';
import ApiError from "../classes/errors/ApiError";

export interface ActionLoaderResponse {
  callAction: (input: any) => any;
  done?: boolean;
  isLoading?: boolean;
  error?: ApiError;
}

export function useActionLoader<T>(
  action: (input: T) => any,
  successMessage?: string | Function,
  errorMessage?: string | Function,
  
): ActionLoaderResponse {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState<ApiError>();

  const getErrorMessage = useCallback(() => {
    if (typeof errorMessage === "function") {
      return errorMessage();
    }
    if (error) {
      return error.message;
    }
    return errorMessage;
  }, [errorMessage, error]);

  const getSuccesMessage = useCallback(() => {
    if (typeof successMessage === "function") {
      return successMessage();
    }
    return successMessage;
  }, [successMessage]);

  const callAction = useCallback(
    (input: T) => {
      setIsLoading(true);
      setDone(false);
      setError(undefined);
      try {
        dispatch(action(input))
        .catch((err: ApiError) => {
          setError(err)
        }).finally(() => {
          setDone(true);
          setIsLoading(false);
        })
      }
      catch (err: any) {
        setError(err)
        setIsLoading(false);
      }
    },
    [action]
  );

  useEffect(() => {
    if (!error || !errorMessage) {
      return;
    }
    toast.error(getErrorMessage());
  }, [error]);

  useEffect(() => {
    if (!done || !successMessage || error) {
      return;
    }
    toast.success(getSuccesMessage());
  }, [done]);

  return {
    callAction,
    isLoading,
    done,
    error
  };
}
