import React, { useEffect, lazy, Suspense, useState } from "react";

import Login from "../Login";
import Loader from "../../components/Loader";
import { PageContainer } from "../common.styles";
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { standardRouteString, StandardRouteMatch } from "../../routes/helpers";
import NotFound from "../common/NotFound";
import { useActionLoader } from "../../hooks/useActionLoader";
import ProfileSetup from "../Setup/ProfileSetup";
import PasswordReset from "../Setup/PasswordReset";
import ForgotPassword from "../Setup/ForgotPassword/ForgotPassword";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { getCurrentUser } from "../../reducers/users/actions";
import mixpanel from "../../utils/mixpanel";
import { getEmployees } from "../../reducers/employees/actions";
import { getConversations } from "../../reducers/chat/actions";

const EmployeeRoutes = lazy(() => import("../../routes/EmployeeRoutes"));
const ManagerRoutes = lazy(() => import("../../routes/ManagerRoutes"));

const PageLayout: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const [userIdentified, setUserIdentified] = useState(false);
  const match = useRouteMatch<StandardRouteMatch>(standardRouteString);
  const { callAction: getThisUser, isLoading, done, error } = useActionLoader(getCurrentUser);
  const { callAction: fetchEmployees, done: employeesLoaded, isLoading: employeesLoading } = useActionLoader(getEmployees)
  const { callAction: fetchConversations, done: conversationsLoaded } = useActionLoader(getConversations);

  useEffect(() => {
    if (!user) {
      getThisUser(null);
    }
  }, []);

  useEffect(() => {
    if (user?.id && !userIdentified) {
      mixpanel.identify(user.id || '');
      mixpanel.meta({ 
        role: user.role,
        email: user.email,
        name: user.firstName + ' ' + user.lastName
      });
      setUserIdentified(true);
    }
    else if (match || !user) {
      return;
    }
    const { role } = user;
    if (!match?.url.includes(role)) {
      history.push(`/${role}`);
    }
  }, [match, user]);

  useEffect(() => {
    if (user?.id && !employeesLoaded && !employeesLoading) {
      fetchEmployees(null);
    }
    if (user?.id && !conversationsLoaded) {
      fetchConversations(null);
    }
  }, [user]);

  if ((!done && !user) || isLoading) {
    return (
      <PageContainer>
        <Loader withOverlay={true} fullScreen={true} />
      </PageContainer>
    );
  }

  if (!user && done && !isLoading) {
    return <PageContainer>
      <Switch>
        <Route path='/setup-account/:token' component={ProfileSetup} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/reset-password/:token' component={PasswordReset} />
        <Route path='/' component={Login} />
        <Loader withOverlay={true} fullScreen={true} />
        <NotFound />
      </Switch>
    </PageContainer>
  }
  return (
    <>
      <PageContainer>
        <Suspense fallback={<Loader withOverlay={true} fullScreen={true} />}>
          <Switch>
            <Route path="/manager" component={ManagerRoutes} />
            <Route path="/employee" component={EmployeeRoutes} />
            <Route path="/setup-account/:token" component={ProfileSetup} />
            <NotFound />
          </Switch>
        </Suspense>
      </PageContainer>
    </>
  );
};

export default PageLayout;
