import styled from 'styled-components';
import { clockedTheme } from '../../theme';

export const DropdownContainer = styled.div`
  position: relative;
  width: 230px;
  height: 35px;
`

export const Select = styled.div<{ open: boolean }>`
  border: 1px solid ${props => props.theme.lightestGrey};
  width: 250px;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  background: white;
  cursor: pointer;
`;

export const Options = styled.ul`
  padding: 0;
  margin: 0;
`;

const common = `
  width: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 18px;
  color: ${clockedTheme.lightGrey};
`

export const Option = styled.li<{ type: string | undefined, disabled: boolean | undefined }>`
  ${common}

  &:hover {
    background-color: #e3f2fd;
  }

  ${props => props.type === 'success'
    ? `
      &:hover {
        background-color: ${props.theme.lightGreen};
        color: ${props.theme.darkGreen};
      }
    `
    : ''
  }

  ${props => props.type === 'danger'
    ? `
      &:hover {
        background-color: ${props.theme.lightRed};
        color: ${props.theme.darkRed};
      }
    `
    : ''
  }

  ${props => props.disabled
    ? `
      cursor: not-allowed;
      background-color: ${props.theme.lightestGrey};

      &:hover {
        background-color: ${props.theme.lightestGrey};
        color: ${props.theme.lightGrey};
      }
    `
    : ''
  }

  svg {
    margin-right: 15px;
  }
`;

export const Placeholder = styled.div`
  ${common}
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SubText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.primary};
  margin-top: 5px;
  font-weight: normal;
`