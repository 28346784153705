import styled from "styled-components";

export const Label = styled.label`
  color: ${(props) => props.theme.standardText};
  font-family: CeraRoundBasic-Medium;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const InputErrorMessage = styled.p`
  color: ${(props) => props.theme.alertRed};
  font-size: 1rem;
  font-family: CeraRoundBasic-Medium;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const FieldSet = styled.fieldset<{
  hasError?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}>`
  border: none;
  border-radius: 5px;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  padding: 10px 30px 10px 0;
  width: 100%;
  max-width: ${props => props.fullWidth ? '100%' : '300px'};
  ${(props) => props.disabled && `opacity: 0.6;`}

  &:only-child {
    padding: 10px 10px 10px 0;
  }
`;
