import styled from "styled-components";

export const Label = styled.label`
  color: ${(props) => props.theme.standardText};
  font-family: CeraRoundBasic-Medium;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  max-width: 3rem;
  position: relative;
`;

export const InputContainer = styled.button`
  align-items: center;
  background: white;
  border: none;
  border-radius: 5px;
  color: #4d4b4b;
  display: flex;
  flex: 1;
  font-size: 10px;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ColorDisplay = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  border-radius: 4px;
  padding: 0.5rem 1rem;
`;

export const ColorContainer = styled.div`
  background: white;
  border: 1px solid ${props => props.theme.lightestGrey};
  border-radius: 5px;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0.5rem;
  position: absolute;
  top: 3.2rem;
  z-index: 10;
`;
