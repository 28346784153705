import { EmployeeModel } from '../../classes/api/types/employee';
import * as helpers from '../helpers';

export const updateEmployee = (employees: EmployeeModel[], employee: EmployeeModel): EmployeeModel[] => {
  const updated = helpers.updateById<EmployeeModel>(employees, employee);
  return updated;
}

export const deleteEmployee = (employees: EmployeeModel[], employeeId: string): EmployeeModel[] => {
  const updated = helpers.removeById<EmployeeModel>(employees, employeeId);
  return [...updated];
}